import OutsideClickHandler from "react-outside-click-handler";
import "./WishlistModel.css";

const WishlistModel = ({
  message,
  isApplyAllButtonClicked,
  closeModal,
  handleNoButtonClicked,
  handleYesButtonClicked,
}) => {
  return (
    <section className="model-popup-wishlist wishlist-box">
      <OutsideClickHandler
        onOutsideClick={() => {
          closeModal();
        }}
      >
        <div className="popup-bg-wishlist">
          <div className="popup-content-whishlist">
            <div className="uper-part">
              <div className="wishlist-logo">
                <img src="/image/WORKBRIDGE.png" alt=" " />
              </div>
              <div className="warning-logo-model">
                <img
                  src={
                    isApplyAllButtonClicked
                      ? "/image/success.png"
                      : "/image/warning-wishlist.png"
                  }
                  alt=" "
                />
              </div>
              <p
                className={
                  isApplyAllButtonClicked
                    ? "text-wishlist-succes"
                    : "text-wishlist"
                }
              >
                {isApplyAllButtonClicked ? "SUCCESS" : "Confirm"}
              </p>
            </div>
            <div
              className={
                isApplyAllButtonClicked ? "lower-part-success" : "lower-part"
              }
            >
              <p
                className={
                  isApplyAllButtonClicked
                    ? "text-wishlist-sucess"
                    : "text-wishlist-two"
                }
              >
                {message}
              </p>
              <div class="btn-yes-no">
                <button className="no-btn" onClick={handleNoButtonClicked}>
                  No
                </button>
                <button className="yes-btn" onClick={handleYesButtonClicked}>
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </section>
  );
};

export default WishlistModel;
