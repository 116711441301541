import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FindJob from "../../../components/Common/FindJob/FindJob";
import MostSearchJob from "../../../components/Common/MostSearchJob/MostSearchJob";
import JobHead from "../../../components/Job/JobHead/JobHead";
import JobDescription from "../../../components/Job/ViewDetail/JobDescription";
import SimilarJobs from "../../../components/Job/ViewDetail/SimilarJobs";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";
import LoginModel from "../../../components/Common/LoginModel/LoginModel";
import { jobTags, JobDescriptionData, JobDetailData } from "../../../data/job";
import {
  setMostSearchJob,
  setSearchWord,
} from "../../../redux/slices/JobSearchSlice";
import API from "../../../api";
import "./index.css";

const ViewDetail = () => {
  const UrlParams = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isEmailVerify, user } = useSelector((state) => state.auth);
  const { mostSearchJob } = useSelector((state) => state.jobSearch);

  const [mostSearchedJobs, setMostSearchedJobs] = useState([]);
  const [jobDetail, setJobDetail] = useState([]);
  const [similarJobs, setSimilarJobs] = useState([]);

  const findJobHandler = () => {
    navigate("/job-search");
  };

  const onMostSearchjobClick = (value) => {
    dispatch(setSearchWord(value));
    dispatch(setMostSearchJob(!mostSearchJob));
    navigate("/job-search");
  };

  useEffect(() => {
    async function getJobDetail() {
      if (UrlParams?.id !== null) {
        let params = `?`;
        if (user !== null) {
          params += `user_id=${user?.id}&`;
        }
        if (UrlParams?.id !== null) {
          params += `jobId=${UrlParams?.id}`;
        }
        try {
          await API.get(`/job-board/job-detail${params}`)
            .then((response) => {
              if (response?.status === 200) {
                let res = response?.data;
                setMostSearchedJobs(res?.mostSearchedJobs);
                setJobDetail(res?.jobDetail);
                setSimilarJobs(res?.similarJobs);
              }
            })
            .catch((error) => {
              if (error) {
                console.log(error);
              }
            });
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log("else");
        setMostSearchedJobs(jobTags);
        setJobDetail(JobDescriptionData);
        setSimilarJobs(JobDetailData);
      }
    }

    getJobDetail();
  }, []);

  return (
    <>
      {isEmailVerify && user?.user_type === "employer" ? (
        ""
      ) : (
        <>
          <FindJob findJobHandler={findJobHandler} />
          <MostSearchJob
            mostSearchedJobs={mostSearchedJobs}
            onMostSearchjobClick={onMostSearchjobClick}
          />
        </>
      )}
      <JobHead hideQuesationBtn={false} jobDetail={jobDetail} />
      <JobDescription jobDetail={jobDetail} />
      <SimilarJobs similarJobs={similarJobs} />
      <WorldConnect />
      <LoginModel />
    </>
  );
};

export default ViewDetail;
