import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import OutsideClickHandler from 'react-outside-click-handler';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CustomSelect from '../../../Common/CustomSelect/CustomSelect';
import JobPositionDetail from './JobPositionDetail';
import capitalizeFirstLetter from '../../../../utils/capitalizeFirstLetter';
import transformRes from '../../../../utils/transformRes';
import API from '../../../../api';
import {
  JOB_POST_DECISION,
  JOB_POST_FIELDS_AI,
} from '../../../../constants';
import {
  clearJobDecision,
  openJobDecisionModal,
} from '../../../../redux/slices/JobDecisionSlice';
import RecommendedSection from './RecommendedSection';
import { openZoeModal } from '../../../../redux/slices/JobPostZoeModalSlice';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const PostJobForm = ({
  loader,
  postAJobHandler,
  setHandleNoButtonPostNow,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, mode, postJobId } = useSelector(
    (state) => state.auth
  );
  const {
    jobDecision,
    isJobDecisionModalOpen,
    prevJobDecisionState,
  } = useSelector((state) => state.jobDecision);

  const { noClicked } = useSelector((state) => state.jobPostZoe);

  const [jobTitleDropDownHide, setJobTitleDropDownHide] =
    useState(true);
  const [jobTitleOption, setJobTitleOption] = useState([]);

  const [careerOption, setCareerOption] = useState([]);
  const [currencyOption, setCurrencyOption] = useState([]);
  const [salaryRangeOption, setSalaryRangeOption] = useState([]);
  const [jobTypeOption, setJobTypeOption] = useState([]);
  const [industryOption, setIndustryOption] = useState([]);
  const [reportingToOption, setReportingToOption] = useState([]);
  const [companySizeOption, setCompanySizeOption] = useState([]);
  const [WorkMethOption, setWorkMethOption] = useState([]);
  const [cityOption, setCityOption] = useState([]);
  const [emptyCity, setEmptyCity] = useState(false);

  const [selectCities, setSelectCities] = useState([]);

  const [allSkillTags, setAllSkillTags] = useState([]);
  const [selectSkillTags, setSelectSkillTags] = useState([]);
  const [allSkillTagsHide, setAllSkillTagsHide] = useState(true);
  const [emptySkill, setEmptySkill] = useState(false);
  const [promptMessage, setPromptMessage] = useState('');
  const [showPromptMessageField, setShowPromptMessageField] =
    useState(false);

  const [showFields, setShowFields] = useState(false);

  // new values to set in the text editor START
  const [jobDesNewValue, setJobDesNewValue] = useState('');
  const [rulesNewValue, setRulesNewValue] = useState('');
  const [skillNewValue, setSkillNewValue] = useState('');
  const [mustSkillNewValue, setMustSkillNewValue] = useState('');
  const [niceToHaveNewValue, setNiceToHaveNewValue] = useState('');
  const [benefitsNewValue, setBenefitsNewValue] = useState('');
  const [workingHoursNewValue, setWorkingHoursNewValue] =
    useState('');
  const [interviewProNewValue, setInterviewProNewValue] =
    useState('');
  const [recNotesNewValue, setRecNotesNewValue] = useState('');
  // new values to set in the text editor END

  /* Zoe thing array start */
  const [recJobDes, setRecJobDes] = useState([]);
  const [recRules, setRecRules] = useState([]);
  const [recSkills, setRecSkills] = useState([]);
  const [recMustSkills, setRecMustSkills] = useState([]);
  const [recNiceToHave, setRecNiceToHave] = useState([]);
  const [recBenefits, setRecBenefits] = useState([]);
  const [recInterviewPro, setRecInterviewPro] = useState([]);
  const [recWorkingHours, setRecWorkingHours] = useState([]);
  const [recRecruiterNotes, setRecRecruiterNotes] = useState([]);
  /* Zoe thing array end */

  /* Prompt states for the field that has text editors start */
  const [jobDesPrompt, setJobDesPrompt] = useState('');
  const [rulesPrompt, setRulesPrompt] = useState('');
  const [skillsPrompt, setSkillsPrompt] = useState('');
  const [mustSkillsPrompt, setMustSkillsPrompt] = useState('');
  const [niceToHavePrompt, setNiceToHavePrompt] = useState('');
  const [benefitsPrompt, setBenefitsPrompt] = useState('');
  const [workingHoursPrompt, setWorkingHoursPrompt] = useState('');
  const [interviewProPrompt, setInterviewProPrompt] = useState('');
  const [recruiterNotesPrompt, setRecruiterNotesPrompt] =
    useState('');
  /* Prompt states for the field that has text editors end */

  const onRadioButtonChange = (event, field) => {
    const newValue = event.target.value;
    // console.log("newValue =====>", newValue);
    switch (field) {
      case 'JobDes':
        setJobDesNewValue(newValue);
        break;
      case 'rules':
        setRulesNewValue(newValue);
        break;
      case 'skill':
        setSkillNewValue(newValue);
        break;
      case 'mustSkill':
        setMustSkillNewValue(newValue);
        break;
      case 'niceToHave':
        setNiceToHaveNewValue(newValue);
        break;
      case 'benefits':
        setBenefitsNewValue(newValue);
        break;
      case 'workingHours':
        setWorkingHoursNewValue(newValue);
        break;
      case 'interviewPro':
        setInterviewProNewValue(newValue);
        break;
      case 'recNotes':
        setRecNotesNewValue(newValue);
        break;
      default:
        setJobDesNewValue(newValue);
        break;
    }
  };

  const setFieldPrompt = (field, promptText) => {
    switch (field) {
      case 'JobDes':
        setJobDesPrompt(promptText);
        break;
      case 'rules':
        setRulesPrompt(promptText);
        break;
      case 'skill':
        setSkillsPrompt(promptText);
        break;
      case 'mustSkill':
        setMustSkillsPrompt(promptText);
        break;
      case 'niceToHave':
        setNiceToHavePrompt(promptText);
        break;
      case 'benefits':
        setBenefitsPrompt(promptText);
        break;
      case 'workingHours':
        setWorkingHoursPrompt(promptText);
        break;
      case 'interviewPro':
        setInterviewProPrompt(promptText);
        break;
      case 'recNotes':
        setRecruiterNotesPrompt(promptText);
        break;
      default:
        setJobDesPrompt(promptText);
        break;
    }
  };

  const updateBtnHandler = async (field, data) => {
    // console.log( "Data is ======>", data );
    await formik.setFieldValue(field, data);
  };

  const updateRecommendArray = (field, value, promptText) => {
    const promptHTML = value;
    // const promptHTML = ({ value });

    /*const newObject = {
                  upper: upper,
                  lower: lower,
                  data: promptHTML,
                  dataToSet: normalPrompt,
                };*/

    switch (field) {
      case 'JobDes':
        setRecJobDes([
          ...recJobDes,
          {
            upper: 1,
            lower: recJobDes[recJobDes?.length - 1]?.lower
              ? recJobDes[recJobDes?.length - 1]?.lower + 1
              : 1,
            data: promptHTML,
            // dataToSet: normalPrompt,
            promptText: promptText,
          },
        ]);
        break;
      case 'rules':
        setRecRules([
          ...recRules,
          {
            upper: 1,
            lower: recRules[recRules?.length - 1]?.lower
              ? recRules[recRules?.length - 1]?.lower + 1
              : 1,
            data: promptHTML,
            // dataToSet: normalPrompt,
            promptText: promptText,
          },
        ]);
        break;
      case 'skill':
        setRecSkills([
          ...recSkills,
          {
            upper: 1,
            lower: recSkills[recSkills?.length - 1]?.lower
              ? recSkills[recSkills?.length - 1]?.lower + 1
              : 1,
            data: promptHTML,
            // dataToSet: normalPrompt,
            promptText: promptText,
          },
        ]);
        break;
      case 'mustSkill':
        setRecMustSkills([
          ...recMustSkills,
          {
            upper: 1,
            lower: recMustSkills[recMustSkills?.length - 1]?.lower
              ? recMustSkills[recMustSkills?.length - 1]?.lower + 1
              : 1,
            data: promptHTML,
            // dataToSet: normalPrompt,
            promptText: promptText,
          },
        ]);
        break;
      case 'niceToHave':
        setRecNiceToHave([
          ...recNiceToHave,
          {
            upper: 1,
            lower: recNiceToHave[recNiceToHave?.length - 1]?.lower
              ? recNiceToHave[recNiceToHave?.length - 1]?.lower + 1
              : 1,
            data: promptHTML,
            // dataToSet: normalPrompt,
            promptText: promptText,
          },
        ]);
        break;
      case 'benefits':
        setRecBenefits([
          ...recBenefits,
          {
            upper: 1,
            lower: recBenefits[recBenefits?.length - 1]?.lower
              ? recBenefits[recBenefits?.length - 1]?.lower + 1
              : 1,
            data: promptHTML,
            // dataToSet: normalPrompt,
            promptText: promptText,
          },
        ]);
        break;
      case 'workingHours':
        setRecWorkingHours([
          ...recWorkingHours,
          {
            upper: 1,
            lower: recWorkingHours[recWorkingHours?.length - 1]?.lower
              ? recWorkingHours[recWorkingHours?.length - 1]?.lower +
                1
              : 1,
            data: promptHTML,
            // dataToSet: normalPrompt,
            promptText: promptText,
          },
        ]);
        break;
      case 'interviewPro':
        setRecInterviewPro([
          ...recInterviewPro,
          {
            upper: 1,
            lower: recInterviewPro[recInterviewPro?.length - 1]?.lower
              ? recInterviewPro[recInterviewPro?.length - 1]?.lower +
                1
              : 1,
            data: promptHTML,
            // dataToSet: normalPrompt,
            promptText: promptText,
          },
        ]);
        break;
      case 'recNotes':
        setRecRecruiterNotes([
          ...recRecruiterNotes,
          {
            upper: 1,
            lower: recRecruiterNotes[recRecruiterNotes?.length - 1]
              ?.lower
              ? recRecruiterNotes[recRecruiterNotes?.length - 1]
                  ?.lower + 1
              : 1,
            data: promptHTML,
            // dataToSet: normalPrompt,
            promptText: promptText,
          },
        ]);
        break;

      default:
        setRecJobDes([
          ...recJobDes,
          {
            upper: 1,
            lower: recJobDes[recJobDes?.length - 1]?.lower
              ? recJobDes[recJobDes?.length - 1]?.lower + 1
              : 1,
            data: promptHTML,
            // dataToSet: normalPrompt,
            promptText: promptText,
          },
        ]);
        break;
    }
  };

  const getValueToSend = (field) => {
    switch (field) {
      case 'JobDes':
        return recJobDes?.length > 0
          ? recJobDes[recJobDes?.length - 1].data
          : formik?.values?.JobDes;
      case 'rules':
        return recRules?.length > 0
          ? recRules[recRules?.length - 1].data
          : formik?.values?.rules;
      case 'skill':
        return recSkills?.length > 0
          ? recSkills[recSkills?.length - 1].data
          : formik?.values?.skill;
      case 'mustSkill':
        return recMustSkills?.length > 0
          ? recMustSkills[recMustSkills?.length - 1].data
          : formik?.values?.mustSkill;
      case 'niceToHave':
        return recNiceToHave?.length > 0
          ? recNiceToHave[recNiceToHave?.length - 1].data
          : formik?.values?.niceToHave;
      case 'benefits':
        return recBenefits?.length > 0
          ? recBenefits[recBenefits?.length - 1].data
          : formik?.values?.benefits;
      case 'workingHours':
        return recWorkingHours?.length > 0
          ? recWorkingHours[recWorkingHours?.length - 1].data
          : formik?.values?.workingHours;
      case 'interviewPro':
        return recInterviewPro?.length > 0
          ? recInterviewPro[recInterviewPro?.length - 1].data
          : formik?.values?.interviewPro;
      case 'recNotes':
        return recRecruiterNotes?.length > 0
          ? recRecruiterNotes[recRecruiterNotes?.length - 1].data
          : formik?.values?.recNotes;

      default:
        return formik?.values?.JobDes;
    }
  };

  const generateJobSectionAI = async (
    sectionName,
    field,
    promptValue
  ) => {
    const jobDescription = formik?.values?.JobDes;

    const valueToGenerate = getValueToSend(field);
    const formData = new FormData();
    formData.append('job_description', jobDescription);
    formData.append('section_name', sectionName);
    formData.append('value', valueToGenerate);
    formData.append('to_update', promptValue);

    try {
      await API.post('/job-board/regenerate-job-section', formData)
        .then((response) => {
          console.log('response ====>', response);
          if (response?.status === 200 || response?.status === 201) {
            let res = response?.data?.data;
            if (response?.data?.result === true) {
              updateRecommendArray(field, res?.value, promptValue);
            }
          }
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }

    /*const formData = new FormData();
                        formData.append("job_description", sectionName);
                        formData.append("section_name", sectionName);
                        formData.append("value", sectionName);
                        formData.append("to_update", sectionName);
            
                        try {
                          await API.post('/job-board/regenerate-job-section', formData)
                              .then(response => {
                                console.log("response ====>", response);
                              })
                              .catch(err => console.log(err));
                        } catch (error) {
                          console.log(error);
                        }*/
  };

  const regenerateBtnClickHandler = async (field) => {
    let data = [];
    switch (field) {
      case 'JobDes':
        data = recJobDes;
        break;
      case 'rules':
        data = recRules;
        break;
      case 'skill':
        data = recSkills;
        break;
      case 'mustSkill':
        data = recMustSkills;
        break;
      case 'niceToHave':
        data = recNiceToHave;
        break;
      case 'benefits':
        data = recBenefits;
        break;
      case 'workingHours':
        data = recWorkingHours;
        break;
      case 'interviewPro':
        data = recInterviewPro;
        break;
      case 'recNotes':
        data = recRecruiterNotes;
        break;

      default:
        data = recJobDes;
        break;
    }

    return data;
  };

  const promptClickHandler = async () => {
    if (
      promptMessage === undefined ||
      promptMessage === '' ||
      promptMessage === null
    ) {
      console.log('promptMessage is undefined');
      return;
    }

    if (
      formik?.values?.jobTitle === undefined ||
      formik?.values?.jobTitle === null ||
      formik?.values?.jobTitle === ''
    ) {
      console.log('Job title is undefined');
      formik.touched['jobTitle'] = true;
      isFormFieldValid('jobTitle');
      formik?.setFieldError(
        'jobTitle',
        'Job Title field cannot be empty'
      );
      return;
    }

    if (
      formik?.values?.jobPosition === undefined ||
      formik?.values?.jobPosition === null ||
      formik?.values?.jobPosition === ''
    ) {
      console.log('Job position is undefined');
      formik.touched['jobPosition'] = true;
      isFormFieldValid('jobPosition');
      formik?.setFieldError(
        'jobPosition',
        'Please Select A Career Level'
      );
      return;
    }

    /*setPromptMessage("");
                setShowPromptMessageField(false);*/

    const jobTitle = formik?.values?.jobTitle;
    const careerLevel = formik?.values?.jobPosition;

    try {
      console.log('job title ======>', jobTitle);
      console.log('career level ======>', careerLevel);

      const formData = new FormData();
      formData.append('job_title', jobTitle);
      formData.append('job_level', careerLevel);
      formData.append('prompt_input', promptMessage);

      await API.post('/job-board/generate-job-desc-ai', formData)
        .then(async (response) => {
          if (response?.status === 200 || response?.status === 201) {
            let res = response?.data?.data;
            if (response?.data?.result === true) {
              // using this to show the fields in the jsx
              setPromptMessage('');
              setShowPromptMessageField(false);

              // using this to set the value in the fields
              await formik.setFieldValue(
                'JobDes',
                res?.job_summary?.toString() ?? ''
                // res?.job_summary !== null ? res?.job_summary : "",
              );

              await formik.setFieldValue(
                'rules',
                res?.roles_n_responsibilities?.toString() ?? ''
              );
              await formik.setFieldValue(
                'skill',
                res?.skills?.toString() ?? ''
              );
              await formik.setFieldValue(
                'mustSkill',
                res?.must_have_skills?.toString() ?? ''
              );
              await formik.setFieldValue(
                'niceToHave',
                res?.nice_to_have_skills?.toString() ?? ''
              );
              await formik.setFieldValue(
                'benefits',
                res?.benefits?.toString() ?? ''
              );
              await formik.setFieldValue(
                'workingHours',
                res?.working_hours?.toString() ?? ''
              );
              await formik.setFieldValue(
                'interviewPro',
                res?.interview_process ?? ''
              );
              await formik.setFieldValue(
                'recNotes',
                res?.recruiter_notes ?? ''
              );
            }
          }
        })
        .catch((err) => {
          console.log('error =======>', err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (jobDecision === JOB_POST_DECISION.ZOE) {
      setShowPromptMessageField(true);
    } else {
      setShowPromptMessageField(false);
    }
  }, [jobDecision]);

  useEffect(() => {
    return () => {
      dispatch(clearJobDecision());
      setShowFields(false);
      setRecJobDes([]);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      jobTitle: '',
      jobTitleId: null,
      jobPosition: '',
      jobPositionId: null,
      currency: '',
      currencyId: null,
      salaryStart: '',
      salaryEnd: '',
      salaryRange: '',
      jobType: '',
      jobTypeId: null,
      skillTags: '',
      JobDes: '',
      rules: '',
      skill: '',
      mustSkill: '',
      niceToHave: '',
      benefits: '',
      workingHours: '',
      interviewPro: '',
      recNotes: '',
      industry: '',
      functionalAreaId: null,
      reportingTo: '',
      reportToId: null,
      companySize: '',
      companySizeId: null,
      WorkMethodology: '',
      workMethodologyId: null,
      city: '',
      status: '',
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      jobTitle: Yup.string().required('Please select a job title'),
      jobType: Yup.string().required('Please select a job type'),
      jobPosition: Yup.string().required(
        'Please select a career level'
      ),
      currency: Yup.string().required('Please select a currency'),
      salaryStart: Yup.number()
        .min(0, 'Please enter a numeric value between 0 and 9')
        .required('Please select a salary range'),
      salaryEnd: Yup.number()
        .min(0, 'Please enter a numeric value between 0 and 9')
        .required('Please select a salary range'),
      salaryRange: Yup.string().required(
        'Please select a salary range'
      ),
      JobDes: Yup.string().required('Job summary cannot be empty'),
      rules: Yup.string().required(
        'Roles And Responsibilities Cannot Be Empty'
      ),
      skill: Yup.string().required('Skill Cannot Be Empty'),
      mustSkill: Yup.string().required(
        'Must Have Skill Cannot Be Empty'
      ),
      benefits: Yup.string().required('Benefits Cannot Be Empty'),
      workingHours: Yup.string().required(
        'Working Hours Cannot Be Empty'
      ),
      interviewPro: Yup.string().required(
        'Interview Process Cannot Be Empty'
      ),

      /*rules: Yup.string()
                                      .test(
                                        "is-not-empty",
                                        "Roles and responsibilities cannot be empty",
                                        (value) => {
                                          if (Array.isArray(value) && value.length >= 0) {
                                            return value[0] !== undefined;
                                          }
                                          return true;
                                        },
                                      ),*/

      /*skill: Yup.array()
                                      .of(Yup.string())
                                      .test("is-not-empty", "Skill cannot be empty", (value) => {
                                        if (Array.isArray(value) && value.length >= 0) {
                                          return value[0] !== undefined;
                                        }
                                        return true;
                                      }),*/

      /*mustSkill: Yup.array()
                                      .of(Yup.string())
                                      .test("is-not-empty", "Must have skill cannot be empty", (value) => {
                                        if (Array.isArray(value) && value.length >= 0) {
                                          return value[0] !== undefined;
                                        }
                                        return true;
                                      }),*/

      /*benefits: Yup.array()
                                      .of(Yup.string())
                                      .test("is-not-empty", "Benefits cannot be empty", (value) => {
                                        if (Array.isArray(value) && value.length >= 0) {
                                          return value[0] !== undefined;
                                        }
                                        return true;
                                      }),*/

      /*workingHours: Yup.array()
                                      .of(Yup.string())
                                      .test("is-not-empty", "Working hours cannot be empty", (value) => {
                                        if (Array.isArray(value) && value.length >= 0) {
                                          return value[0] !== undefined;
                                        }
                                        return true;
                                      }),*/

      /*interviewPro: Yup.array()
                                      .of(Yup.string())
                                      .test("is-not-empty", "Interview process cannot be empty", (value) => {
                                        if (Array.isArray(value) && value.length >= 0) {
                                          return value[0] !== undefined;
                                        }
                                        return true;
                                      }),*/
      companySize: Yup.string().required(
        'Company size cannot be empty'
      ),
      // city: Yup.string().required("Please select at-least one city"),
    }),
    onSubmit: async (data, { resetForm }) => {
      if (selectSkillTags?.length === 0) setEmptySkill(true);

      if (selectCities?.length === 0) setEmptyCity(true);

      if (selectSkillTags?.length === 0 || selectCities?.length === 0)
        return;
      const SkillTags = selectSkillTags?.map((item) => item.id);
      postAJobHandler(data, SkillTags, selectCities);
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const isArrayFieldValid = (passwordErrors) =>
    !!(
      formik.touched[passwordErrors] && formik.errors[passwordErrors]
    );
  const getArrayErrorMessage = (passwordErrors) => {
    if (isArrayFieldValid(passwordErrors)) {
      return (
        <ul className="error-msg">
          {passwordErrors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      );
    }
    return null;
  };

  const onjobTitleChange = async (name) => {
    await formik.setFieldValue("jobTitle", name);
    getJobTitles(name, "search");
    setJobTitleDropDownHide(false);
  };

  const onjobTitleClick = async (id, name) => {
    await formik.setFieldValue("jobTitleId", id);
    await formik.setFieldValue("jobTitle", name);
    setJobTitleDropDownHide(true);
  };

  const onJobPositionClick = async (id, name) => {
    await formik.setFieldValue("jobPositionId", id);
    await formik.setFieldValue("jobPosition", name);
  };

  const onSalaryRangeClick = async (name) => {
    await formik.setFieldValue("salaryRange", name);
  };

  const onCurrencyClick = async (id, name) => {
    await formik.setFieldValue("currencyId", id);
    await formik.setFieldValue("currency", name);
  };

  const onJobTypeClick = async (id, name) => {
    await formik.setFieldValue("jobTypeId", id);
    await formik.setFieldValue("jobType", name);
  };

  const onIndustryClick = async (id, name) => {
    await formik.setFieldValue("functionalAreaId", id);
    await formik.setFieldValue("industry", name);
  };

  const onReportToClick = async (id, name) => {
    await formik.setFieldValue("reportToId", id);
    await formik.setFieldValue("reportingTo", name);
  };

  const onCompanySizeClick = async (id, name) => {
    await formik.setFieldValue("companySizeId", id);
    await formik.setFieldValue("companySize", name);
  };

  const onWorkMethClick = async (id, name) => {
    await formik.setFieldValue("workMethodologyId", id);
    await formik.setFieldValue("WorkMethodology", name);
  };

  const onCitiesClick = (id) => {
    if (!selectCities.includes(id)) {
      setSelectCities([...selectCities, id]);
      setEmptyCity(false);
    }
  };

  const onSkillTagsChange = async (value) => {
    await formik.setFieldValue("skillTags", value);
    getSearchSuggestion(value, "jobTag");
  };

  const onSkillTagsClick = async (curentSkillTags) => {
    const isIdExists = selectSkillTags?.some(
      (item) => item.id === curentSkillTags?.id
    );
    if (isIdExists) return;
    setSelectSkillTags([...selectSkillTags, curentSkillTags]);
    setAllSkillTagsHide(true);
    setEmptySkill(false);
    await formik.setFieldValue("skillTags", "");
  };

  const onSkillTagsCancel = async (curentSkillTags) => {
    const updatedItems = selectSkillTags.filter(
      (item) => item.id !== curentSkillTags?.id
    );
    setSelectSkillTags(updatedItems);
    if (updatedItems?.length === 0) setEmptySkill(true);
  };

  const getSearchSuggestion = async (searchValue, searchType) => {
    try {
      let params = ``;
      if (searchValue !== '') {
        params = `?query_key=${searchValue}&type=${searchType}`;
      }
      await API.get(`/job-board/get-search-suggestions${params}`)
        .then((response) => {
          if (response?.status === 200) {
            let res = response?.data;

            if (searchType === 'jobTag') {
              if (res?.job_tags?.length > 0) {
                setAllSkillTags(res?.job_tags);
                setAllSkillTagsHide(false);
              }
            }
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getJobTitles = async (searchValue, searchType) => {
    try {
      let params = ``;
      if (searchValue !== '') {
        params = `?${searchType}=${searchValue}`;
      }

      await API.get(`/job-board/job-titles-careers${params}`)
        .then((response) => {
          if (response?.status === 200) {
            let res = response?.data?.data;
            setJobTitleOption(res?.job_titles);
            setCareerOption(res?.career_levels);

            if (res?.job_titles?.length === 0) {
              // console.log("No Job titles found");

              // display error message on job title
              formik.touched['jobTitle'] = true;
              formik.errors['jobTitle'] =
                'Job Title does not exist in our system';

              // show Job decision modal dialog
              dispatch(openJobDecisionModal());
            }
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getJobTitles();
  }, []);

  useEffect(() => {
    async function getJobDropDownData() {
      try {
        await API.get(`/job-board/post-a-job/detail?country_id=1`)
          .then(async (response) => {
            if (response?.status === 200) {
              let res = response?.data?.data;
              let result = response?.data;
              if (result?.success === true) {
                setCurrencyOption(res?.currencies);
                setSalaryRangeOption(res?.salary_ranges);
                setJobTypeOption(res?.job_types);
                setIndustryOption(res?.functional_areas);
                setReportingToOption(res?.reporting_persons);
                setCompanySizeOption(res?.company_sizes);
                setWorkMethOption(res?.work_methodologies);
                setCityOption(res?.cities);
                await formik.setFieldValue(
                  "currencyId",
                  res?.currencies[0]?.id
                );
                await formik.setFieldValue(
                  "currency",
                  res?.currencies[0]?.title
                );
                await formik.setFieldValue(
                  "salaryRange",
                  res?.salary_ranges[0]?.value
                );
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
    getJobDropDownData();
  }, []);

  useEffect(() => {
    if (
      mode === "add" &&
      postJobId === null &&
      formik?.values?.jobTitleId !== null &&
      formik?.values?.jobPositionId !== null
    ) {
      async function getChatGPTData() {
        try {
          await API.get(
            `/job-board/job-description?job_title_id=${formik?.values?.jobTitleId}&career_level_id=${formik?.values?.jobPositionId}`
          )
            .then(async (response) => {
              if (response?.status === 200) {
                let res = response?.data?.data;
                let result = response?.data;

                /*if (
                  result?.success === false &&
                  Object.keys(res).length === 0
                ) {
                  await formik.setFieldError(
                    "jobTitle",
                    "Job Title does not exist in our system",
                  );
                  return;
                }*/

                if (result?.success === true) {
                  setShowFields(true);
                  await formik.setFieldValue(
                    'JobDes',
                    res?.description !== null ? res?.description : ''
                  );

                  /*await formik.setFieldValue(
                                                                                                              "rules",
                                                                                                              await transformRes(res?.responsibilities),
                                                                                                            );*/

                  await formik.setFieldValue(
                    'rules',
                    res?.responsibilities
                  );
                  await formik.setFieldValue('skill', res?.skills);
                  await formik.setFieldValue(
                    'mustSkill',
                    res?.must_skills
                  );
                  await formik.setFieldValue(
                    'niceToHave',
                    res?.nice_to_have
                  );
                  await formik.setFieldValue(
                    'benefits',
                    res?.benefits
                  );
                  await formik.setFieldValue(
                    'workingHours',
                    res?.working_hrs
                  );
                  await formik.setFieldValue(
                    'interviewPro',
                    res?.interview_process
                  );
                  await formik.setFieldValue(
                    'recNotes',
                    res?.recruiter_notes
                  );
                }
              }
            })
            .catch(async (error) => {
              if (error) {
                const { response } = error;
                const { data } = response;
                // if (data?.success === false) {
                //   await formik.setFieldValue("JobDes", "");
                //   await formik.setFieldValue("rules", "");
                //   await formik.setFieldValue("skill", "");
                //   await formik.setFieldValue("mustSkill", "");
                //   await formik.setFieldValue("niceToHave", "");
                //   await formik.setFieldValue("benefits", "");
                //   await formik.setFieldValue("workingHours", "");
                //   await formik.setFieldValue("interviewPro","");
                //   await formik.setFieldValue("recNotes", "");
                // }
                console.log(error);
              }
            });
        } catch (error) {
          console.log(error);
        }
      }

      getChatGPTData();
    }
  }, [formik?.values?.jobTitleId, formik?.values?.jobPositionId]);

  useEffect(() => {
    if (mode === 'edit' && postJobId !== null) {
      async function getJobDetail() {
        try {
          await API.get(`/job-board/get-job-detail/${postJobId}`)
            .then(async (response) => {
              if (response?.status === 200) {
                let res = response?.data?.data;
                let result = response?.data;
                if (result?.success === true) {
                  console.log('res', res);
                  await formik.setFieldValue('jobTitle', res?.name);

                  await formik.setFieldValue(
                    'jobPositionId',
                    res?.career_level?.id
                  );

                  await formik.setFieldValue(
                    'jobPosition',
                    res?.career_level?.name
                  );

                  await formik.setFieldValue(
                    'currencyId',
                    res?.currency?.id
                  );
                  await formik.setFieldValue(
                    'currency',
                    res?.currency?.title
                  );

                  await formik.setFieldValue(
                    'salaryStart',
                    res?.salary_from
                  );
                  await formik.setFieldValue(
                    'salaryEnd',
                    res?.salary_to
                  );
                  await formik.setFieldValue(
                    'salaryRange',
                    res?.salary_range !== null
                      ? res?.salary_range
                      : ''
                  );
                  await formik.setFieldValue(
                    'jobTypeId',
                    res?.job_types[0]?.id
                  );
                  await formik.setFieldValue(
                    'jobType',
                    res?.job_types[0]?.name
                  );
                  setSelectSkillTags(res?.tags);

                  await formik.setFieldValue(
                    'JobDes',
                    res?.description
                  );
                  await formik.setFieldValue(
                    'rules',
                    await transformRes(res?.responsibilities)
                  );
                  await formik.setFieldValue(
                    'skill',
                    await transformRes(res?.skills)
                  );
                  await formik.setFieldValue(
                    'mustSkill',
                    await transformRes(res?.must_skills)
                  );
                  await formik.setFieldValue(
                    'niceToHave',
                    await transformRes(res?.nice_to_have)
                  );
                  await formik.setFieldValue(
                    'benefits',
                    await transformRes(res?.benefits)
                  );
                  await formik.setFieldValue(
                    'workingHours',
                    await transformRes(res?.working_hrs)
                  );
                  await formik.setFieldValue(
                    'interviewPro',
                    await transformRes(res?.interview_process)
                  );
                  await formik.setFieldValue(
                    'recNotes',
                    await transformRes(res?.recruiter_notes)
                  );
                  await formik.setFieldValue(
                    'functionalAreaId',
                    res?.functional_area?.id
                      ? res?.functional_area?.id
                      : null
                  );
                  await formik.setFieldValue(
                    'industry',
                    res?.functional_area?.name
                      ? res?.functional_area?.name
                      : ''
                  );

                  await formik.setFieldValue(
                    'reportToId',
                    res?.report_to?.id ? res?.report_to?.id : null
                  );
                  await formik.setFieldValue(
                    'reportingTo',
                    res?.report_to?.name ? res?.report_to?.name : ''
                  );

                  await formik.setFieldValue(
                    'companySizeId',
                    res?.company_size ? res?.company_size?.id : null
                  );
                  await formik.setFieldValue(
                    'companySize',
                    res?.company_size ? res?.company_size?.title : ''
                  );

                  await formik.setFieldValue(
                    'workMethodologyId',
                    res?.work_methodology?.id
                      ? res?.work_methodology?.id
                      : null
                  );
                  await formik.setFieldValue(
                    'WorkMethodology',
                    res?.work_methodology?.name
                      ? res?.work_methodology?.name
                      : ''
                  );

                  const city = res?.cities?.map((item) => item.id);
                  setSelectCities(city);

                  await formik.setFieldValue('status', res?.status);
                }
              }
            })
            .catch((error) => {
              if (error) {
                console.log(error);
              }
            });
        } catch (error) {
          console.log(error);
        }
      }

      getJobDetail();
    }
  }, [postJobId]);

  // it shows up when a user directly selects zoe or coming from zoe
  if (
    jobDecision === JOB_POST_DECISION.ZOE &&
    showPromptMessageField &&
    prevJobDecisionState !== JOB_POST_DECISION.COPY_PASTE
  ) {
    return (
      <div className="job-content-show-right">
        <div className={'employe-final-job-post form-center-job'}>
          <div className="candidate-signup-form-feild final-post-employe">
            <form onSubmit={formik.handleSubmit}>
              <div
                className={`signup-jobs-option f-feild ${
                  isFormFieldValid('jobTitle') && 'error-input'
                }`}
              >
                <label htmlFor="fjobtitles">Choose a Job Title</label>
                <br />
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setJobTitleDropDownHide(true);
                  }}
                >
                  <div className="industry-feild-frm bg-pass-f">
                    <input
                      type="text"
                      id="job-post-field"
                      placeholder="Choose Job Title"
                      name="jobTitle"
                      value={formik?.values?.jobTitle}
                      onChange={(e) => onjobTitleChange(e?.target?.value)}
                    />
                    <div className="icon-hide">
                      {isFormFieldValid('jobTitle') && (
                        <img src="/image/warning.png" alt="" />
                      )}
                    </div>
                    <div
                      className={`list-search-dropdown-company ${
                        jobTitleDropDownHide && 'job-dropdown-hide'
                      }`}
                    >
                      {jobTitleOption?.length > 0 && (
                        <>
                          <ul className="list-job-title-company">
                            {jobTitleOption.map((j, i) => (
                              <li
                                onClick={() =>
                                  onjobTitleClick(j?.id, j?.name)
                                }
                              >
                                {j?.name}
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                  </div>
                </OutsideClickHandler>
                {getFormErrorMessage('jobTitle')}
              </div>

              <div className="job-postion-form f-feild">
                <div className="level-of-developer">
                  {careerOption?.length > 0 &&
                    careerOption.map((prod) => (
                      <div className="job-postion-select-btn">
                        <input
                          id="job-postion"
                          value="junior"
                          checked={
                            formik?.values?.jobPosition === prod?.name
                          }
                          name="jobPosition"
                          type="radio"
                          onClick={() =>
                            onJobPositionClick(prod?.id, prod?.name)
                          }
                        />{' '}
                        <label
                          htmlFor="job-postion"
                          className="job-postion-btn"
                        >
                          {prod?.name}
                        </label>
                        <br />
                      </div>
                    ))}

                  <br />
                  {getFormErrorMessage('jobPosition')}
                </div>
              </div>
            </form>
            {/* when zoe selected we will show this input field to enter the prompt by the user  */}
            <div className="job-textarea-box f-feild-post">
              <label htmlFor="flink">
                Job Description Requirements
              </label>
              <br />
              <div className="input-box-user">
                <div className="type-user-f">
                  <input
                    type="text"
                    id="chat-cv-job"
                    value={promptMessage}
                    onChange={(e) => {
                      setPromptMessage(e.target.value);
                    }}
                  />
                  <button
                    type="submit"
                    disabled={
                      promptMessage === '' ||
                      promptMessage === undefined ||
                      promptMessage === null
                    }
                    className={`send-chat-j ${
                      promptMessage && 'icon-cursor'
                    }`}
                    onClick={() => promptClickHandler()}
                  >
                    {promptMessage !== undefined &&
                    promptMessage !== null &&
                    promptMessage !== '' ? (
                      <img src="/image/send-icon.png" alt="" />
                    ) : (
                      <img src="/image/chat-post-job.png" alt="" />
                    )}
                  </button>
                </div>
              </div>
              <p className="error-msg hide-error">
                Enter Short Description
              </p>
            </div>

            {/* closing of the input field */}
          </div>
        </div>
      </div>
    );
  }

  if (
    (jobDecision === JOB_POST_DECISION.COPY_PASTE ||
      jobDecision === JOB_POST_DECISION.ZOE) &&
    prevJobDecisionState === JOB_POST_DECISION.COPY_PASTE
  ) {
  }
  return (
    <>
      <div className="job-content-show-right">
        <div className={"employe-final-job-post form-center-job"}>
          <div className="candidate-signup-form-feild final-post-employe">
            <form onSubmit={formik.handleSubmit}>
              <div
                className={`signup-jobs-option f-feild ${
                  isFormFieldValid("jobTitle") && "error-input"
                }`}
              >
                <label for="fjobtitles">Choose a Job Title</label>
                <br />
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setJobTitleDropDownHide(true);
                  }}
                >
                  <div className="industry-feild-frm bg-pass-f">
                    <input
                      type="text"
                      id="job-post-field"
                      placeholder="Choose Job Title"
                      name="jobTitle"
                      value={formik?.values?.jobTitle}
                      onChange={(e) => onjobTitleChange(e?.target?.value)}
                    />
                    <div className="icon-hide">
                      {isFormFieldValid("jobTitle") && (
                        <img src="/image/warning.png" alt="" />
                      )}
                    </div>
                    <div
                      className={`list-search-dropdown-company ${
                        jobTitleDropDownHide && "job-dropdown-hide"
                      }`}
                    >
                      {jobTitleOption?.length > 0 && (
                        <>
                          <ul className="list-job-title-company">
                            {jobTitleOption.map((j, i) => (
                              <li
                                onClick={() => onjobTitleClick(j?.id, j?.name)}
                              >
                                {j?.name}
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                  </div>
                </OutsideClickHandler>
                {getFormErrorMessage("jobTitle")}
              </div>

              <div className="job-postion-form f-feild">
                <div className="level-of-developer">
                  {careerOption?.length > 0 &&
                    careerOption.map((prod) => (
                      <div className="job-postion-select-btn">
                        <input
                          id="job-postion"
                          value="junior"
                          checked={formik?.values?.jobPosition === prod?.name}
                          name="jobPosition"
                          type="radio"
                          onClick={() =>
                            onJobPositionClick(prod?.id, prod?.name)
                          }
                        />{" "}
                        <label for="job-postion" className="job-postion-btn">
                          {prod?.name}
                        </label>
                        <br />
                      </div>
                    ))}

                  <br />
                  {getFormErrorMessage("jobPosition")}
                </div>
              </div>

              {jobDecision === JOB_POST_DECISION.COPY_PASTE ||
              jobDecision === JOB_POST_DECISION.ZOE ||
              showFields === true ? (
                <>
                  <div
                    className={`signup-company-information job-textarea-box f-feild ${
                      isFormFieldValid("JobDes") && "error-input"
                    }`}
                  >
                    <label for="flink">Job Summary</label>
                    <br />

                    <ReactQuill
                      theme="snow"
                      className={`${
                        isFormFieldValid("JobDes") && "error-input"
                      }`}
                      value={formik?.values?.JobDes ?? ""}
                      name="JobDes"
                      modules={modules}
                      onChange={(data) => {
                        formik.setFieldValue("JobDes", data);
                        formik.handleChange("JobDes");
                      }}
                      onBlur={() => {
                        formik.handleBlur("JobDes");
                        // console.log("formik data ===>", formik.values.JobDes);
                      }}
                    />

                    {/*<textarea
                      className="form-font-f"
                      rows="10"
                      cols="100"
                      name="JobDes"
                      value={formik.values.JobDes}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />*/}
                    {/*<p className="msg-show-blowe-textarea">Max Limit (100) words</p>*/}
                    {getFormErrorMessage("JobDes")}
                    {/* Recommended Section Start */}
                    <RecommendedSection
                      data={recJobDes}
                      field={"JobDes"}
                      promptText={jobDesPrompt}
                      fieldNewValue={jobDesNewValue}
                      onRadioButtonChange={onRadioButtonChange}
                      radioFieldName={"jobDescription"}
                      regenerateBtnClickHandler={regenerateBtnClickHandler}
                      updateBtnHandler={updateBtnHandler}
                      generateJobSectionAI={generateJobSectionAI}
                      setFieldPrompt={setFieldPrompt}
                    />
                    {/* Recommended Section End */}
                  </div>
                  <div
                    className={`signup-company-information job-textarea-box f-feild ${
                      isFormFieldValid("rules") && "error-input"
                    }`}
                  >
                    <label for="flink">Roles and Responsibilities</label>
                    <br />
                    {/*<BulletedTextArea
                  className={`custom-bulleted-textarea form-font-f ${
                    isFormFieldValid("rules") &&
                    "custom-bulleted-textarea-error"
                  }`}
                  name="rules"
                  onChange={(value) => formik.setFieldValue("rules", value)}
                  values={formik?.values?.rules}
                  bulletChar={""}
                />*/}

                    <ReactQuill
                      theme="snow"
                      className={`${isFormFieldValid(
                        "rules",
                      )} && "error-input"`}
                      value={formik?.values?.rules ?? ""}
                      name="rules"
                      modules={modules}
                      onChange={(data) => {
                        formik.setFieldValue("rules", data);
                        formik.handleChange("rules");
                      }}
                      onBlur={() => {
                        formik.handleBlur("rules");
                      }}
                    />
                    {getFormErrorMessage("rules")}

                    {/* Recommended section start */}
                    <RecommendedSection
                      data={recRules}
                      field={"rules"}
                      promptText={rulesPrompt}
                      fieldNewValue={rulesNewValue}
                      onRadioButtonChange={onRadioButtonChange}
                      radioFieldName={"rules"}
                      regenerateBtnClickHandler={regenerateBtnClickHandler}
                      updateBtnHandler={updateBtnHandler}
                      generateJobSectionAI={generateJobSectionAI}
                      setFieldPrompt={setFieldPrompt}
                    />
                    {/* Recommended section end */}
                  </div>
                  <div
                    className={`signup-company-information job-textarea-box f-feild ${
                      isFormFieldValid("skill") && "error-input"
                    }`}
                  >
                    <label for="flink">Skills</label>
                    <br />

                    <ReactQuill
                      theme="snow"
                      className={`${isFormFieldValid(
                        "skill",
                      )} && "error-input"`}
                      value={formik?.values?.skill ?? ""}
                      name="skill"
                      modules={modules}
                      onChange={(data) => {
                        formik.setFieldValue("skill", data);
                        formik.handleChange("skill");
                      }}
                      onBlur={() => {
                        formik.handleBlur("skill");
                      }}
                    />

                    {/*<BulletedTextArea
                  className={`custom-bulleted-textarea form-font-f ${
                    isFormFieldValid("skill") &&
                    "custom-bulleted-textarea-error"
                  }`}
                  name="skill"
                  onChange={(value) => formik.setFieldValue("skill", value)}
                  values={formik?.values?.skill}
                  bulletChar={""}
                />*/}
                    {getFormErrorMessage("skill")}
                    {/* Recommended Section Start */}
                    <RecommendedSection
                      data={recSkills}
                      field={"skill"}
                      promptText={skillsPrompt}
                      fieldNewValue={skillNewValue}
                      onRadioButtonChange={onRadioButtonChange}
                      radioFieldName={"skills"}
                      regenerateBtnClickHandler={regenerateBtnClickHandler}
                      updateBtnHandler={updateBtnHandler}
                      generateJobSectionAI={generateJobSectionAI}
                      setFieldPrompt={setFieldPrompt}
                    />

                    {/* Recommended Section End */}
                  </div>
                  <div
                    className={`signup-company-information job-textarea-box f-feild ${
                      isFormFieldValid("mustSkill") && "error-input"
                    }`}
                  >
                    <label for="flink">Must Have Skills</label>
                    <br />
                    {/*<BulletedTextArea
                  className={`custom-bulleted-textarea form-font-f ${
                    isFormFieldValid("mustSkill") &&
                    "custom-bulleted-textarea-error"
                  }`}
                  name="mustSkill"
                  onChange={(value) => formik.setFieldValue("mustSkill", value)}
                  values={formik?.values?.mustSkill}
                  bulletChar={""}
                />*/}

                    <ReactQuill
                      theme="snow"
                      className={`${isFormFieldValid(
                        "mustSkill",
                      )} && "error-input"`}
                      value={formik?.values?.mustSkill ?? ""}
                      name="mustSkill"
                      modules={modules}
                      onChange={(data) => {
                        formik.setFieldValue("mustSkill", data);
                        formik.handleChange("mustSkill");
                      }}
                      onBlur={() => {
                        formik.handleBlur("mustSkill");
                      }}
                    />
                    {getFormErrorMessage("mustSkill")}
                    {/* Recommended Section Start */}
                    <RecommendedSection
                      data={recMustSkills}
                      field={"mustSkill"}
                      promptText={mustSkillsPrompt}
                      fieldNewValue={mustSkillNewValue}
                      onRadioButtonChange={onRadioButtonChange}
                      radioFieldName={"mustSkills"}
                      regenerateBtnClickHandler={regenerateBtnClickHandler}
                      updateBtnHandler={updateBtnHandler}
                      generateJobSectionAI={generateJobSectionAI}
                      setFieldPrompt={setFieldPrompt}
                    />
                    {/* Recommended Section End */}
                  </div>
                  <div
                    className={`signup-company-information job-textarea-box f-feild`}
                  >
                    <label for="flink">Nice To Have</label>
                    <br />
                    <ReactQuill
                      theme="snow"
                      className={`${isFormFieldValid(
                        "niceToHave",
                      )} && "error-input"`}
                      value={formik?.values?.niceToHave ?? ""}
                      name="niceToHave"
                      modules={modules}
                      onChange={(data) => {
                        formik.setFieldValue("niceToHave", data);
                        formik.handleChange("niceToHave");
                      }}
                      onBlur={() => {
                        formik.handleBlur("niceToHave");
                      }}
                    />

                    {/*<BulletedTextArea
                  className={`custom-bulleted-textarea form-font-f ${
                    isFormFieldValid("niceToHave") &&
                    "custom-bulleted-textarea-error"
                  }`}
                  name="niceToHave"
                  onChange={(value) =>
                    formik.setFieldValue("niceToHave", value)
                  }
                  values={formik?.values?.niceToHave}
                  bulletChar={""}
                />*/}
                    {/* Recommended Section Start */}
                    <RecommendedSection
                      data={recNiceToHave}
                      field={"niceToHave"}
                      promptText={niceToHavePrompt}
                      fieldNewValue={niceToHaveNewValue}
                      onRadioButtonChange={onRadioButtonChange}
                      radioFieldName={"niceToHaves"}
                      regenerateBtnClickHandler={regenerateBtnClickHandler}
                      updateBtnHandler={updateBtnHandler}
                      generateJobSectionAI={generateJobSectionAI}
                      setFieldPrompt={setFieldPrompt}
                    />
                    {/* Recommended Section End */}
                  </div>
                  <div
                    className={`signup-company-information job-textarea-box f-feild ${
                      isFormFieldValid("benefits") && "error-input"
                    }`}
                  >
                    <label for="flink">Benefits</label>
                    <br />

                    <ReactQuill
                      theme="snow"
                      className={`${isFormFieldValid(
                        "benefits",
                      )} && "error-input"`}
                      value={formik?.values?.benefits ?? ""}
                      name="benefits"
                      modules={modules}
                      onChange={(data) => {
                        formik.setFieldValue("benefits", data);
                        formik.handleChange("benefits");
                      }}
                      onBlur={() => {
                        formik.handleBlur("benefits");
                      }}
                    />

                    {/*<BulletedTextArea
                  className={`custom-bulleted-textarea form-font-f ${
                    isFormFieldValid("benefits") &&
                    "custom-bulleted-textarea-error"
                  }`}
                  name="benefits"
                  onChange={(value) => formik.setFieldValue("benefits", value)}
                  values={formik?.values?.benefits}
                  bulletChar={""}
                />*/}
                    {getFormErrorMessage("benefits")}
                    {/* Recommended Section Start */}
                    <RecommendedSection
                      data={recBenefits}
                      field={"benefits"}
                      promptText={benefitsPrompt}
                      fieldNewValue={benefitsNewValue}
                      onRadioButtonChange={onRadioButtonChange}
                      radioFieldName={"benefit"}
                      regenerateBtnClickHandler={regenerateBtnClickHandler}
                      updateBtnHandler={updateBtnHandler}
                      generateJobSectionAI={generateJobSectionAI}
                      setFieldPrompt={setFieldPrompt}
                    />
                    {/* Recommended Section End */}
                  </div>
                  <div
                    className={`signup-company-information job-textarea-box f-feild ${
                      isFormFieldValid("workingHours") && "error-input"
                    }`}
                  >
                    <label for="flink">Working Hours</label>
                    <br />

                    <ReactQuill
                      theme="snow"
                      className={`${isFormFieldValid(
                        "workingHours",
                      )} && "error-input"`}
                      value={formik?.values?.workingHours ?? ""}
                      name="workingHours"
                      modules={modules}
                      onChange={(data) => {
                        formik.setFieldValue("workingHours", data);
                        formik.handleChange("workingHours");
                      }}
                      onBlur={() => {
                        formik.handleBlur("workingHours");
                      }}
                    />

                    {/* <BulletedTextArea
                  className={`custom-bulleted-textarea form-font-f ${
                    isFormFieldValid("workingHours") &&
                    "custom-bulleted-textarea-error"
                  }`}
                  name="workingHours"
                  onChange={(value) =>
                    formik.setFieldValue("workingHours", value)
                  }
                  values={formik?.values?.workingHours}
                  bulletChar={""}
                />*/}
                    {getFormErrorMessage("workingHours")}
                    {/* Recommended Section Start */}
                    <RecommendedSection
                      data={recWorkingHours}
                      field={"workingHours"}
                      promptText={workingHoursPrompt}
                      fieldNewValue={workingHoursNewValue}
                      onRadioButtonChange={onRadioButtonChange}
                      radioFieldName={"benefit"}
                      regenerateBtnClickHandler={regenerateBtnClickHandler}
                      updateBtnHandler={updateBtnHandler}
                      generateJobSectionAI={generateJobSectionAI}
                      setFieldPrompt={setFieldPrompt}
                    />
                    {/* Recommended Section End */}
                  </div>
                  <div
                    className={`signup-company-information job-textarea-box f-feild ${
                      isFormFieldValid("interviewPro") && "error-input"
                    }`}
                  >
                    <label for="flink">Interview Process</label>
                    <br />

                    <ReactQuill
                      theme="snow"
                      className={`${isFormFieldValid(
                        "interviewPro",
                      )} && "error-input"`}
                      value={formik?.values?.interviewPro ?? ""}
                      name="interviewPro"
                      modules={modules}
                      onChange={(data) => {
                        formik.setFieldValue("interviewPro", data);
                        formik.handleChange("interviewPro");
                      }}
                      onBlur={() => {
                        formik.handleBlur("interviewPro");
                      }}
                    />

                    {/*<BulletedTextArea
                  className={`custom-bulleted-textarea form-font-f ${
                    isFormFieldValid("interviewPro") &&
                    "custom-bulleted-textarea-error"
                  }`}
                  name="interviewPro"
                  onChange={(value) =>
                    formik.setFieldValue("interviewPro", value)
                  }
                  values={formik?.values?.interviewPro}
                  bulletChar={""}
                />*/}
                    {getFormErrorMessage("interviewPro")}
                    {/* Recommended Section Start */}
                    <RecommendedSection
                      data={recInterviewPro}
                      field={"interviewPro"}
                      promptText={interviewProPrompt}
                      fieldNewValue={interviewProNewValue}
                      onRadioButtonChange={onRadioButtonChange}
                      radioFieldName={"interviewProcess"}
                      regenerateBtnClickHandler={regenerateBtnClickHandler}
                      updateBtnHandler={updateBtnHandler}
                      generateJobSectionAI={generateJobSectionAI}
                      setFieldPrompt={setFieldPrompt}
                    />
                    {/* Recommended Section End */}
                  </div>
                  <div
                    className={`signup-company-information job-textarea-box f-feild`}
                  >
                    <label for="flink">Recruiter Notes</label>
                    <br />

                    <ReactQuill
                      theme="snow"
                      className={`${isFormFieldValid(
                        "recNotes",
                      )} && "error-input"`}
                      value={formik?.values?.recNotes ?? ""}
                      name="recNotes"
                      modules={modules}
                      onChange={(data) => {
                        formik.setFieldValue("recNotes", data);
                        formik.handleChange("recNotes");
                      }}
                      onBlur={() => {
                        formik.handleBlur("recNotes");
                      }}
                    />

                    {/*<BulletedTextArea
                  className={`custom-bulleted-textarea form-font-f ${
                    isFormFieldValid("recNotes") &&
                    "custom-bulleted-textarea-error"
                  }`}
                  name="recNotes"
                  onChange={(value) => formik.setFieldValue("recNotes", value)}
                  values={formik?.values?.recNotes}
                  bulletChar={""}
                />*/}
                    {/* Recommended Section Start */}
                    <RecommendedSection
                      data={recRecruiterNotes}
                      field={"recNotes"}
                      promptText={recruiterNotesPrompt}
                      fieldNewValue={recNotesNewValue}
                      onRadioButtonChange={onRadioButtonChange}
                      radioFieldName={"recruiterNotes"}
                      regenerateBtnClickHandler={regenerateBtnClickHandler}
                      updateBtnHandler={updateBtnHandler}
                      generateJobSectionAI={generateJobSectionAI}
                      setFieldPrompt={setFieldPrompt}
                    />
                    {/* Recommended Section End */}
                  </div>
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setAllSkillTagsHide(true);
                    }}
                  >
                    <div
                      className={`signup-skill-tags job-textarea-box f-feild tags-tittles ${
                        emptySkill && "error-input"
                      }`}
                    >
                      <label for="fjobtitles">Tech Tags</label>
                      <input
                        className="form-font-f"
                        type="text"
                        name="skillTags"
                        value={formik.values.skillTags}
                        onChange={(e) =>
                          onSkillTagsChange(e.currentTarget.value)
                        }
                        placeholder="Tech Tags"
                      />
                      {allSkillTags?.length > 0 && (
                        <div
                          className={`job-tags-titles-dropdown ${
                            allSkillTagsHide && "job-dropdown-hide"
                          }`}
                        >
                          <ul className="dorpdown-list-location">
                            {allSkillTags.map((j, i) => (
                              <li
                                key={i}
                                onClick={() => {
                                  onSkillTagsClick(j);
                                }}
                              >
                                {" "}
                                {j?.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}

                      {selectSkillTags?.length > 0 && (
                        <ul class="tags-titles-list">
                          {selectSkillTags.map((j, i) => (
                            <li key={i}>
                              {j?.name}
                              <span onClick={() => onSkillTagsCancel(j)}>
                                <img src="../image/cross.png" alt="" />
                              </span>
                            </li>
                          ))}
                        </ul>
                      )}
                      {emptySkill && (
                        <p className="error-msg">Please enter key job Skills</p>
                      )}
                    </div>
                  </OutsideClickHandler>
                </>
              ) : null}

              <div className="signup-salary f-feild">
                <label for="fsalary">Salary Range</label>
                <br />
                <div className="salary-range-start-end">
                  <div
                    className={`merge-feild-1 ${
                      isFormFieldValid("salaryStart") && "error-input"
                    }`}
                  >
                    <div
                      className={`salary-f-job ${
                        isFormFieldValid("currency") && "error-input"
                      }`}
                    >
                      <div className="salary-start">
                        <div
                          id="fsalary-country"
                          className="company-form-dropdownBox-f"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {formik?.values?.currency !== ""
                            ? formik?.values?.currency
                            : "USD $"}
                        </div>

                        <div className="list-search-dropdown-country-job">
                          {currencyOption?.length > 0 && (
                            <ul className="list-job-title-country">
                              {currencyOption.map((prod) => (
                                <li
                                  onClick={() =>
                                    onCurrencyClick(prod?.id, prod?.title)
                                  }
                                >
                                  <img src="/image/CA.png" alt="" />{" "}
                                  {`${prod?.title}`}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                    <input
                      className="form-font-f"
                      type="text"
                      name="salaryStart"
                      id="salary-start-range"
                      value={formik?.values?.salaryStart}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>

                  <div className="merge-feild-2">
                    <p className="salryto">To</p>
                  </div>
                  <div
                    className={`merge-feild-3 ${
                      isFormFieldValid("salaryEnd") && "error-input"
                    }`}
                  >
                    <input
                      className="form-font-f"
                      type="text"
                      name="salaryEnd"
                      id="salary-end"
                      value={formik?.values?.salaryEnd}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="merge-feild-4">
                    <div
                      className={`${
                        isFormFieldValid("salaryRange") && "error-input"
                      }`}
                    >
                      <div className="Year">
                        <div
                          id="fsalary-year"
                          className="company-form-dropdownBox-f"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {formik?.values?.salaryRange !== ""
                            ? capitalizeFirstLetter(formik?.values?.salaryRange)
                            : "Year"}
                        </div>

                        {salaryRangeOption?.length > 0 && (
                          <div className="list-search-dropdown-year-job">
                            <ul className="list-job-title-year-job">
                              {salaryRangeOption.map((prod) => (
                                <li
                                  onClick={() =>
                                    onSalaryRangeClick(prod?.value)
                                  }
                                >
                                  {" "}
                                  {`${capitalizeFirstLetter(prod?.value)}`}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <CustomSelect
                id="choose-jobs-type"
                name="jobType"
                className="dropdown-icon-postjob form-font-f"
                label="Job Type"
                label2="Job Type"
                logo="/image/c-z-f.png"
                borderRed={isFormFieldValid("jobType")}
                errorMsg={getFormErrorMessage("jobType")}
                value={formik.values.jobType}
                data={jobTypeOption}
                onClickHandler={onJobTypeClick}
              />
              <div
                className={`last-four-feild-post-job ${
                  formik.values.jobPosition !== "" &&
                  "show-feild-after-jobpostion-select"
                }`}
              >
                <CustomSelect
                  id="choose-jobs"
                  name="industry"
                  className="dropdown-icon-postjob form-font-f"
                  label="Industry"
                  label2="Industry"
                  borderRed={isFormFieldValid("industry")}
                  errorMsg={getFormErrorMessage("industry")}
                  logo={"/image/team-f.png"}
                  data={industryOption}
                  value={formik?.values?.industry}
                  onClickHandler={onIndustryClick}
                />
                <CustomSelect
                  id="choose-jobs"
                  name="reportingTo"
                  className="dropdown-icon-postjob form-font-f"
                  label="Reporting To"
                  label2="Reporting To"
                  borderRed={isFormFieldValid("reportingTo")}
                  errorMsg={getFormErrorMessage("reportingTo")}
                  logo={"/image/team-f.png"}
                  data={reportingToOption}
                  value={formik?.values?.reportingTo}
                  onClickHandler={onReportToClick}
                />

                <CustomSelect
                  id="choose-jobs"
                  name="companySize"
                  className="dropdown-icon-postjob form-font-f"
                  label="Company Size"
                  label2="Company Size"
                  borderRed={isFormFieldValid("companySize")}
                  errorMsg={getFormErrorMessage("companySize")}
                  logo={"/image/c-z-f.png"}
                  data={companySizeOption}
                  value={formik?.values?.companySize}
                  onClickHandler={onCompanySizeClick}
                />

                <CustomSelect
                  id="choose-jobs"
                  name="WorkMethodology"
                  className="dropdown-icon-postjob form-font-f"
                  label="Work Methodology"
                  label2="Work Methodology"
                  borderRed={isFormFieldValid("WorkMethodology")}
                  errorMsg={getFormErrorMessage("WorkMethodology")}
                  logo={"/image/w-m-f.png"}
                  data={WorkMethOption}
                  value={formik?.values?.WorkMethodology}
                  onClickHandler={onWorkMethClick}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="cities-form-feild cities-center-in-form">
        <div className="signup-select-cities-jobs f-feild">
          <p className="cities-job-heading">
            Select a City/Cities to Post a Job
          </p>
          <div className="img-radion-btn">
            <div className="city-img">
              <img src="/image/cityimg.png" alt="" />
            </div>
            <div className="cities-btn">
              {cityOption?.length > 0 &&
                cityOption.map((prod) => (
                  <div className="city-radio-btn">
                    <input
                      id="job-postion"
                      type="radio"
                      checked={selectCities.includes(prod.id)}
                      onClick={() => onCitiesClick(prod?.id)}
                    />{" "}
                    <label for="">{prod?.name}</label>
                    <br />
                  </div>
                ))}
            </div>
          </div>
          {emptyCity && (
            <p class="error-msg">Please select at-least one city</p>
          )}
        </div>
      </div>

      <div className="candidate-from-signup-create-final-job btn-next-pre">
        <button
          className="btn-next-previous btn-next-pre-toggle"
          onClick={async () => {
            await formik.setFieldValue("status", "draft");
            formik.handleSubmit();
          }}
        >
          {loader && formik?.values?.status === "draft" ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            " Post Later"
          )}
        </button>
        <button
          className="btn-job-post"
          type="submit"
          onClick={async () => {
            if (jobDecision === JOB_POST_DECISION.ZOE) {
              await formik.setFieldValue("status", "published");
              formik.handleSubmit();
            }

            const postNow = async () => {
              await formik.setFieldValue("status", "published");
              formik.handleSubmit();
            };

            setHandleNoButtonPostNow(() => postNow);

            if (jobDecision === JOB_POST_DECISION.COPY_PASTE) {
              dispatch(openZoeModal());
              setShowPromptMessageField(false);
              return;
            }

            if (jobDecision === null) {
              await formik.setFieldValue("status", "published");
              formik.handleSubmit();
            }
          }}
        >
          {loader && formik?.values?.status === "published" ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Post Now"
          )}
        </button>
      </div>
    </>
  );
};

export default PostJobForm;
