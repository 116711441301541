import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setActiveSideBar,
  setSelectedDep,
  setSelectedRoleR,
} from "../../../redux/slices/AdminDashboardSlice";
import TableError from "../../../components/Common/Admin/TabelError/TableError";
import TablePagination from "../../../components/Common/Admin/TablePagination/TablePagination";
import APIPvt from "../../../apiPvt";
import "./index.css";

const AdminUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [msg, setMsg] = useState("");

  const [userListingData, setUserListingData] = useState([]);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState([]);
  const [role, setRole] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState({
    label: "",
    value: null,
  });

  const [selectedRole, setSelectedRole] = useState({
    id: null,
    title: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const navHandler = () => {
    dispatch(setSelectedDep({ id: null, name: "" }));

    dispatch(setSelectedRoleR({ id: null, name: "" }));

    dispatch(
      setActiveSideBar({
        id: "2",
        name: "Users",
        value: "Create User",
      })
    );
    navigate("/admin-system-user");
  };

  const onSearchChange = (value) => {
    setSearch(value);
  };

  const onStatusClick = (label, value) => {
    setSelectedStatus({
      label: label,
      value: value,
    });
  };

  const onPageChange = (page) => {
    if (page >= 1 && page <= totalPages) setCurrentPage(page);
  };

  const onRoleClick = (id, title) => {
    setSelectedRole({
      id: id,
      title: title,
    });
  };

  const getUserListingData = async () => {
    try {
      let params = `?`;
      if (search !== "") {
        params += `search=${search}&`;
      }
      if (selectedRole?.id !== null) {
        params += `role_id=${selectedRole?.id}&`;
      }
      if (selectedStatus?.value !== null) {
        params += `status=${selectedStatus?.value}&`;
      }
      if (currentPage !== null) {
        params += `page=${currentPage}&`;
      }
      setMsg("");
      await APIPvt.get(`/users${params}`)
        .then((response) => {
          if (response?.status === 200 || response?.success === true) {
            let res = response?.data;
            setUserListingData(res?.data);
            setRole(res?.roles);
            setStatus(res?.status);
            setTotalPages(res?.last_page);
            if (
              res?.data?.length === 0 &&
              (search === "" ||
                selectedRole?.id === null ||
                selectedStatus?.value === null)
            )
              setMsg("Please Create a User to view data.");
            else if (
              res?.data?.length === 0 &&
              (search !== "" ||
                selectedRole?.id !== null ||
                selectedStatus?.value !== null)
            )
              setMsg("Please make another selection. No record found.");
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserListingData();
  }, [selectedStatus, selectedRole, search, currentPage]);

  return (
    <div className="page-space bg-clr-admin">
      <div className="page-title-heading">
        <h2>Users</h2>
      </div>{" "}
      <button
        className="create-department"
        type="text"
        onClick={() => navHandler()}
      >
        Create Users +
      </button>
      <div className="filter-search-bar">
        <div className="two-drop-role">
          <div className="col-left-adminfilter">
            <p className="filter-label-admin">Roles</p>
            <div className="admin-status-filter">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-filter-admin">
                  <p
                    className={` ${
                      selectedRole?.title !== "" && "text-filter-select"
                    }`}
                  >
                    {" "}
                    {selectedRole?.title === "" ? "Roles" : selectedRole?.title}
                  </p>
                  <img src="/image/admin-d.png" alt="" />
                </div>
              </div>

              {role?.length > 0 && (
                <div className="admin-status-dropdown">
                  <ul className="list-job-title-company">
                    {role?.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          onRoleClick(item?.id, item?.title);
                        }}
                      >
                        {item?.title}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="col-left-adminfilter">
            <p className="filter-label-admin">Status</p>
            <div className="admin-status-filter">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-filter-admin">
                  <p
                    className={` ${
                      selectedStatus?.label !== "" && "text-filter-select"
                    }`}
                  >
                    {" "}
                    {selectedStatus?.label === ""
                      ? "Status"
                      : selectedStatus?.label}
                  </p>
                  <img src="/image/admin-d.png" alt="" />
                </div>
              </div>

              {status?.length > 0 && (
                <div className="admin-status-dropdown">
                  <ul className="list-job-title-company">
                    {status?.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          onStatusClick(item?.label, item?.value);
                        }}
                      >
                        {item?.label}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col-right-searcgadmin">
          <div className="search-admin">
            <img src="/image/job-search.png" alt="" />
            <input
              type="text"
              name="search"
              id="jon-search-box"
              placeholder="Search"
              className="form-font-f"
              onChange={(e) => {
                onSearchChange(e?.target?.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="department-table-imge">
        <div className="col-user-table">
          <div className="table-responsive">
            <table className="depart-table-left">
              <thead className="tb-heading-user">
                <tr>
                  <th scope="col">
                    <div className="th-admin-heading">First Name</div>
                  </th>
                  <th scope="col">
                    <div className="th-admin-heading">Last Name</div>
                  </th>
                  <th scope="col" className="size-th-user">
                    <div className="th-admin-heading">Role Name</div>
                  </th>
                  <th scope="col">
                    <div className="th-admin-heading">User Name</div>
                  </th>
                  <th scope="col" className="size-th-user">
                    <div className="th-admin-heading">Email</div>
                  </th>
                  <th scope="col">
                    <div className="th-admin-heading">Actions</div>
                  </th>
                </tr>
              </thead>
              <tbody className="tb-user">
                {userListingData?.length > 0 &&
                  userListingData?.map((item, index) => (
                    <tr>
                      <td className="">
                        <div className="name-sr-circle">{item?.first_name}</div>
                      </td>
                      <td>{item?.last_name}</td>
                      <td>{item?.roles[0]?.title}</td>
                      <td>{item?.username}</td>
                      <td>{item?.email}</td>
                      <td>
                        <div className="four-role-img">
                          <img
                            src="/image/role-d.png"
                            alt=""
                            className="show-tooltip icon-cursor"
                          />
                          <img
                            src="/image/role-edit.png"
                            alt=""
                            className="show-tooltip2 icon-cursor"
                          />
                          <img
                            src="/image/role-delet.png"
                            alt=""
                            className="show-tooltip3 icon-cursor"
                          />
                          <div className="tooltip-view-role-admin">
                            View Users
                          </div>
                          <div className="tooltip-edit-role-admin">Edit</div>
                          <div className="tooltip-delete-role-admin">
                            Delete
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          {userListingData?.length > 0 && (
            <TablePagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={onPageChange}
            />
          )}
          <TableError msg={msg} />
        </div>
      </div>
    </div>
  );
};

export default AdminUser;
