import { Link } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import "./ResumeModel.css";
import { useSelector } from "react-redux";

const ResumeModel = ({ modelHandler }) => {
  const { resumeUrl } = useSelector((state) => state.candidateDashboard);

  return (
    <>
      {/*<section className="model-candidate-resume">
      <div className="popup-bg">
        <OutsideClickHandler
          onOutsideClick={() => {
            modelHandler(false);
          }}
        >
          <div className="popup-candidate-resume">
            <div
              className="close-img-resume"
              onClick={() => modelHandler(false)}
            >
              <img src="/image/profile-cross.jpg" alt="" />
            </div>
            <div className="model-logo">
              <img src="/image/WORKBRIDGE.png" alt="" />
            </div>
            <div className="resume-data-profile">
              <div className="candidate-name-btn">
                <div className="resume-heading">
                  <div className="download-print-btn">
                    <Link href="#">
                      <button>Print</button>
                    </Link>
                    <Link href="#">
                      <button>Download</button>
                    </Link>
                  </div>
                  <h1>Candidate Resume</h1>
                </div>
              </div>
              <div className="resume-candidate-info">
                <img src="/image/resume-img.png" alt="" />
                <h5>Andrew James</h5>
              </div>
              <div className="candidate-social-info">
                <ul className="candidate-social-list">
                  <p>Customer Service Representative</p>
                  <li>
                    <img src="/image/resume-location.png" alt="" />
                    North Macedonia
                  </li>
                  <li>
                    <img src="/image/resume-chat.png" alt="" />
                    https://www.amazon.ca/
                  </li>
                  <li>
                    <img src="/image/resume-mail.png" alt="" />
                    <a href="mailto:primary@amazon.com">primary@amazon.com</a>
                  </li>
                  <li>
                    <img src="/image/resume-call.png" alt="" />
                    <a href="tel:+995599132862">+995 599132862</a>
                  </li>
                  <li>
                    <img src="/image/resume-linkden.png" alt="" />
                    https://www.linkedin.com/company/amazon?original_referer=...
                  </li>
                </ul>
              </div>
              <div className="resume-profile-accadmic">
                <div className="resume-content-candidate">
                  <p className="profile-resume-heading candiadte-resume-heading">
                    Profile
                  </p>
                  <p className="resum-content">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    orem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the
                    industry'sorem Ipsum is simply dummy text of the printing
                    and typesetting industry.{" "}
                  </p>
                </div>
                <div className="resume-education-candidate">
                  <p className="profile-resume-education candiadte-resume-heading">
                    Education
                  </p>
                  <div className="university-year">
                    <p>University of Toronto</p>
                    <p>2017 - 2021</p>
                  </div>
                  <p className="resume-degree">Masters in I.T</p>
                </div>
                <div className="resume-skills-candidate">
                  <p className="profile-resume-skills candiadte-resume-heading">
                    SKills
                  </p>
                  <ul>
                    <li>Lorem Ipsum</li>
                    <li>Lorem Ipsum</li>
                    <li>Lorem Ipsum</li>
                    <li>Lorem Ipsum</li>
                    <li>Lorem Ipsum</li>
                    <li>Lorem Ipsum</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    </section>*/}

      <section className="model-candidate-resume-iframe">
        <div className="popup-bg-iframe">
          <OutsideClickHandler
            onOutsideClick={() => {
              modelHandler(false);
            }}
          >
            <div className="popup-candidate-resume-iframe">
              <div className="resume-data-profile-iframe">
                <iframe
                  title="resume"
                  // src="https://workbridge.unitedsoftlabs.com/uploads/candidates/resume/40/91yrRX2AWOqWA1UTKy6n8bp7FIuSZahVc6pbftXi.pdf"
                  src={resumeUrl}
                  allow="fullscreen"
                />
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      </section>
    </>
  );
};

export default ResumeModel;
