import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";
import { setSelectJobId } from "../../../redux/slices/JobDetailSlice";
import { setClearFilter } from "../../../redux/slices/JobSearchSlice";
import { useSelector } from "react-redux";
import API from "../../../api";
import JobRecordLeft from "../../../components/Common/JobRecord/JobRecordLeft";
import { setFavoriteJobsCount } from "../../../redux/slices/AuthSlice";
import WishlistModel from "../../../components/Common/WishlistModel/WishlistModel";
import "./index.css";

const CanWishlist = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [userWishList, setUserWishList] = useState([]);
  const [toggleModal, setToggleModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [isApplyAllButtonClicked, setIsApplyAllButtonClicked] = useState(false);
  const [isClearAllButtonClicked, setIsClearAllButtonClicked] = useState(false);
  const [isYesButtonClicked, setIsYesButtonClicked] = useState(false);

  const showModal = () => {
    setToggleModal(true);
  };

  const closeModal = () => {
    setToggleModal(false);
  };
  const handleNoButtonClicked = () => {
    setIsApplyAllButtonClicked(false);
    setIsClearAllButtonClicked(false);
    closeModal();
  };

  const handleYesButtonClicked = async () => {
    if (isClearAllButtonClicked === true) {
      if (user?.id && userWishList?.length > 0) {
        try {
          await API.get(`/auth/clear-all-wishlist-jobs?user_id=${user?.id}`)
            .then((response) => {
              if (
                response?.status === 200 ||
                response?.status === 201 ||
                response?.status === 304
              ) {
                let res = response?.data;
                if (res?.result === true) {
                  setModalMessage(res?.message[0].success);
                  setUserWishList([]);
                  dispatch(setFavoriteJobsCount(0));
                }
              }
            })
            .catch((error) => {
              if (error) {
                console.log(error);
              }
            });
        } catch (error) {
          console.log(error);
        }
      }
    }

    if (isApplyAllButtonClicked === true) {
      if (user?.id && userWishList.length > 0) {
        try {
          await API.get(`/auth/apply-all-wishlist-jobs?user_id=${user?.id}`)
            .then((response) => {
              if (
                response?.status === 200 ||
                response?.status === 201 ||
                response?.status === 304
              ) {
                let res = response?.data;
                if (res?.result) {
                  setModalMessage(res?.message[0].success);
                  setUserWishList([]);
                  dispatch(setFavoriteJobsCount(0));
                }
              }
            })
            .catch((error) => {
              if (error) {
                console.log(error);
              }
            });
        } catch (error) {
          console.log(error);
        }
      }
    }

    setIsApplyAllButtonClicked(false);
    setIsClearAllButtonClicked(false);
    closeModal();
    setIsYesButtonClicked(true);
  };

  const handleApplyAllButton = () => {
    setIsApplyAllButtonClicked(true);
    setModalMessage(
      "Are you sure you want to Apply for all the jobs in wishlist ?",
    );
    showModal();
  };

  const handleClearAllButton = () => {
    setIsClearAllButtonClicked(true);
    setModalMessage(
      "Are you sure you want to Clear all the jobs from wishlist ?",
    );
    showModal(true);
  };

  const getCandidateFavoriteJobs = async () => {
    if (user?.id) {
      try {
        await API.get(`/get-wishlist?user_id=${user?.id}`)
          .then((response) => {
            if (
              response?.status === 200 ||
              response?.status === 201 ||
              response?.status === 304
            ) {
              let res = response?.data;
              if (res?.result === true) {
                setUserWishList(res?.favorite_jobs);
                dispatch(setFavoriteJobsCount(res?.favorite_jobs?.length));
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const removeFromFavoriteJobs = async (jobId) => {
    if (user?.id && jobId) {
      try {
        const formData = new FormData();
        formData.append("user_id", user?.id);
        formData.append("job_id", jobId);
        await API.post(`/update-whishlist`, formData)
          .then((response) => {
            if (
              response?.status === 200 ||
              response?.status === 201 ||
              response?.status === 304
            ) {
              let res = response?.data;

              if (res?.result === true) {
                dispatch(setFavoriteJobsCount(res?.count));

                let newUserWishList = userWishList.filter(
                  (job) => job.id !== jobId,
                );
                setUserWishList(newUserWishList);
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getCandidateFavoriteJobs();
  }, [isYesButtonClicked]);

  const onDetailJobHandler = (v) => {
    navigate(`/job-view-detail/${v}`);
  };

  // if (userWishList.length === 0) {
  //   return (
  //     <>
  //       <section className="wishlist-sec">
  //         <div className="container-site">
  //           <div className="job-heading-wishlist">
  //             <h2>My Wishlist</h2>
  //           </div>
  //           <h2>No favorite jobs found </h2>
  //           <div className="divider"></div>
  //         </div>
  //       </section>
  //       <WorldConnect />
  //     </>
  //   );
  // }

  return (
    <>
      <section className="wishlist-sec">
        <div className="container-site">
          <div className="job-heading-wishlist">
            <h2>My Wishlist</h2>
          </div>
          {userWishList.length > 0 &&
            userWishList.map((job, index) => (
              <Fragment key={job?.id}>
                <div className="divider"></div>
                <div className="Job-Opportunities-row">
                  <JobRecordLeft JobData={job} />
                  <div className="job-announcements">
                    <div className="job-announcements-col-candidate">
                      <a
                        onClick={() => onDetailJobHandler(job?.id)}
                      >
                        <button>View detailed Job Position</button>
                      </a>
                    </div>
                    <div className="job-wishlist-btn">
                      <button onClick={() => removeFromFavoriteJobs(job?.id)}>
                        Delete From List
                      </button>
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
          <div className="divider"></div>
        </div>
      </section>
      <section className="wishlist-btn-sec">
        <div className="container-site">
          <div className="whishlist-three-btn-stack">
            <div className="wishlist-apply-btn whishlist-btn">
              <button onClick={handleApplyAllButton}>Apply All</button>
            </div>
            <div className="wishlist-clear-btn whishlist-btn">
              <button onClick={handleClearAllButton}>Clear All</button>
            </div>
            <div className="wishlist-findjob-btn whishlist-btn">
              <Link to="/job-search" onClick={() => dispatch(setClearFilter())}>
                <button>Find another job</button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <WorldConnect />
      {toggleModal ? (
        <WishlistModel
          message={modalMessage}
          isApplyAllButtonClicked={isApplyAllButtonClicked}       
          closeModal={closeModal}
          handleNoButtonClicked={handleNoButtonClicked}
          handleYesButtonClicked={handleYesButtonClicked}
        />
      ) : null}
    </>
  );
};

export default CanWishlist;
