import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import { setActiveSideBar } from "../../../redux/slices/AdminDashboardSlice";
import { clearLoginUser } from "../../../redux/slices/AuthSlice";
import "./AdminHeader.css";

const AdminHeader = () => {
  const dispatch = useDispatch();
  const { userName } = useSelector((state) => state.auth);
  const { activeSideBar } = useSelector((state) => state.adminDashboard);

  const [hideMenu, setHideMenu] = useState(true);

  const onLogOutHandler = () => {
    dispatch(clearLoginUser());
    localStorage.removeItem("token");
  }

  return (
    <div className="header-sidebar">
      <div className="row-header">
        <div className="col-left-sidebar">
          <div className="text-header-sidebar">
            <p>Dashboard /</p>
            <span className="active">{activeSideBar?.value}</span>
          </div>
        </div>
        <div className="col-right-sidebar">
          <div className="department-name-img icon-cursor">
            <a href="#">
              <img src="/image/profile.png" alt="" />
              <p className="department-name">
                Hi, {userName !== "" ? capitalizeFirstLetter(userName) : "John"}
              </p>
              <OutsideClickHandler
                onOutsideClick={() => {
                  setHideMenu(true);
                }}
              >
                <div
                  className="toggle-img-admin"
                  onClick={() => setHideMenu(!hideMenu)}
                >
                  <img src="/image/menu-profile.png" alt="" />
                </div>
              </OutsideClickHandler>
            </a>
          </div>
          <div className="all-flag-sidebar">
            <div className="headder-flag-sidebar">
              <div className="flag-list active">
                <img src="/image/flag-1.png" alt="" />
                <div className="flag-caption active">
                  <p>North Macedonia</p>
                </div>
              </div>
            </div>
            <div className="headder-flag-sidebar">
              <div className="flag-list">
                <img src="/image/flag-2.png" alt="" />
              </div>
              <div className="flag-caption">
                <p>North Macedonia</p>
              </div>
            </div>
            <div className="headder-flag-sidebar">
              <div className="flag-list">
                <img src="/image/flag-3.png" alt="" />
              </div>
              <div className="flag-caption">
                <p>North Macedonia</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`dropdown-dashbord-admin ${hideMenu && "hide-show-click"}`}
        onClick={() => setHideMenu(true)}
      >
        <ul className="dropdown-menu-dashbord">
          <Link
            to="/employer-dashboard"
            onClick={() =>
              dispatch(
                setActiveSideBar({
                  id: "1",
                  name: "Modules",
                  value: "Modules",
                })
              )
            }
          >
            <li className="menu-dropdown">
              <img src="/image/dash-m.png" alt="" />
              Job Portal
            </li>
          </Link>
          <Link to="/employer-profile">
            <li className="menu-dropdown">
              <img src="/image/profl-m.png" alt="" />
              My Profile
            </li>
          </Link>
          <Link to="/" onClick={() => onLogOutHandler()}>
            <li className="menu-dropdown">
              <img src="/image/log-m.png" alt="" />
              Logout
            </li>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default AdminHeader;
