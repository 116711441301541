import { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import { useSelector } from "react-redux";

export const AdminRoutes = () => {
  const [isLogged, setIsLogged] = useState(true);
  const { isEmailVerify, user } = useSelector((state) => state.auth);

  return (isEmailVerify && user?.user_type === "employer") ? (
    <AdminLayout>
      <Outlet />
    </AdminLayout>
  ) : (
    <Navigate to="/" replace />
  );
};

