import React from "react";
import Banner from "../../components/Home/Banner/Banner";
import LogoSection from "../../components/Home/LogoSection/LogoSection";
import CompanyCard from "../../components/Home/CompanyCard/CompanyCard";
import CompanySection from "../../components/Home/CompanySection/CompanySection";
import JobSection from "../../components/Home/JobSection/JobSection";
import CountrySection from "../../components/Home/CountrySection/CountrySection";
import CookiesSection from "../../components/Home/CookiesSection/CookiesSection";
import LoginModel from "../../components/Common/LoginModel/LoginModel";

const Home = () => {
  return (
    <>  
      <Banner />
      <LogoSection />
      <CompanyCard />
      <CompanySection />
      <JobSection />
      <CountrySection />
      <CookiesSection/>
      <LoginModel/>
    </>
  );
};

export default Home;
