import { createSlice } from "@reduxjs/toolkit";

const initialState = {

};
const ProfileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
  }
});

// Action creators are generated for each case reducer function
export const {

} = ProfileSlice.actions;

export default ProfileSlice.reducer;



