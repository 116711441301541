import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BaseInfo from "../../../components/Employer/Dashboard/BaseInfo";
import NewApplicants from "../../../components/Employer/Dashboard/NewApplicants";
import JobListing from "../../../components/Employer/Dashboard/JobListing";
import JobDrafts from "../../../components/Employer/Dashboard/JobDrafts";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";
import ResumeModel from "../../../components/Common/ResumeModel/ResumeModel";
import CommentModel from "../../../components/Common/CommentModel/CommentModel";
import {
  candidateFirstImpressionChangeAsync,
  candidateStatusChangeAsync,
  changeCandidateStatusToResumeReviewedAsync,
  changeJobStatusAsync,
  favoriteCandidateAsync,
  getSystemFieldAsync,
} from "../../../redux/slices/employerDashboardSlice";
import API from "../../../api";
import "./index.css";
import { CANDIDATE_STATUSES } from "../../../constants";
import {
  addJobCommentAsync,
  emptyCommentsModalData,
  setCandidateId,
  setJobId,
} from "../../../redux/slices/commentModalSlice";

const EmpDashboard = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { candidateStatuses } = useSelector((state) => state.employerDashboard);
  /*Counter*/
  const [totalApplicants, setTotalApplicants] = useState(0);
  const [jobListingsCount, setJobListingsCount] = useState(0);
  const [draftJobsCount, setDraftJobsCount] = useState(0);
  const [favoriteCount, setFavoriteCount] = useState(0);

  /*Session*/
  const [applicants, setApplicants] = useState([]);
  const [publishedJobs, setPublishedJobs] = useState([]);
  const [draftJobs, setDraftJobs] = useState([]);
  const [showJobListing, setShowJobListing] = useState(false);

  /*Job Listing Filter*/
  const [jobTitles, setJobTitles] = useState([]);
  const [datePosted, setDatePosted] = useState([]);
  const [expiryDate, setExpiryDate] = useState([]);
  const [cities, setcities] = useState([]);
  const [jobStatus, setJobStatus] = useState([]);

  /* new applicants filter selected value */
  const [selectedImpression, setSelectedImpression] = useState({
    id: null,
    text: null,
  });

  const [selectedCandidateStatus, setSelectedCandidateStatus] = useState({
    id: null,
    text: null,
  });

  const [searchNewApplicant, setSearchNewApplicant] = useState(null);

  /*Job Listing Filter Selected Value*/
  const [selectedJobTitile, setSelectedJobTitile] = useState({
    jobId: null,
    jobTitle: "",
  });
  const [search, setSearch] = useState("");
  const [selectedDatePostedLabel, setSelectedDatePostedLabel] = useState("");
  const [selectedDatePosted, setSelectedDatePosted] = useState("");
  const [selectedExpiryDateLabel, setSelectedExpiryDateLabel] = useState("");
  const [selectedExpiryDate, setSelectedExpiryDate] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCityId, setSelectedCityId] = useState(null);
  const [selectedJobStatus, setSelectedJobStatus] = useState({
    label: "",
    value: null,
  });

  /*Toggle Model*/
  const [toggleModel, setToggleModel] = useState(false);
  const [toggleModelC, setToggleModelC] = useState(false);

  const commentRemoved = (commentId, commentToReplace) => {
    const updatedApplicants = applicants.map((applicant) => {
      if (applicant?.comments?.id === commentId) {
        const newComment = {
          ...commentToReplace,
          comments_count: applicant?.comments?.comments_count > 0 ? applicant?.comments?.comments_count - 1 : 0,
        };

        if (commentToReplace === "") {
          return {
            ...applicant,
            comments: "",
          };
        }

        return {
          ...applicant,
          comments: {
            ...newComment,
          },
        };
      }
      return applicant;
    });
    setApplicants(updatedApplicants);
  };

  const commentAdded = (candidateId, jobId, comment) => {
    const updatedApplicants = applicants.map((applicant) => {
      if (
        applicant?.account_id === candidateId &&
        applicant?.job_id === jobId
      ) {

        return {
          ...applicant,
          comments: {
            ...comment,
            comments_count: applicant?.comments?.comments_count + 1,
          },
        };
      }
      return applicant;
    });
    setApplicants(updatedApplicants);
  };

  const handleNewCommentSubmit = async (text, jobId, candidateId) => {
    if (text === null || text === undefined || text === "") return;
    if (jobId === null || jobId === undefined || jobId === "") return;
    if (candidateId === null || candidateId === undefined || candidateId === "")
      return;

    dispatch(
      addJobCommentAsync({
        userId: user?.id,
        jobId: jobId,
        candidateId: candidateId,
        commentText: text,
      }),
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedApplicants = applicants.map((applicant) => {
            if (
              applicant?.account_id === candidateId &&
              applicant?.job_id === jobId
            ) {
              return {
                ...applicant,
                comments: {
                  ...res?.data[0]?.comments[0],
                  comments_count: 0,
                },
              };
            }
            return applicant;
          });
          setApplicants(updatedApplicants);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const modelCHandler = (v, candidate_id, job_id) => {
    setToggleModelC(v);

    if (v === false) {
      dispatch(emptyCommentsModalData());
      return;
    }
    if (
      candidate_id === null ||
      candidate_id === undefined ||
      candidate_id === "" ||
      job_id === null ||
      job_id === undefined ||
      job_id === ""
    ) {
      setToggleModelC(false);
      return;
    }

    dispatch(setJobId(job_id));
    dispatch(setCandidateId(candidate_id));
  };
  const modelHandler = (v) => setToggleModel(v);

  const onJobTitleClick = (job_id, job_title) => {
    setSelectedJobTitile({
      jobId: job_id,
      jobTitle: job_title,
    });
  };

  const onJobTitleClean = () => {
    setSelectedJobTitile({
      jobId: null,
      jobTitle: "",
    });
  };

  const onResumeClickHandler = async (candidateId, jobId, currentStatus) => {
    if (currentStatus !== CANDIDATE_STATUSES.NEW_APPLICANT) return;

    dispatch(
      changeCandidateStatusToResumeReviewedAsync({
        candidateId: candidateId,
        jobId: jobId,
      }),
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedApplicants = applicants.map((applicant) => {
            if (
              applicant?.account_id === candidateId &&
              applicant?.job_id === jobId
            ) {
              return {
                ...applicant,
                status_id: res?.status_id,
                status: res?.status,
              };
            }
            return applicant;
          });
          setApplicants(updatedApplicants);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /* new applicants on first impression click */
  const onFirstImpressionClick = async (
    candidateId,
    jobId,
    impressionId,
    currentStatusId,
  ) => {
    if (currentStatusId === CANDIDATE_STATUSES.NEW_APPLICANT) return;
    if (candidateId === null || jobId === null || impressionId === null) return;

    dispatch(
      candidateFirstImpressionChangeAsync({
        candidateId: candidateId,
        jobId: jobId,
        impressionId: impressionId,
      }),
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedApplicants = applicants.map((applicant) => {
            if (
              applicant?.account_id === candidateId &&
              applicant?.job_id === jobId
            ) {
              return {
                ...applicant,
                impression_id: res?.impression_removed === 1 ? 0 : impressionId,
              };
            }
            return applicant;
          });
          setApplicants(updatedApplicants);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /* New Application status Change Handler */
  const onCandidateStatusChangeHandler = async (
    candidateId,
    jobId,
    statusId,
    currentStatusId,
  ) => {
    if (candidateId === null || jobId === null || statusId === null) return;
    if (currentStatusId === statusId) return;
    dispatch(
      candidateStatusChangeAsync({
        candidateId: candidateId,
        statusId: statusId,
        jobId: jobId,
      }),
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedApplicants = applicants.map((applicant) => {
            if (
              applicant?.account_id === candidateId &&
              applicant?.job_id === jobId
            ) {
              const { id, status } = candidateStatuses.find(
                (item) => item.id === statusId,
              );
              return {
                ...applicant,
                status_id: id,
                status: status,
              };
            }
            return applicant;
          });
          setApplicants(updatedApplicants);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /* start new applicant things */

  const onSearchNewApplicantChange = (value) => {
    setSearchNewApplicant(value);
  };

  const onFirstImpressionChange = (id, text) => {
    setSelectedImpression({
      id: id,
      text: text,
    });
  };

  const onFirstImpressionClean = () => {
    setSelectedImpression({
      id: null,
      text: null,
    });
  };

  const onCandidateStatusChange = (id, text) => {
    setSelectedCandidateStatus({
      id: id,
      text: text,
    });
  };

  const onCandidateStatusClean = () => {
    setSelectedCandidateStatus({
      id: null,
      text: null,
    });
  };

  /* End new Applicant things*/

  const onSearchChange = (value) => {
    setSearch(value);
  };

  const onDatePostedClick = (value, label) => {
    setSelectedDatePosted(value);
    setSelectedDatePostedLabel(label);
  };

  const onDatePostedClean = () => {
    setSelectedDatePosted("");
    setSelectedDatePostedLabel("");
  };

  const onExpiryClick = (value, label) => {
    setSelectedExpiryDate(value);
    setSelectedExpiryDateLabel(label);
  };

  const onExpiryClean = () => {
    setSelectedExpiryDate("");
    setSelectedExpiryDateLabel("");
  };

  const onLocationClick = ({ id, name }) => {
    setSelectedCityId(id);
    setSelectedCity(name);
  };

  const onLocationClean = () => {
    setSelectedCityId(null);
    setSelectedCity("");
  };

  const onJobStatusClick = (label, value) => {
    setSelectedJobStatus({
      label: label,
      value: value,
    });
  };

  const onJobStatusClean = () => {
    setSelectedJobStatus({
      label: "",
      value: null,
    });
  };

  const onFvtClick = async (account_id, job_id) => {
    dispatch(
      favoriteCandidateAsync({
        userId: user?.id,
        accountId: account_id,
        jobId: job_id,
      }),
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedApplicants = applicants.map((applicant) => {
            if (
              applicant?.account_id === account_id &&
              applicant?.job_id === job_id
            ) {
              return {
                ...applicant,
                is_favorite: applicant?.is_favorite === 0 ? 1 : 0,
              };
            }
            return applicant;
          });
          setApplicants(updatedApplicants);
          setFavoriteCount(res?.count);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onJobStatusListingClick = async (job_id) => {
    dispatch(
      changeJobStatusAsync({
        jobId: job_id,
      }),
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedJob = publishedJobs.map((job) => {
            if (job?.id === job_id) {
              return {
                ...job,
                is_active: job?.is_active === 0 ? 1 : 0,
              };
            }
            return job;
          });
          setPublishedJobs(updatedJob);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onJobStatusDraftClick = async (job_id) => {
    dispatch(
      changeJobStatusAsync({
        jobId: job_id,
      }),
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedDraftJobs = draftJobs?.filter(
            (job) => job?.id !== job_id,
          );
          const draftjob = draftJobs?.find((job) => job?.id === job_id);

          if (jobListingsCount >= 0) setJobListingsCount(jobListingsCount + 1);
          if (draftJobsCount >= 0) setDraftJobsCount(draftJobsCount - 1);
          setDraftJobs(updatedDraftJobs);
          setPublishedJobs([draftjob, ...publishedJobs]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getEmployerApplicantsData = async () => {
    if (user?.id) {
      let params = `?`;
      if (user !== null) {
        params += `user_id=${user?.id}&`;
      }
      if (selectedImpression?.text !== null) {
        params += `impression_id=${selectedImpression?.id}&`;
      }
      if (selectedCandidateStatus?.text !== null) {
        params += `status_id=${selectedCandidateStatus?.id}&`;
      }
      if (searchNewApplicant !== null) {
        params += `search=${searchNewApplicant}&`;
      }

      try {
        await API.get(`/new-applicants${params}`)
          .then((response) => {
            if (
              response?.status === 200 ||
              response?.status === 201 ||
              response?.status === 304
            ) {
              let res = response?.data;
              if (res?.result === true) {
                setTotalApplicants(res?.total_applicants);
                setFavoriteCount(res?.favorite_applicant_count);
                setApplicants(res?.applicants);
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getEmpJobListingData = async () => {
    if (user?.id) {
      try {
        let params = `?`;
        if (user !== null) {
          params += `user_id=${user?.id}&`;
        }
        if (selectedJobTitile?.jobId !== null) {
          params += `job_id=${selectedJobTitile?.jobId}&`;
        }
        if (search !== "") {
          params += `search=${search}&`;
        }
        if (selectedDatePosted !== "") {
          params += `date_posted=${selectedDatePosted}&`;
        }
        if (selectedExpiryDate !== "") {
          params += `expiry_date=${selectedExpiryDate}&`;
        }
        if (selectedCityId !== null) {
          params += `city_id=${selectedCityId}&`;
        }
        if (selectedJobStatus?.value !== null) {
          params += `is_active=${selectedJobStatus?.value}&`;
        }

        await API.get(`/jobs-listing${params}`)
          .then((response) => {
            if (
              response?.status === 200 ||
              response?.status === 201 ||
              response?.status === 304
            ) {
              let res = response?.data;
              if (res?.result === true) {
                let jobs = response?.data?.jobs;
                setJobListingsCount(res?.job_listings_count);
                setJobTitles(res?.job_titles);
                setPublishedJobs(jobs?.published);

                if (
                  selectedJobTitile?.jobId === null &&
                  search === "" &&
                  selectedDatePosted === "" &&
                  selectedExpiryDate === "" &&
                  selectedCityId === null &&
                  selectedJobStatus?.value === null &&
                  jobs?.published?.length === 0
                ) {
                  setShowJobListing(false);
                } else setShowJobListing(true);
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getEmpJobDraftData = async () => {
    if (user?.id) {
      try {
        await API.get(`/draft-jobs?user_id=${user?.id}`)
          .then((response) => {
            if (
              response?.status === 200 ||
              response?.status === 201 ||
              response?.status === 304
            ) {
              let res = response?.data;
              if (res?.result === true) {
                let jobs = response?.data?.jobs;
                setDraftJobsCount(res?.draft_jobs_count);
                setDraftJobs(jobs?.draft);
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getDatePostValue = async () => {
    dispatch(
      getSystemFieldAsync({
        key: "date_posted",
      }),
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {
          setDatePosted(res?.data);
          setExpiryDate(res?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCityValue = async () => {
    try {
      await API.get(`/get-cities?country_id=1`)
        .then((response) => {
          if (response?.status === 200) {
            let res = response?.data;
            setcities(res);
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getJobStatusValue = async () => {
    dispatch(
      getSystemFieldAsync({
        key: "job_status",
      }),
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {
          setJobStatus(res?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getEmployerApplicantsData();
  }, [searchNewApplicant, selectedImpression, selectedCandidateStatus]);

  useEffect(() => {
    getEmpJobListingData();
  }, [
    selectedJobTitile,
    search,
    selectedDatePosted,
    selectedExpiryDate,
    selectedCityId,
    selectedJobStatus,
  ]);

  useEffect(() => {
    getEmployerApplicantsData();
    getEmpJobListingData();
    getEmpJobDraftData();
    getDatePostValue();
    getCityValue();
    getJobStatusValue();
  }, []);

  return (
    <>
      <BaseInfo
        totalApplicants={totalApplicants}
        jobListingsCount={jobListingsCount}
        draftJobsCount={draftJobsCount}
        favoriteCount={favoriteCount}
      />
      <NewApplicants
        totalApplicants={totalApplicants}
        applicants={applicants}
        onFvtClick={onFvtClick}
        modelHandler={modelHandler}
        modelCHandler={modelCHandler}
        selectedFirstImpression={selectedImpression}
        selectedCandidateStatus={selectedCandidateStatus}
        onSearchChange={onSearchNewApplicantChange}
        onFirstImpressionChange={onFirstImpressionChange}
        onFirstImpressionClean={onFirstImpressionClean}
        onCandidateStatusChange={onCandidateStatusChange}
        onCandidateStatusClean={onCandidateStatusClean}
        onFirstImpressionClick={onFirstImpressionClick}
        onCandidateStatusChangeHandler={onCandidateStatusChangeHandler}
        onResumeClickHandler={onResumeClickHandler}
        handleAddNewCommentHandler={handleNewCommentSubmit}
        handleRemovedComment={commentRemoved}
      />
      <JobListing
        jobTitles={jobTitles}
        selectedJobTitile={selectedJobTitile}
        onJobTitleClean={onJobTitleClean}
        datePosted={datePosted}
        selectedDatePostedLabel={selectedDatePostedLabel}
        selectedDatePosted={selectedDatePosted}
        expiryDate={expiryDate}
        selectedExpiryDateLabel={selectedExpiryDateLabel}
        selectedExpiryDate={selectedExpiryDate}
        cities={cities}
        selectedCity={selectedCity}
        jobStatus={jobStatus}
        selectedJobStatus={selectedJobStatus}
        showJobListing={showJobListing}
        jobListingsCount={jobListingsCount}
        publishedJobs={publishedJobs}
        onJobTitleClick={onJobTitleClick}
        onSearchChange={onSearchChange}
        onDatePostedClick={onDatePostedClick}
        onDatePostedClean={onDatePostedClean}
        onExpiryClick={onExpiryClick}
        onExpiryClean={onExpiryClean}
        onLocationClick={onLocationClick}
        onLocationClean={onLocationClean}
        onJobStatusClick={onJobStatusClick}
        onJobStatusClean={onJobStatusClean}
        onChangeJobStatus={onJobStatusListingClick}
      />
      <JobDrafts
        draftJobsCount={draftJobsCount}
        draftJobs={draftJobs}
        onChangeJobStatus={onJobStatusDraftClick}
      />
      <WorldConnect />
      {toggleModel && <ResumeModel modelHandler={modelHandler} />}
      {toggleModelC && (
        <CommentModel
          modelCHandler={modelCHandler}
          commentAdded={commentAdded}
          commentRemoved={commentRemoved}
        />
      )}
    </>
  );
};

export default EmpDashboard;
