import { Link } from "react-router-dom";
import "./Pagination.css";

const Pagination = ({
  startPage,
  currentPage,
  endPage,
  onCurrentPageHandler,
}) => {
  const prePageHandler = () => {
    if (currentPage > startPage) onCurrentPageHandler(currentPage - 1);
  };

  const nextPageHandler = () => {
    if (currentPage < endPage) onCurrentPageHandler(currentPage + 1);
  };

  return (
    <section className="pageniation-sec">
      <div className="container-site-job-match">
        <div className="pagination-job">
          <Link
            className="fill-box"
            href="#"
            onClick={() => onCurrentPageHandler(startPage)}
          >
            &laquo;
          </Link>
          <Link className="fill-box" href="#" onClick={() => prePageHandler()}>
            &lt;
          </Link>

          {[...Array(endPage)].map((_, i) => {
            return (
              <Link
                href="#"
                className={currentPage === i + 1 ? "active" : ""}
                onClick={() => onCurrentPageHandler(i + 1)}
              >
                {i + 1}
              </Link>
            );
          })}

          <Link className="fill-box" href="#" onClick={() => nextPageHandler()}>
            &gt;
          </Link>
          <Link
            className="fill-box"
            href="#"
            onClick={() => onCurrentPageHandler(endPage)}
          >
            &raquo;
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Pagination;
