import CustomRouter from "./routes";
import "./App.css";
import "./styles/common.css";
import "./styles/responsive.css";

function App() {
  return (
      <CustomRouter />    
  );
}

export default App;
