import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setSelectJobId } from "../../../redux/slices/JobDetailSlice";
import { setCandidateListId } from "../../../redux/slices/employerDashboardSlice";
import getDays from "../../../utils/getDays";
import "./JobRecord.css";

const JobListingRight = ({ selectedJobId, date }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onDetailJobHandler = () => {
    navigate(`/job-view-detail/${selectedJobId}`);
  };

  const onCandidateListHandler = () => {
    dispatch(setCandidateListId(selectedJobId));
  };

  return (
    <div class="job-announcements">
      <div class="job-announcements-col btn-size">
        <a onClick={() => onDetailJobHandler()}>
          <button>View detailed Job Position</button>
        </a>
        <div className="job-time-wishlist">
          <div className="job-eployee-table-col">
            <p className="day">
              {date ? getDays(date) + " Days ago" : "21 Days ago"}
            </p>
            <p className="job">Job will disappear in 12 Days</p>
          </div>
          <Link
            to="/employer-candidate-list"
            className="job-candidate-list-icon"
            onClick={() => onCandidateListHandler()}
          >
            <img src="/image/candidate-job-list.png" alt="apply" />
            <p>Candidates List</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default JobListingRight;
