import "./Footer.css";

const Footer = () => {
  return (
    <section className="footer-sec ">
      <div className="container container-site">
        <div className="space-inner-row ">
          <div className="footer-row ">
            <div className="footer-address ">
              <img src="/image/footer-logo.png " alt="footer-logo " />
              <p className="head-office ">HEAD OFFICE</p>
              <p className="office-address ">
                9131 Keele Street, Unit A4, Vaughan, ON L4K 0G7, Canada
              </p>
              <p className="office-mail ">
                <a href="mailto:info@workbridgeteam.ca ">
                  info@workbridgeteam.ca
                </a>
              </p>
            </div>
            <div className="footer-services footer-list ">
              <ul>
                <li className="service-heading ">Services</li>
                <li>Employer of Record</li>
                <li>Background and Reference Check</li>
                <li>Asset Management</li>
                <li>Recruitment Process Outsourcing</li>
                <li>Global Consulting</li>
                <li>Global Talent Hub</li>
              </ul>
            </div>
            <div className="footer-countries footer-list ">
              <ul>
                <li className="Countries-heading ">Countries</li>
                <li>North Macedonia</li>
                <li>Albania</li>
                <li>Bosnia</li>
                <li>Pakistan</li>
                <li>Brazil</li>
                <li>Canada</li>
                <li>USA</li>
              </ul>
            </div>
            <div className="footer-privacy footer-list ">
              <p className="privacy-list "></p>
              <ul>
                <li>Privacy Statement</li>
                <li>Terms and Conditions</li>
                <li>GDPR</li>
              </ul>
            </div>
          </div>
          <div className="footer-divider "></div>
          <div className="footer-rights ">
            <div className="footer-site-development ">
              <p>
                2023 © <strong>WORKBRIDGE.</strong> All rights reserved{" "}
              </p>
            </div>
            <div className="site-map ">
              <ul className="site-list ">
                <li>Privacy Policy</li>
                <li>Site Map</li>
                <li>Accessibility</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
