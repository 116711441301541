import { useNavigate, Link } from "react-router-dom";
import { useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import API from "../../../../api";
import * as Yup from "yup";
import {
  setActiveSubTab,
  setActiveTab,
  setEmailVerify,
  setFavoriteJobsCount,
  setLoginUser,
  setLoginUserName,
  setSignUpEmp,
} from "../../../../redux/slices/AuthSlice";
import "./LoginForm.css";

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Please enter valid email address"
        )
        .required("Please enter email"),
      password: Yup.string().required("Please enter password"),
    }),
    onSubmit: async (data, { resetForm }) => {
      const { email, password } = data;
      try {
        const formData = new FormData();
        formData.append("email", email.trim());
        formData.append("password", password);
        setLoader(true);

        await API.post(`/auth/login`, formData)
          .then((response) => {
            if (response?.status === 200 || response?.status === 201) {
              const res = response?.data;
              const user = response?.data?.user;

              if (res?.result === true) {
                dispatch(setLoginUser(res));
                dispatch(setLoginUserName(user?.first_name));
                dispatch(setEmailVerify(true));
                dispatch(setFavoriteJobsCount(res?.favorite_job_count));
                localStorage.setItem("token", res?.access_token);
                if (res?.user?.user_type === "candidate")
                  navigate("/candidate-dashboard");
                if (res?.user?.user_type === "employer")
                  navigate("/employer-dashboard");
              }
              setLoader(false);
            }
          })
          .catch((error) => {
            if (error) {
              const { response } = error;
              const { data } = response;
              setErrorMsg(data?.message?.error);
              setTimeout(() => {
                setErrorMsg("");
              }, 5000);
              console.log(error);
            }
            setLoader(false);
          });
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const onSignUpHandler = () => {
    dispatch(setActiveTab("candidate"));
    dispatch(setActiveSubTab("signUp"));
    dispatch(setSignUpEmp({}));
  };

  return (
    <>
      <section className="candiate-login">
        <div className="container container-site">
          <div className="candidate-login-row">
            <div className="candidate-profile login-f-img">
              <img src="/image/candidate-login.png" alt="candiate-login" />
            </div>
            <div className="candiate-login-form">
              <div className="candidate-signin">
                <h2>Sign In</h2>
                <form onSubmit={formik.handleSubmit}>
                  <div
                    className={`candidate-username ${
                      isFormFieldValid("email") && "error-input"
                    }`}
                  >
                    <input
                      className="form-font-f"
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {getFormErrorMessage("email")}
                  </div>

                  <div className="candidate-password">
                    <div
                      className={`signup-passowrd  ${
                        isFormFieldValid("password") && "error-input"
                      }`}
                    >
                      <div className="password-icon bg-pass-f">
                        <input
                          className="form-font-f"
                          id="password-field"
                          type={passwordShown ? "text" : "password"}
                          placeholder="Password"
                          name="password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <div className="icon-hide">
                          {isFormFieldValid("password") ? (
                            <img src="/image/warning.png" alt="" />
                          ) : (
                            <>
                              <img
                                src="/image/hide-password.png"
                                alt=""
                                className={
                                  passwordShown && "password-icon-togle"
                                }
                                onClick={() => setPasswordShown(true)}
                              />
                              <img
                                src="/image/password.png"
                                alt=""
                                className={
                                  !passwordShown && "password-icon-togle"
                                }
                                onClick={() => setPasswordShown(false)}
                              />
                            </>
                          )}
                        </div>
                      </div>
                      {getFormErrorMessage("password")}
                    </div>

                    <div className="remember-forget-password">
                      <input
                        id="rememberme"
                        name="rememberme"
                        value="remember"
                        type="checkbox"
                      />{" "}
                      &nbsp;Remember me
                      <Link to="/forgot-password">
                        <p className="forget-password">Forgot password?</p>
                      </Link>
                    </div>
                    <div className="heading-below-p">
                      <p className="heading-below-text">{errorMsg}</p>
                    </div>
                    <div className="candidate-from-submit">
                      <button type="submit" className="form-submit">
                        {loader ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Sign in"
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="signup-here">
                    <p>
                      Don’t have an account?{" "}
                      <Link to="/signup" onClick={() => onSignUpHandler()}>
                        <span>Sign up</span>
                      </Link>
                    </p>
                  </div>
                  {/* <div className="or-divider">
                    <div className="divider-one"></div>
                    <div className="text-or">Or</div>
                    <div className="divider-one"></div>
                  </div>
                  <div className="candidate-login-google">
                    <Link href="#">
                      <img src="/image/goole.png" alt="goole" />
                      <p>Continue with Google</p>
                    </Link>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginForm;
