import { useState, useEffect } from "react";
import JobDetailSec from "./JobDetailSec";
import JobDetailRightSec from "./JobDetailRightSec";


const JobDescription = ({ jobDetail }) => {
  let jobDesDetail = Object.assign({}, jobDetail[0]);

  const [recruiterNotes, setRecruiterNotes] = useState([""]);
  const [mustSkills, setMustSkills] = useState([""]);
  const [niceToHave, setNiceToHave] = useState([""]);
  const [interviewProcess, setInterviewProcess] = useState([""]);

  const tranferData = (data) => {
    if (!data) {
      return [];
    }
    try {
      const currentArray = JSON?.parse(data);
      const convertArray = currentArray?.map((item) => {
        return item;
      });
      return convertArray;
    } catch (error) {
      console.error("Error parsing responsibilities:", error);
      return [];
    }
  };

  useEffect(() => {
    let rec = tranferData(jobDetail[0]?.recruiter_notes);
    let skill = tranferData(jobDetail[0]?.must_skills);
    let nice = tranferData(jobDetail[0]?.nice_to_have);
    let interview = tranferData(jobDetail[0]?.interview_process);

    setRecruiterNotes(rec);
    setMustSkills(skill);
    setNiceToHave(nice);
    setInterviewProcess(interview);
  }, [jobDetail]);

  return (
    <>
      {" "}
      <section className="job-description-sec">
        <div className="container container-site">
          <div className="space-inner-row">
            <div className="Job-Opportunities-row">
              <div className="Job-Opportunities-row-company">
                <div className="job-company-details">
                  {jobDesDetail?.description !== "" && (
                    <div className="job-heading-detail-sec">
                      <h5>Job Summary</h5>
                      <p className="job-discription-com-j">
                        {jobDesDetail?.description}
                      </p>
                    </div>
                  )}

                  {jobDesDetail?.responsibilities?.length > 0 && (
                    <JobDetailSec
                      label="Responsibilities"
                      description={jobDesDetail?.responsibilities}
                    />
                  )}

                  {jobDesDetail?.skills?.length > 0 && (
                    <JobDetailSec
                      label="Skills"
                      description={jobDesDetail?.skills}
                    />
                  )}

                  {jobDesDetail?.benefits?.length > 0 && (
                    <JobDetailSec
                      label="Benefits"
                      description={jobDesDetail?.benefits}
                    />
                  )}

                  {jobDesDetail?.working_hrs?.length > 0 && (
                    <JobDetailSec
                      label="Working Hours"
                      description={jobDesDetail?.working_hrs}
                    />
                  )}

                  {recruiterNotes[0] !== "" && recruiterNotes?.length > 0 && (
                    <div className="recruite-note-box">
                      <h5>Recruiter Notes</h5>
                      <p className="job-discription-com-j-recruite">
                        {" "}
                        {recruiterNotes?.map((i) => (
                          <>{`${i}`}</>
                        ))}
                      </p>
                    </div>
                  )}
                </div>
                <div className="company-job-announcements">
                  <div className="job-announcements-col">
                    <div className="job-company-right">
                      {jobDesDetail?.jobCities?.length > 0 && (
                        <div className="job-location-title">
                          <p>Job Location </p>
                          <ul className="job-location-list">
                            {jobDesDetail?.jobCities.map((item, i) => (
                              <li>{item?.name}</li>
                            ))}
                          </ul>
                          <div className="company-right-col"></div>
                        </div>
                      )}
                      {jobDesDetail?.industry?.length > 0 && (
                        <>
                          <JobDetailRightSec
                            label="Industry"
                            value={jobDesDetail?.industry}
                          />
                          <div className="company-right-col"></div>
                        </>
                      )}
                      {jobDesDetail?.reportingPerson?.length > 0 && (
                        <>
                          {" "}
                          <JobDetailRightSec
                            label="Reporting To"
                            value={jobDesDetail?.reportingPerson}
                          />
                          <div className="company-right-col"></div>
                        </>
                      )}

                      {jobDesDetail?.workMethodology?.length > 0 && (
                        <JobDetailRightSec
                          label="Work Methodology"
                          value={jobDesDetail?.workMethodology}
                        />
                      )}

                      {mustSkills[0] !== "" && mustSkills?.length > 0 && (
                        <>
                          <div className="company-right-col"></div>
                          <div className="j-com-dev-info">
                            <p className="company-j-info-l">Must Have Skills</p>
                            <ul className="skill-list-j">
                              {mustSkills?.map((i) => (
                                <li>{`${i}`}</li>
                              ))}
                            </ul>
                          </div>
                        </>
                      )}

                      {jobDesDetail?.tags?.length > 0 && (
                        <>
                          <div className="company-right-col"></div>
                          <div className="job-skills-detail-col">
                            {jobDesDetail?.tags.map((i) => (
                              <button>{i?.name?.trim()}</button>
                            ))}
                          </div>
                        </>
                      )}

                      {niceToHave[0] !== "" && niceToHave?.length > 0 && (
                        <>
                          <div className="company-right-col"></div>
                          <div className="j-com-dev-info">
                            <p className="company-j-info-l">Nice To Have</p>
                            <ul className="skill-list-j">
                              {niceToHave?.map((i) => (
                                <li>{`${i}`}</li>
                              ))}
                            </ul>
                          </div>
                        </>
                      )}

                      {interviewProcess[0] !== "" &&
                        interviewProcess?.length > 0 && (
                          <>
                            <div className="company-right-col"></div>
                            <div className="j-com-dev-info">
                              <p className="company-j-info-l">
                                Interview Process
                              </p>
                              <ul className="skill-list-j">
                                {interviewProcess?.map((i) => (
                                  <li>{`${i?.trim()}`}</li>
                                ))}
                              </ul>
                            </div>
                          </>
                        )}

                      <div className="company-right-col"></div>
                      <div className="j-com-dev-info qr-code-img">
                        <p className="company-j-info-l ">Share This Job</p>
                        <img src="/image/QR.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JobDescription;
