import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import API from "../../../../api";
import "./ForgotForm.css";

const ForgotForm = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Please enter valid email address"
        )
        .required("Please enter email"),
    }),
    onSubmit: async (data, { resetForm }) => {
      const { email } = data;
      try {
        let params = `?`;
        if (email !== "") {
          params += `email=${email}`;
        }
        setLoader(true);
        await API.get(`/auth/send-password-reset-mail${params}`)
          .then((response) => {
            if (response?.status === 200 || response?.status === 201) {
              const res = response?.data;
              if (res?.result === true) {
                setErrorMsg(res?.message);
                setTimeout(() => navigate('/'), 2000);
              }
              setLoader(false);
            }
          })
          .catch((error) => {
            if (error) {
              const { response } = error;
              const { data } = response;
              setErrorMsg(data?.message);
              setTimeout(() => {
                setErrorMsg("");
              }, 5000);
              console.log(error);
            }
            setLoader(false);
          });
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  return (
    <>
      <section className="candiate-login">
        <div className="container container-site">
          <div className="candidate-login-row-password">
            <div className="candidate-profile candidate-profile-login-f">
              <img src="/image/passwordforget.png" alt="password" />
            </div>
            <div className="candiate-login-form">
              <div className="candidate-signin-password-ft">
                <h2>Forgot Password</h2>{" "}
                <form onSubmit={formik.handleSubmit}>
                  <div
                    className={`candidate-email f-feild-forgot ${
                      isFormFieldValid("email") && "error-input"
                    }`}
                  >
                    <input
                      type="text"
                      placeholder="E-Mail"
                      name="email"
                      id="forgotpassowrd-mail"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {getFormErrorMessage("email")}
                  </div>
                  <div className="heading-below-p">
                    <p className="heading-below-text">{errorMsg}</p>
                  </div>
                  <div className="candidate-from-submit">
                    <button type="submit" className="password-btn">
                      {loader ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotForm;
