import OutsideClickHandler from "react-outside-click-handler";
import "./CommentModel.css";
import { useEffect, useState, Fragment, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addJobCommentAsync,
  fetchJobCommentsAsync,
  removeJobCommentAsync,
  setComments,
} from "../../../redux/slices/commentModalSlice";
import {
  formatCurrDate,
  formatRelativeDate,
  formatTimestamp,
} from "../../../utils/formatDate";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { current } from "@reduxjs/toolkit";

const CommentModel = ({ modelCHandler, commentAdded, commentRemoved }) => {
  const dispatch = useDispatch();
  const { comments, jobId, candidateId } = useSelector(
    (state) => state.comments
  );
  const { user } = useSelector((state) => state.auth);

  const [commentss, setComments] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [toggleEmojiPicker, setToggleEmojiPicker] = useState(false);
  const [loading, setLoading] = useState(true);

  // Create a ref for the modal container
  const modalRef = useRef(null);

  useEffect(() => {
    // Scroll the modal to the bottom when it opens
    fetchComments();
  }, []);

  const scrollToBottom = () => {
    if (modalRef.current) {
      modalRef.current.scrollTop = modalRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [commentss, toggleEmojiPicker]);

  const removeCommentClickHandler = (commentId) => {
    if (
      commentId === undefined ||
      commentId === null ||
      commentId === "" ||
      commentId <= 0
    )
      return;

    dispatch(
      removeJobCommentAsync({
        commentId: commentId,
        candidateId: candidateId,
        jobId: jobId,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          setComments(res?.data);
          commentRemoved(
            commentId,
            res?.lastComment[res?.lastComment?.length - 1] ?? ""
          );

          // Use the map method to update the data structure
          /*const updatedComments = commentss?.map((item) => {
            // Check if the 'comments' property exists and is an array
            if (Array.isArray(item?.comments)) {
              // Use filter to remove the comment with the specified ID
              item.comments = item?.comments?.filter(
                (comment) => comment.id !== commentId,
              );
            }
            return item;
          });*/

          // setComments(updatedComments);
          // const lastArray = commentss[commentss?.length - 1]?.comments;
          // const lastComment = lastArray[lastArray?.length - 1];
          // commentRemoved(commentId, lastComment ?? "");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const toggleEmojiPickerHandler = () => {
    setToggleEmojiPicker((current) => !current);
    scrollToBottom();
  };

  const addEmojiToInput = (e) => {
    setCommentText((current) => current + e.native);
  };

  const handleCommentBoxChange = (e) => {
    const value = e.target.value;

    setCommentText(value);
    if (value === null || value === undefined || value === "") {
      setDisableSubmit(true);
      return;
    }
    setDisableSubmit(false);
  };

  const clearCommentBox = () => setCommentText("");

  const handleSubmit = () => {
    if (jobId === null || jobId === undefined || jobId === "") return;
    if (candidateId === null || candidateId === undefined || candidateId === "")
      return;
    if (user?.id === null || user?.id === undefined || user?.id === "") return;
    if (commentText === null || commentText === undefined || commentText === "")
      return;

    dispatch(
      addJobCommentAsync({
        jobId: jobId,
        candidateId: candidateId,
        userId: user?.id,
        commentText: commentText,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          setComments(res?.data);
          const data = res?.data;
          const lastArray = data[data?.length - 1]?.comments;
          const lastComment = lastArray[lastArray?.length - 1];
          commentAdded(candidateId, jobId, lastComment);
          scrollToBottom();
        }
      })
      .catch((error) => {
        console.log(error);
      });

    clearCommentBox();
  };

  const fetchComments = async () => {
    if (
      jobId === null ||
      jobId === undefined ||
      jobId === "" ||
      candidateId === null ||
      candidateId === undefined ||
      candidateId === ""
    ) {
      return;
    }
    dispatch(fetchJobCommentsAsync({ jobId, candidateId }))
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {
          setComments(res?.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (loading === true) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="click-model-close">
      <section className="model-comment-popup">
        <div className="popup-bg">
          <OutsideClickHandler
            onOutsideClick={() => {
              modelCHandler(false);
            }}
          >
            <div className="popup-candidate-resume">
              <div
                className="close-img-resume"
                onClick={() => modelCHandler(false)}
              >
                <img src="/image/profile-cross.jpg" alt="" />
              </div>
              <div className="model-logo">
                <img src="/image/WORKBRIDGE.png" alt="" />
              </div>
              <div className="chat-model-comment-box" ref={modalRef}>
                <div className="comment-model-title">
                  <div className="resume-heading">
                    <h1>Comments</h1>
                  </div>
                </div>
                {commentss?.map((value, index) => (
                  <Fragment key={index}>
                    <div className="border-text">
                      <fieldset>
                        <legend>{formatRelativeDate(value.day)}</legend>
                      </fieldset>
                    </div>
                    {value?.comments?.map((comment, index) => (
                      <Fragment key={index}>
                        {comment?.user_id === user?.id ? (
                          <>
                            <div className="comment-chat-model-right info-border">
                              <img
                                src={
                                  comment?.avatar_link ??
                                  "/image/right-chatbot.png"
                                }
                                alt=""
                              />
                              <div className="chat-comment-box-right">
                                <div className="comment-add-box">
                                  <div className="comment-owner-details">
                                    <p className="name-comment-owner">
                                      {comment?.first_name +
                                        " " +
                                        comment?.last_name}
                                    </p>
                                    <p className="comment-add-date">
                                      {/*08 : 55 PM{" "}*/}
                                      {formatTimestamp(comment?.created_at)}
                                    </p>
                                    <p
                                      className="comment-remove"
                                      onClick={() =>
                                        removeCommentClickHandler(comment?.id)
                                      }
                                    >
                                      Remove
                                    </p>
                                  </div>
                                  <div className="edit-icon-model">
                                    <img
                                      src="/image/chat-model-edit.png"
                                      alt="test"
                                    />
                                  </div>
                                </div>
                                <div className="chat-info-box">
                                  <p>{comment?.comments}</p>
                                </div>
                                <div className="chat-reply">
                                  <p>Reply</p>
                                  <img src="/image/comment-reply.png" alt="" />
                                </div>
                              </div>
                            </div>
                            {/* <div className="comment-chat-model-right">
                              <img src="/image/right-chatbot.png" alt="" />
                              <div className="chat-comment-box-right">
                                <div className="comment-add-box">
                                  <div className="comment-owner-details">
                                    <p className="name-comment-owner">
                                      James Michael
                                    </p>
                                    <p className="comment-add-date">
                                      1 day ago
                                    </p>
                                    <p className="comment-remove">Remove</p>
                                  </div>
                                  <div className="two-icon-chat">
                                    <img src="/image/chat-right.png" alt="" />
                                    <img src="/image/chat-cut.png" alt="" />
                                  </div>
                                </div>
                                <div className="chat-info-box">
                                  <p>
                                    This candidate has a very strong background
                                    and a strong 5 years of experience I
                                    recommend him for the next round of
                                    interview.{" "}
                                  </p>
                                </div>
                              </div>
                            </div> */}

                            {/* <div className="comment-chat-model-user-reply">
                              <div className="chat-comment-box-right-udser-reply">
                                <div className="comment-add-box-left">
                                  <img
                                    src="/image/chat-model-edit.png"
                                    alt=""
                                  />
                                </div>
                                <div className="chat-info-box-left-bg-clr-chg">
                                  <div className="name-comment-owner-left">
                                    James Michael
                                  </div>
                                  <p>
                                    This candidate has a very strong background
                                    and a strong 5 years of experience I
                                    recommend him for the next round of
                                    interview.{" "}
                                  </p>
                                </div>
                                <div className="chat-info-box-bg-clr">
                                  <div className="comment-owner-details">
                                    <p className="name-comment-owner">
                                      James Michael
                                    </p>
                                    <p className="comment-add-date">
                                      1 day ago
                                    </p>
                                    <p className="comment-remove">Remove</p>
                                  </div>
                                  <p>Noted!</p>
                                </div>
                              </div>
                            </div> */}
                          </>
                        ) : (
                          <div className="comment-chat-model">
                            <img
                              src={
                                comment?.avatar_link ?? "/image/chat-model.png"
                              }
                              alt=""
                            />
                            <div className="chat-comment-box">
                              <div className="comment-add-box">
                                <div className="comment-owner-details">
                                  <p className="name-comment-owner">
                                    {comment?.first_name +
                                      " " +
                                      comment?.last_name}
                                  </p>
                                  <p className="comment-add-date">
                                    {formatTimestamp(comment?.created_at)}
                                  </p>
                                  {/*<p className="comment-remove">Remove</p>*/}
                                </div>
                              </div>
                              <div className="chat-info-box">
                                <p>{comment?.comments}</p>
                              </div>
                              <div className="chat-reply">
                                <p>Reply</p>
                                <img src="/image/comment-reply.png" alt="" />
                              </div>
                            </div>
                          </div>
                        )}
                      </Fragment>
                    ))}
                  </Fragment>
                ))}
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  {toggleEmojiPicker === true ? (
                    <Picker
                      data={data}
                      theme="light"
                      onEmojiSelect={(e) => addEmojiToInput(e)}
                    />
                  ) : null}
                </div>
{/* 
                <div className="comment-chat-model-end-user">
                  <div className="chat-comment-box-left-bg-chng">
                    <div className="comment-add-box-left">
                      <img src="/image/chat-cut.png" alt="" />
                    </div>
                    <div className="chat-info-box-left">
                      <div className="name-comment-owner-left">
                        James Michael
                      </div>
                      <p>
                        This candidate has a very strong background and a strong
                        5 years of experience I recommend him for the next round
                        of interview.{" "}
                      </p>
                    </div>
                  </div>
                </div> */}

                <div className="chat-comment-f">
                  <div className="chat-form">
                    <input
                      type="text"
                      name=""
                      id="comment-f"
                      placeholder="Type here..."
                      value={commentText}
                      onChange={handleCommentBoxChange}
                    />
                    <img
                      src="/image/ssmile.png"
                      alt=""
                      onClick={() => toggleEmojiPickerHandler()}
                    />
                  </div>
                  <div className="send-comment">
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      disabled={disableSubmit}
                    >
                      <img src="/image/send-chat.png" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      </section>
    </div>
  );
};

export default CommentModel;
