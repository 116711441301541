import axios from "axios";
import { BASE_API } from "./config";

// export default axios.create({
//   baseURL: BASE_API
// });

const API = axios.create();

API.interceptors.request.use(
  async (config) => {
    try {
      const response = await axios.get(
        "https://workbridge.unitedsoftlabs.com/base_url.php"
      );
      if (response?.status === 200) {
        const { base_url } = response.data;

        config.baseURL = base_url + "/public/api/v1";
      } else {
        config.baseURL = BASE_API;
      }
    } catch (error) {
      // Handle errors
      console.error("Error fetching base URL:", error);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default API;
