import { useNavigate, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  depStatusDropdownAsync,
  setActiveSideBar,
  setSelectedDep,
} from "../../../../redux/slices/AdminDashboardSlice";
import * as Yup from "yup";
import APIPvt from "../../../../apiPvt";
import FormError from "../../../Common/Admin/FormError/FormError";

const AdminCreateRole = () => {
  const dispatch = useDispatch();
  const { selectedDep } = useSelector((state) => state.adminDashboard);

  const [loader, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [statusDropDown, setStatusDropDown] = useState([]);
  const [depDropDown, setDepDropDown] = useState([]);

  const formik = useFormik({
    initialValues: {
      role: "",
      dep: "",
      depId: null,
      status: "",
      statusId: null,
      description: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      role: Yup.string().required("Please enter role"),
      dep: Yup.string().required("Please enter department"),
    }),
    onSubmit: async (data, { resetForm }) => {
      onCreateRole(data);
    },
  });

  const onDepClick = async (id, name) => {
    await formik.setFieldValue("depId", id);
    await formik.setFieldValue("dep", name);
  };

  const onStatusClick = async (label, value) => {
    await formik.setFieldValue("status", label);
    await formik.setFieldValue("statusId", value);
  };

  const onCancelClick = () => {
    dispatch(
      setActiveSideBar({
        id: "2",
        name: "Department",
        value: "Create Department",
      })
    );
  };

  const onCreateRole = async (data) => {
    try {
      const { role, depId, statusId, description } = data;
      const formData = new FormData();
      formData.append("department_id", depId);
      formData.append("title", role?.trim());
      statusId !== null && formData.append("status", statusId);
      description !== "" && formData.append("description", description);

      setLoader(true);
      await APIPvt.post(`/role`, formData)
        .then((response) => {
          if (response?.status === 200 || response?.status === 201) {
            const res = response?.data;

            if (res?.success === true) {
              dispatch(setSelectedDep({ id: null, name: "" }));
              dispatch(
                setActiveSideBar({
                  id: "2",
                  name: "Users",
                  value: "Create User",
                })
              );
            } else {
              setErrorMsg(res?.message);
              setTimeout(() => {
                setErrorMsg("");
              }, 5000);
            }
            setLoader(false);
          }
        })
        .catch((error) => {
          if (error) {
            const { response } = error;
            const { data } = response;
            setErrorMsg(data?.message);
            setTimeout(() => {
              setErrorMsg("");
            }, 5000);
            console.log(error);
          }
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const depStatusDropdownHandler = () => {
    dispatch(depStatusDropdownAsync())
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {
          setDepDropDown(res?.data?.departments);
          setStatusDropDown(res?.data?.status);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  useEffect(() => {
    depStatusDropdownHandler();
    async function getSelectedDep() {
      await formik.setFieldValue("depId", selectedDep?.id);
      await formik.setFieldValue("dep", selectedDep?.name);
    }
    getSelectedDep();
  }, []);

  return (
    <>
      <div className="form-heading">
        <h2>Create Roles</h2>
        {/* <button className="add-role-admin">Add Role +</button> */}
      </div>
      <div className="department-form-box">
        <form onSubmit={formik.handleSubmit}>
          <div className="admin-from-step space-b-f">
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("dep") && "error-input-admin"
              }`}
            >
              <label for="">Department *</label>
              <div className={`admin-status-filter-form`}>
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${
                        formik?.values?.dep !== "" && "text-filter-select"
                      }`}
                    >
                      {" "}
                      {formik?.values?.dep === ""
                        ? "Department"
                        : formik?.values?.dep}
                    </p>

                    {isFormFieldValid("dep") ? (
                      <img src="/image/warning.png" alt="" />
                    ) : (
                      <img src="/image/admin-d.png" alt="" />
                    )}
                  </div>
                </div>
                {depDropDown?.length > 0 && (
                  <div className="admin-status-dropdown-frm">
                    <ul className="list-job-title-company">
                      {depDropDown?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onDepClick(item?.id, item?.name);
                          }}
                        >
                          {item?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {getFormErrorMessage("dep")}
            </div>
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("role") && "error-input-admin"
              }`}
            >
              <label for="">Role Name *</label>
              <br />
              <input
                type="text"
                name="role"
                id="dept-f"
                placeholder="Role Name"
                className="form-font-admin"
                value={formik.values.role}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("role")}
            </div>

            <div className={`f-admin feild-f-admin`}>
              <label for="">Status</label>
              <div className={`admin-status-filter-form`}>
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${
                        formik?.values?.status !== "" && "text-filter-select"
                      }`}
                    >
                      {" "}
                      {formik?.values?.status === ""
                        ? "Status"
                        : formik?.values?.status}
                    </p>
                    <img src="/image/admin-d.png" alt="" />
                  </div>
                </div>

                {statusDropDown?.length > 0 && (
                  <div className="admin-status-dropdown-frm">
                    <ul className="list-job-title-company">
                      {statusDropDown?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onStatusClick(item?.label, item?.value);
                          }}
                        >
                          {item?.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="f-admin feild-f-text-are">
            <label for="">Role Description</label>
            <br />
            <textarea
              name="description"
              id="text-admin"
              cols="30"
              rows="5"
              placeholder="Type here"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="admin-form-btn">
            {" "}
            <button className="cancel-admin" onClick={() => onCancelClick()}>
              Cancel
            </button>{" "}
            <button className="next-admin" type="submit">
              {loader ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Next"
              )}
            </button>
          </div>
        </form>
        <FormError errorMsg={errorMsg} />
      </div>
    </>
  );
};

export default AdminCreateRole;
