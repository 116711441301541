import React, { useState } from "react";
import { useSelector } from "react-redux";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";

const JobCoreQuestion = ({ label, data }) => {
  const { isEmailVerify, user } = useSelector((state) => state.auth);
  const [mainCollapseOpen, setMainCollapseOpen] = useState(false);
  const [questionCollapseStates, setQuestionCollapseStates] = useState(
    Array(data.length).fill(true)
  );

  const toggleMainCollapse = () => {
    setMainCollapseOpen(!mainCollapseOpen);
  };

  const toggleQuestionCollapse = (sectionIndex) => {
    const updatedStates = [...questionCollapseStates];
    updatedStates[sectionIndex] = !updatedStates[sectionIndex];
    setQuestionCollapseStates(updatedStates);
  };

  if (data?.length === 0) return null;

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingThree">
        <button
          className={`accordion-button q-tabs ${
            mainCollapseOpen ? "" : "collapsed"
          }`}
          type="button"
          onClick={() => toggleMainCollapse()}
        >
          {label}
        </button>
      </h2>
      <div
        id="collapseThree"
        className={`accordion-collapse collapse ${
          mainCollapseOpen ? "show" : ""
        }`}
        aria-labelledby="headingThree"
        data-bs-parent="#accordionExample"
      >
        {data?.map((item, sectionIndex) => (
          <div className="accordion-body" key={sectionIndex}>
            <div
              className="accordion"
              id={`sub-accordionExample2-${sectionIndex}`}
            >
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id={`sub-headingOne-${sectionIndex}`}
                >
                  <button
                    className={`accordion-button bg-clr-q collapsed `}
                    type="button"
                    onClick={() => toggleQuestionCollapse(sectionIndex)}
                  >
                    {capitalizeFirstLetter(item?.name)}
                  </button>
                </h2>
                {item?.questions?.map((item2, questionIndex) => (
                  <div
                    id={`sub-collapseOne-${sectionIndex}-${questionIndex}`}
                    className={`accordion-collapse collapse ${
                      questionCollapseStates[sectionIndex] ? "" : "show"
                    }`}
                    aria-labelledby={`sub-headingOne-${sectionIndex}`}
                    data-bs-parent={`#sub-accordionExample2-${sectionIndex}`}
                    key={questionIndex}
                  >
                    <div className="accordion-body side-space">
                      <h2
                        className="accordion-header sub-header tab-space-tb"
                        id={`sub-headingOne-${sectionIndex}-${questionIndex}`}
                      >
                        <button
                          className="accordion-button sub-heading"
                          type="button"
                        //  onClick={() => toggleQuestionCollapse(sectionIndex)}
                        >
                          Question {questionIndex + 1}:
                          <span className="q-text-s">{item2?.question}</span>
                        </button>
                      </h2>
                      {isEmailVerify && user?.user_type === "employer" && (
                        <h2
                          className="accordion-header sub-header tab-space-tb"
                          id={`sub-headingOne-${sectionIndex}-${questionIndex}`}
                        >
                          <button
                            className="accordion-button queation-answer-tab"
                            type="button"
                      //      onClick={() => toggleQuestionCollapse(sectionIndex)}
                          >
                            Answer:
                            <span className="q-text-ans">{item2?.answer}</span>
                          </button>
                        </h2>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobCoreQuestion;
