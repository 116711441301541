import "./MostSearchJob.css";

const MostSearchJob = ({ onMostSearchjobClick, mostSearchedJobs }) => {
  if (mostSearchedJobs?.length === 0) return null;

  return (
    <>
      {" "}
      <section className="job-search-sec">
        <div className="container container-site">
          <div className="job-search-tag-heading">
            <h2>Most Search Jobs</h2>
          </div>
          <div className="most-job-search-tag">
            <ul className="find-job-tag">
              {mostSearchedJobs?.length > 0 &&
                mostSearchedJobs.map((tag) => (
                  <li
                    className="icon-cursor"
                    onClick={() => onMostSearchjobClick(tag?.query)}
                  >
                    {tag?.query}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default MostSearchJob;
