const PermissionForm = ({
  activePerTab,
  selectPermission,
  selectedSubModules,
  selectedSubModulesSlug,
  onActivePerTabClick,
  onSlugClick,
  onAddAllSlugClick,
  onRemoveAllSlugClick
}) => {
  return (
    <div className="user-permission-form-box">
      <div className="user-heading-filter">
        <div className="first-part-f">
          <h3>Permissions</h3>
          <p>User Permissions details</p>
        </div>
        <div className="select-filter">
          <label for="">Permission:</label>
          <div className="admin-status-filter-form">
            <div
              id="dropdown-boxId2"
              className="company-form-dropdownBox1"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div className="text-filter-admin">
                <p>Select</p>
                <img src="/image/admin-d.png" alt="" />
              </div>
            </div>
            <div className="admin-status-dropdown-frm">
              <ul className="list-admin-portal">
                <li onClick={() => onAddAllSlugClick()}>Add All</li>
                <li onClick={() => onRemoveAllSlugClick()}>Remove All</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="table-user-permission">
        {/* <!-- Tabs navs --> */}
        <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">
          {selectPermission?.length > 0 &&
            selectPermission?.map((item, index) => (
              <li
                className="nav nav-tabs nav-fill"
                role="presentation"
                onClick={() => onActivePerTabClick(item)}
              >
                <a
                  className={`nav-link ${
                    item?.name === activePerTab && "active"
                  }`}
                  id="ex1-tab-1"
                  data-mdb-toggle="tab"
                  href="#ex1-tabs-1"
                  role="tab"
                  aria-controls="ex1-tabs-1"
                  aria-selected="true"
                >
                  {item?.name}
                </a>
              </li>
            ))}
        </ul>
        <div className="tab-content" id="ex1-content">
          <div
            className="tab-pane fade show active"
            id="ex1-tabs-1"
            role="tabpanel"
            aria-labelledby="ex1-tab-1"
          >
            <div className="table-responsive">
              <table className="depart-table-left">
                <thead className="tb-heading-user-admin">
                  <tr>
                    <th scope="col">
                      <div className="th-admin-heading">Sub Modules</div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">View Permission</div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">Create Permission</div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">Edit Permission</div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">Delete Permission</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="tb-user-admin">
                  {selectedSubModules?.length > 0 &&
                    selectedSubModules?.map((item, index) => (
                      <tr>
                        <td className="deprt-left-td">
                          <div className="name-sr-circle">{item?.name}</div>
                        </td>
                        {item?.permissions?.length > 0 &&
                          item?.permissions?.map((j, index) => (
                            <td>
                              <div className="tik-crl">
                                <img
                                className="icon-cursor"
                                  src={
                                    (
                                    !selectedSubModulesSlug.includes(j?. name))
                                      ? "/image/clr-g-bf.png" 
                                      : "/image/clr-b-bg.png"
                                  }
                                  alt=""
                                  onClick={() => onSlugClick(j?.name)}
                                />
                              </div>
                            </td>
                          ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="ex1-tabs-2"
            role="tabpanel"
            aria-labelledby="ex1-tab-2"
          >
            Tab 2 content
          </div>
          <div
            className="tab-pane fade"
            id="ex1-tabs-3"
            role="tabpanel"
            aria-labelledby="ex1-tab-3"
          >
            Tab 3 content
          </div>
        </div>
      </div>
    </div>
  );
};

export default PermissionForm;
