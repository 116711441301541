import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CanProfileForm from "../../../components/Candidate/Profile/CanProfileForm/CanProfileForm";
import CanProfileView from "../../../components/Candidate/Profile/CanProfileView/CanProfileView";
import ResumeModel from "../../../components/Common/ResumeModel/ResumeModel";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";
import API from "../../../api";
import "./index.css";
import { setResumeUrl } from "../../../redux/slices/candidateDashboardSlice";

const CanProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [editable, setEditable] = useState(false);
  const [toggleModel, setToggleModel] = useState(false);
  const [jobTitles, setTobTitles] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedImageName, setSelectedImageName] = useState("");

  const [baseInfo, setBaseInfo] = useState({
    first_name: "",
    last_name: "",
    email: "",
    countryId: null,
    phone: "",
    linkedin: null,
    file: null,
    avatar: null,
    bio: null,
    my_interests: null,
    intro_video_link: null,
  });

  const [city, setCity] = useState({
    cityName: "",
    cityId: null,
  });

  const modelHandler = (v) => setToggleModel(v);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImageName(file.name);
        setBaseInfo({...baseInfo, file: file});     
        onUpdateResume(file); 
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedImageName("");
      setBaseInfo({...baseInfo, file: null});      
    }
  };

  const editHandler = (editValue) => {
    setEditable(editValue);
  };

  const changePasswordHandler = () => {
    navigate("/forgot-password");
  };

  const submitHandler = async (data, selectJobTitlesId, selectSkillTagsId) => {
    const {
      firstName,
      lastName,
      cityId,
      phoneNo,
      linkedinURL,
      aboutSelf,
      interested,
      files,
      introVideo,
    } = data;

    try {
      const formData = new FormData();
      formData.append("id", user?.id);
      formData.append("first_name", firstName);
      formData.append("last_name", lastName);
      formData.append("phone", phoneNo);
      formData.append("city_id", cityId);
      formData.append("linkedin", linkedinURL);
      aboutSelf !== "" && formData.append("bio", aboutSelf);
      interested !== "" && formData.append("my_interests", interested);
      files !== null && files !== undefined && formData.append("avatar", files);
      introVideo !== null &&
        introVideo !== undefined &&
        formData.append("intro_video", introVideo);

      selectJobTitlesId !== null &&
        Object.keys(selectJobTitlesId).forEach((key) => {
          formData.append("job_title[]", selectJobTitlesId[key]);
        });

      selectSkillTagsId !== null &&
        Object.keys(selectSkillTagsId).forEach((key) => {
          formData.append("tech_tags[]", selectSkillTagsId[key]);
        });

      setLoader(true);

      await API.post("/auth/update-candidate-profile", formData)
        .then((response) => {
          if (response?.status === 200 || response?.status === 201) {
            const res = response?.data;
            if (res?.result === true) {
              getUserProfile();
              setEditable(false);
            }
            setErrorMsg(res?.message?.success);
            setLoader(false);
          }
        })
        .catch((error) => {
          if (error) {
            const { response } = error;
            const { data } = response;
            setErrorMsg(data?.message);
            setTimeout(() => {
              setErrorMsg("");
            }, 5000);
            console.log(error);
          }
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const onUpdateResume = async (file) => {
    if (user?.id) {
      try {
        const formData = new FormData();
        formData.append("id", user?.id);
        formData.append("resume", file);

        await API.post(`/auth/update-resume`, formData)
          .then((response) => {
            if (response?.status === 200 || response?.status === 201) {
              const result = response?.data?.result;
              if (result === true) {
                getUserProfile();
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onSubmitAboutSelf = async (value) => {
    if (user?.id) {
      try {
        const formData = new FormData();
        formData.append("id", user?.id);
        formData.append("bio", value);

        await API.post(`/auth/update-about-me`, formData)
          .then((response) => {
            if (response?.status === 200 || response?.status === 201) {
              const result = response?.data?.result;
              if (result === true) {
                getUserProfile();
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onSubmitInterested = async (value) => {
    if (user?.id) {
      try {
        const formData = new FormData();
        formData.append("id", user?.id);
        formData.append("my_interests", value);

        await API.post(`/auth/update-my-interests`, formData)
          .then((response) => {
            if (response?.status === 200 || response?.status === 201) {
              const result = response?.data?.result;
              if (result === true) {
                getUserProfile();
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onSubmitIntroVideo = async (value) => {
    if (user?.id) {
      try {
        const formData = new FormData();
        formData.append("id", user?.id);
        value !== null &&
          value !== undefined &&
          formData.append("intro_video", value);

        await API.post(`/auth/update-intro-video`, formData)
          .then((response) => {
            if (response?.status === 200 || response?.status === 201) {
              const result = response?.data?.result;
              if (result === true) {
                getUserProfile();
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getUserProfile = async () => {
    if (user?.id) {
      try {
        await API.get(`/auth/get-profile?id=${user?.id}`)
          .then((response) => {
            if (response?.status === 200) {
              console.log("response?.status", response);
              let res = response?.data;
              let city = response?.data?.city;

              setBaseInfo({
                first_name: res?.first_name,
                last_name: res?.last_name,
                email: res?.email,
                phone: res?.phone,
                countryId: res?.country,
                linkedin: res?.linkedin,
                file: res?.resume,
                avatar: res?.avatar,
                bio: res?.bio,
                my_interests: res?.my_interests,
                intro_video_link: res?.intro_video_link,
              });

              dispatch(setResumeUrl(res?.resume));

              if (city) {
                setCity({
                  cityName: city[0]?.name,
                  cityId: city[0]?.id,
                });
              }
              if (res?.job_title?.length > 0) {
                setTobTitles(res?.job_title);
              } else {
                setTobTitles([]);
              }

              if (res?.tech_tags?.length > 0) {
                setTags(res?.tech_tags);
              } else {
                setTags([]);
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <>
      <section className="profile-edit">
        <div className="container container-site">
        <div className="space-inner-row">
          <div className="profile-edit-row">
            <div className="profile-heading">
              <h2>My Profile {editable && <span>(Edit)</span>}</h2>
            </div>
            <div
              className="profile-eidt-icon icon-cursor"
              onClick={() => editHandler(true)}
            >
              <img src="/image/profile-edit.png" alt="" />
            </div>
          </div>
          </div>
        </div>
      </section>
      <section className="candidate-profile-sec">
        <div className="container container-site">
        <div className="space-inner-row">
          <div className="my-profile-row">
            {editable ? (
              <CanProfileForm
                loader={loader}
                baseInfo={baseInfo}
                city={city}
                jobTitles={jobTitles}
                tags={tags}
                editable={editable}
                editHandler={editHandler}
                submitHandler={submitHandler}
              />
            ) : (
              <CanProfileView
                baseInfo={baseInfo}
                city={city}
                jobTitles={jobTitles}
                tags={tags}
                onSubmitAboutSelf={onSubmitAboutSelf}
                onSubmitInterested={onSubmitInterested}
                onSubmitIntroVideo={onSubmitIntroVideo}
              />
            )}
            <div className="candidate-profile-btn">
              <div className="three-stack-btn">
                <button onClick={() => modelHandler(true)}>
                  View your Resume
                </button>
                <button>
                  <label id="candidate-img">
                    <input
                      id="file-input-btn"
                      type="file"
                      name="files"
                      onClick={(e) => handleImageChange(e)}
                      onChange={(e) => handleImageChange(e)}
                    />
                    <p className="file-btn">Upload new Resume</p>
                  </label>
                </button>
                <button onClick={() => changePasswordHandler()}>
                  Change Password
                </button>
              </div>
            </div>
          </div>
          </div>
        </div>
      </section>
      <WorldConnect />
      {toggleModel && <ResumeModel modelHandler={modelHandler} />}
    </>
  );
};

export default CanProfile;
