import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isModalOpen: false, // it can hold true or false
  noClickedButtonClicked: false,
};

const JobPostZoeModalSlice = createSlice({
  name: "jobDecision",
  initialState,
  reducers: {
    openZoeModal: (state) => {
      state.isModalOpen = true;
    },
    closeZoeModal: (state) => {
      state.isModalOpen = false;
    },
    setNoClickedToTrue: (state) => {
      state.noClicked = true;
    },
    setNoClickedToFalse: (state) => {
      state.noClicked = false;
    },
  },
});

export const {
  openZoeModal,
  closeZoeModal,
  setNoClickedToTrue,
  setNoClickedToFalse,
} = JobPostZoeModalSlice.actions;

export default JobPostZoeModalSlice.reducer;
