const CompanyDescription = ({ companyData }) => {
  return (
    <section className="company-detail-sec">
      <div className="container container-site">
        <div className="company-detail-heading">
          <h2>Company Details</h2>
        </div>
        <div className="divider-opcity "></div>
        <div className="company-content-logo">
          <div className="company-logo-left">
            <img
              src={
                companyData?.logo !== null
                  ? companyData?.logo
                  : "/image/amazaon.png"
              }
              alt=""
            />
            <h6>Amazon</h6>
          </div>
          <div className="company-content-right">
            {companyData?.name && <h6>{companyData?.name}</h6>}
            {companyData?.description && <p>{companyData?.description}</p>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompanyDescription;
