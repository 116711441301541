// AdminSidebar.js
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setActiveSideBar } from "../../../redux/slices/AdminDashboardSlice";
import { ADMIN_SIDE_BAR } from "../../../constants";
import "./AdminSidebar.css";


const AdminSidebar = () => {
  const dispatch = useDispatch();
  const { activeSideBar } = useSelector((state) => state.adminDashboard);

  const [adminSideBarOption, setAdminSideBarOption] = useState(ADMIN_SIDE_BAR);

  const toggleSubMenu = (index) => {
    const updatedAdminSideBarOption = [...adminSideBarOption];
    updatedAdminSideBarOption[index].subMenuOpen =
      !updatedAdminSideBarOption[index].subMenuOpen;
    setAdminSideBarOption(updatedAdminSideBarOption);
    dispatch(
      setActiveSideBar({
        id: updatedAdminSideBarOption[index].id,
        name: updatedAdminSideBarOption[index].name,
        value: updatedAdminSideBarOption[index].value,
      })
    );
  };

  const toggleSubSubMenu = (index, subIndex) => {
    const updatedAdminSideBarOption = [...adminSideBarOption];
    updatedAdminSideBarOption[index].subMenuOption[subIndex].subMenuOpen =
      !updatedAdminSideBarOption[index].subMenuOption[subIndex].subMenuOpen;
    setAdminSideBarOption(updatedAdminSideBarOption);

    dispatch(
      setActiveSideBar({
        id: updatedAdminSideBarOption[index].id,
        name: updatedAdminSideBarOption[index].subMenuOption[subIndex].name,
        value: updatedAdminSideBarOption[index].subMenuOption[subIndex].value,
      })
    );
  };

  return (
    <div className="col-md-2 sidebar-bg">
      <div className="siebar-logo">
        <img src="/image/WORKBRIDGE-white.png" alt="" />
      </div>
      <div className="navbar-sidemenu">
        <ul className="navbar-nav">
          {adminSideBarOption?.map((option, index) => (
            <li
              key={index}
              className={`sub-children nav-item icon-crv ${
                option.id === activeSideBar?.id ? "active" : ""
              }`}
            >
              <Link
                to={option?.path}
                className="menu-btn nav-link"
                onClick={() => toggleSubMenu(index)}
              >
                <img src={option?.icon} alt="" /> {option?.name}
                {option?.subMenuOption?.length > 0 && (
                  <img src="/image/down-menu.png" alt="" class="cart-menu" />
                )}
              </Link>
              {option?.subMenuOpen ||
                (option.id === activeSideBar.id && (
                  <ul
                    className={`inner-dropdown ${
                      option.id === activeSideBar.id ? "d-block" : "d-none"
                    }`}
                  >
                    {option?.subMenuOption.map((subOption, subIndex) => (
                      <li
                        key={subIndex}
                        className={`sub-children nav-item icon-crv ${
                          subOption?.name === activeSideBar?.name ||
                          subOption?.value === activeSideBar?.value
                            ? "active"
                            : ""
                        }`}
                      >
                        <Link
                          className="menu-btn"
                          to={subOption?.path}
                          onClick={() => toggleSubSubMenu(index, subIndex)}
                        >
                          <img src={subOption?.icon} alt="" /> {subOption?.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ))}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AdminSidebar;
