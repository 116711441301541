import { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import { useSelector } from "react-redux";

export const CandidateRoutes = ({ findJobHide, toggleAuthHeader }) => {
  const [isLogged, setIsLogged] = useState(true);
  const { isEmailVerify, user } = useSelector((state) => state.auth);

  return (isEmailVerify && user?.user_type === "candidate") ? (
    <Layout
      isLogged={isLogged}
      findJobHide={findJobHide}
      toggleAuthHeader={toggleAuthHeader}
    >
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/" replace />
  );
};
