import { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import JobRecordRight from "../../Common/JobRecord/JobRecordRight";
import JobRecordLeft from "../../Common/JobRecord/JobRecordLeft";

const RecJob = ({ recJobsCount, recJobs }) => {
  const [count, setCount] = useState(10);

  const countHandler = () => {
    if (count < recJobsCount) setCount((prevCount) => prevCount + 10);
  };

  if (recJobs?.length === 0) return null;

  return (
    <section className="new-job" id="rec_job">
      <div className="container container-site">
        <div className="job-heading">
          <h2>Recommended Jobs</h2>
        </div>

        {recJobs?.length > 0 &&
          recJobs?.slice(0, count)?.map((prod, index) => (
            <Fragment key={prod?.id}>
              <div className="divider"></div>
              <div className="Job-Opportunities-row">
                <JobRecordLeft JobData={prod} />
                <JobRecordRight
                  date={prod?.date}
                  selectedJobId={prod?.id}
                  isApplied={prod?.is_applied}
                  wishList={prod?.whishlist}
                />
              </div>
            </Fragment>
          ))}
        <div className="divider"></div>
      </div>
      {recJobs?.length > 10 && (
        <div className="see-more-job" onClick={() => countHandler()}>
          <Link href="#">
            <button>See more</button>
          </Link>
        </div>
      )}
    </section>
  );
};

export default RecJob;
