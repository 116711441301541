import "./WelcomeModel.css";

const WelcomeModel = ({ modelHandler }) => {
  return (
    <section className="model-popup model-popup-2">
      <div className="popup-bg ">
        <div className="popup-content ">
          <div className="close-img " onClick={() => modelHandler(true)}>
            <img src="/image/close.png " alt=" " />
          </div>
          <div className="send-correct">
            <img src="/image/WORKBRIDGE.png" alt=" " />
          </div>
          <div className="send-correct">
            <img src="/image/send-correct.png " alt=" " />
          </div>
          <p className="popup-text">
            Welcome! Your account has been created <span>Successfully</span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default WelcomeModel;
