import { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import JobRecordLeft from "../../Common/JobRecord/JobRecordLeft";
import JobDraftRight from "../../Common/JobRecord/JobDraftRight";
import { JobDraftData } from "../../../data/employer";

const JobDrafts = ({ draftJobsCount, draftJobs, onChangeJobStatus }) => {
  const [count, setCount] = useState(10);

  const countHandler = () => {
    if (count < draftJobsCount) setCount((prevCount) => prevCount + 10);
  };

  if (draftJobs?.length === 0) return null;

  return (
    <>
      <section className="open-postion" id="draft_jobs">
      <div className="space-inner-row">
        <div className="container container-site">
          <div className="job-search-box-row">
            <div className="job-heading smilar-job-heading">
              <h2>Drafts</h2>
            </div>
          </div>
          <div className="divider-opcity "></div>
        </div>
        </div>
      </section>
      <section className="company-job-sec">
        <div className="container container-site">
          {draftJobs?.length > 0 &&
            draftJobs?.slice(0, count)?.map((prod, index) => (
              <Fragment key={prod?.id}>
                <div className="divider"></div>
                <div className="Job-Opportunities-row">
                  <JobRecordLeft
                    activeSwitch={true}
                    radioBtn={false}
                    JobData={prod}
                    onChangeJobStatus={onChangeJobStatus}
                  />
                  <JobDraftRight
                    date={prod?.date}
                    selectedJobId={prod?.id}
                    onChangeJobStatus={onChangeJobStatus}
                  />
                </div>
              </Fragment>
            ))}

          <div className="divider"></div>
        </div>
        {draftJobs?.length > 10 && (
          <div className="see-more-job" onClick={() => countHandler()}>
            <Link href="#">
              <button>See more</button>
            </Link>
          </div>
        )}
      </section>
    </>
  );
};

export default JobDrafts;
