import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectCompanyId } from "../../../redux/slices/companyDetailSlice";
import API from "../../../api";
import "./CompanyCard.css";

const CompanyCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const onDetailCompanyHandler = (id) => {
    navigate(`/company-detail/${id}`);
  };

  useEffect(() => {
    async function getTopCompanies() {
      try {
        await API.get(`/job-board/top_companies`)
          .then((response) => {
            if (response?.status === 200) {
              let res = response?.data;
              setData(res);
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
    getTopCompanies();
  }, []);

  if (data?.length === 0) return null;

  return (
    <>
      <section className="featured-sec">
        <div className="container container-site">
          <div className="featured-row">
            {data?.length > 0 &&
              data.slice(0, 15).map((prod) => (
                <div className="featured-col ">
                  <div className="featured-col-content">
                    <div className="image-heading ">
                      <img src="/image/company-md.png" alt="fitenss " />
                      <button className="j-home-btn">3 jobs</button>
                    </div>
                    <div className="card-headeing">
                      <a         
                        onClick={() => onDetailCompanyHandler(prod?.id)}
                      >
                        <h3>{prod?.name}</h3>
                      </a>
                    </div>
                    <div className="card-text">
                      <p>
                        {prod?.description?.length >= 80 ? (
                          <p>{prod?.description?.substr(0, 80) + "..."}</p>
                        ) : (
                          <p>{prod?.description}</p>
                        )}
                      </p>
                    </div>
                    <Link to="/">
                      <button className="e-btn">E-Commerce</button>
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default CompanyCard;
