import { combineReducers } from "redux";
import AuthSlice from "./slices/AuthSlice";
import JobSearchSlice from "./slices/JobSearchSlice";
import JobDetailSlice from "./slices/JobDetailSlice";
import companyDetailSlice from "./slices/companyDetailSlice";
import employerDashboardSlice from "./slices/employerDashboardSlice";
import candidateDashboardSlice from "./slices/candidateDashboardSlice";
import ProfileSlice from "./slices/ProfileSlice";
import CommentModalSlice from "./slices/commentModalSlice";
import JobDecisionSlice from "./slices/JobDecisionSlice";
import JobPostZoeModalSlice from "./slices/JobPostZoeModalSlice";
import AdminDashboardSlice from "./slices/AdminDashboardSlice";

const rootReducer = combineReducers({
  auth: AuthSlice,
  jobSearch: JobSearchSlice,
  jobDetail: JobDetailSlice,
  companyDetail: companyDetailSlice,
  employerDashboard: employerDashboardSlice,
  candidateDashboard: candidateDashboardSlice,
  profile: ProfileSlice,
  comments: CommentModalSlice,
  jobDecision: JobDecisionSlice,
  jobPostZoe: JobPostZoeModalSlice,
  adminDashboard: AdminDashboardSlice,
});

export {rootReducer};
