import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeTab: "candidate",
  activeSubTab: "signUp",
  openLoginModel: false,
  isEmailVerify: false,
  accessToken: "",
  tokenType: "Bearer",
  user: null,
  userName: "",
  signUpEmp: {},
  mode: "add",
  postJobId: null,
  favoriteJobCount: 0,
};
const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    getLoginModel: (state) => {
      return state.openLoginModel;
    },
    setLoginModel: (state, action) => {
      state.openLoginModel = action.payload;
    },
    getActiveTab: (state) => {
      return state.activeTab;
    },
    getActiveSubTab: (state) => {
      return state.activeSubTab;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setActiveSubTab: (state, action) => {
      state.activeSubTab = action.payload;
    },
    setEmailVerify: (state, action) => {
      state.isEmailVerify = action.payload;
    },   
    setLoginUser: (state, action) => {
      state.accessToken = action.payload?.access_token;
      state.user = action.payload?.user;
    },
    setLoginUserName: (state, action) => {
      state.userName = action.payload;
    },
    getSignUpEmp: (state) => {
      return state.signUpEmp;
    },
    setSignUpEmp: (state, action) => {
      state.signUpEmp = action.payload;
    },
    setPostJobMode: (state, action) => {
      state.mode = action.payload;
    },
    setPostJobId: (state, action) => {
      state.postJobId = action.payload;
    },
    setFavoriteJobsCount: (state, action) => {
      state.favoriteJobCount = action.payload;
    },
    incrementFavoriteJobsCount: (state, payload) => {
      state.favoriteJobCount++;
    },
    clearLoginUser: (state) => {
      state.accessToken = "";
      state.user = null;
      state.isEmailVerify = false;
      state.mode = "add";
      state.postJobId = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  openLoginModel,
  setLoginModel,
  getActiveTab,
  getActiveSubTab,
  setActiveTab,
  setActiveSubTab,
  setEmailVerify,
  setLoginUser,
  setLoginUserName,
  getSignUpEmp,
  setSignUpEmp,
  setPostJobMode,
  setPostJobId,
  clearLoginUser,
  setFavoriteJobsCount,
  incrementFavoriteJobsCount,
} = AuthSlice.actions;

export default AuthSlice.reducer;
