import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectJobId } from "../../../../redux/slices/JobDetailSlice";
import { setSelectCompanyId } from "../../../../redux/slices/companyDetailSlice";
import getDays from "../../../../utils/getDays";

const TopMatchLeft = ({ selectedJobId, JobData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onDetailJobHandler = () => {
    navigate(`/job-view-detail/${selectedJobId}`);
  };

  const onDetailCompanyHandler = (id) => {
    navigate(`/company-detail/${id}`);
  };

  return (
    <>
      <div className="short-content-left job-col-left">
        <div className="job-title-left">
          <div
            className={`job-services-col-left  ${
              JobData?.is_promoted === 1
                ? "job-services-col-left-w-2"
                : "job-services-col-left-w"
            }`}
          >
            <a
              onClick={() => onDetailCompanyHandler(JobData?.company?.id)}
            >
              <img src={`/image/company-sm.png`} alt="fitenss" />
              <p>{JobData?.company?.name}</p>
            </a>
          </div>
          {JobData?.is_promoted === 1 && (
            <Link href="#">
              <button>Promoted * </button>
            </Link>
          )}
        </div>
        <Link href="#">
          <h3>{JobData?.name}</h3>
        </Link>

        <div className="job-time">
          <p>{JobData?.jobType?.length > 0 && JobData?.jobType[0].name}</p>
        </div>
        <p className="job-discription-left">
          {JobData?.description?.length >= 65 ? (
            <>{JobData?.description?.substr(0, 65) + "..."}</>
          ) : (
            <>{JobData?.description}</>
          )}{" "}
        </p>
        <a onClick={() => onDetailJobHandler()}>
          <button>View detailed Job Position</button>
        </a>
      </div>
      <div className="divider-two"></div>
    </>
  );
};

export default TopMatchLeft;
