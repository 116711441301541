import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import JobRecordLeft from "../../Common/JobRecord/JobRecordLeft";
import JobRecordRight from "../../Common/JobRecord/JobRecordRight";
import FilterDropDown from "../../Common/FilterDropDown/FilterDropDown";

import {
  JobDetailData,
  jobTitle,
  activeJob,
  datePosted,
  location,
} from "../../../data/job";

const OpenPosstion = ({
  jobTitles,
  setSearch,
  selectedJobTitile,
  datePosted,
  selectedDatePostedLabel,
  selectedDatePosted,
  expiryDate,
  selectedExpiryDateLabel,
  selectedExpiryDate,
  cities,
  selectedCity,
  jobs,
  OpenPosstionShow,
  onJobTitleClick,
  onSearchChange,
  onJobTitleClean,
  onDatePostedClick,
  onDatePostedClean,
  onExpiryClick,
  onExpiryClean,
  onLocationClick,
  onLocationClean
}) => {
  const [count, setCount] = useState(10);

  const countHandler = () => {
    if (count < jobs?.length) setCount((prevCount) => prevCount + 10);
  };

  if (OpenPosstionShow === false && jobs?.length === 0) return null;

  return (
    <>
      <section className="open-postion">
        <div className="container container-site">
          <div className="job-search-box-row">
            <div className="job-heading smilar-job-heading">
              <h2>Open Positions</h2>
            </div>
            <div className="search-box">
              <img src="/image/job-search.png" alt="" />
              <input
                type="text"
                name="search"
                id="jon-search-box"
                placeholder="Search Jobs"
                className="form-font-f"
                onChange={(e) => {
                  onSearchChange(e?.target?.value);
                }}
              />
            </div>
          </div>
          <div className="divider-opcity "></div>
          <div className="j-fillter-only">
            <div className="job-fillter-company">
              <div className="j-company-fillter">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-icon">
                    <p>
                      {selectedJobTitile === ""
                        ? "Job Title"
                        : selectedJobTitile?.length >= 20
                        ? selectedJobTitile?.substr(0, 20) + " ..."
                        : selectedJobTitile}
                    </p>
                    {selectedJobTitile === "" ? (
                      <img src="/image/company--detail-filter.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-b.png"
                          alt=""
                          onClick={() => onJobTitleClean()}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {jobTitles?.length > 0 && (
                  <div className="list-search-dropdown-company">
                    <ul className="list-job-title-company">
                      {jobTitles?.map((j, i) => (
                        <li
                          key={i}
                          className="icon-cursor"
                          onClick={() => {
                            onJobTitleClick(j);
                          }}
                        >
                          {j}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="job-fillter-company">
              <div className="j-company-fillter">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-icon">
                    <p>
                      {selectedDatePostedLabel === ""
                        ? "Date Posted"
                        : selectedDatePostedLabel}
                    </p>
                    {selectedDatePostedLabel === "" ? (
                      <img src="/image/company--detail-filter.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-b.png"
                          alt=""
                          onClick={() => onDatePostedClean()}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {datePosted?.length > 0 && (
                  <div className="list-search-dropdown-status">
                    <ul className="dorpdown-list-date-company">
                      {datePosted?.map((j, i) => (
                        <li
                          key={i}
                          className="icon-cursor"
                          onClick={() => {
                            onDatePostedClick(j?.value, j?.label);
                          }}
                        >
                          <img src="/image/calander-d.png" alt="" />
                          {j?.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="job-fillter-company">
              <div className="j-company-fillter">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-icon">
                    <p>
                      {selectedExpiryDateLabel === ""
                        ? "Expiry Date"
                        : selectedExpiryDateLabel}
                    </p>
                    {selectedExpiryDateLabel === "" ? (
                      <img src="/image/company--detail-filter.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-b.png"
                          alt=""
                          onClick={() => onExpiryClean()}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {expiryDate?.length > 0 && (
                  <div className="list-search-dropdown-status">
                    <ul className="dorpdown-list-date-company">
                      {expiryDate?.map((j, i) => (
                        <li
                          key={i}
                          className="icon-cursor"
                          onClick={() => {
                            onExpiryClick(j?.value, j?.label);
                          }}
                        >
                          <img src="/image/calander-d.png" alt="" />
                          {j?.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="job-fillter-company">
              <div className="j-company-fillter">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-icon">
                    <p>{selectedCity === "" ? "Location" : selectedCity}</p>
                    {selectedCity === "" ? (
                      <img src="/image/company--detail-filter.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-b.png"
                          alt=""
                          onClick={() => onLocationClean()}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {cities?.length > 0 && (
                  <div className="list-search-dropdown-status">
                    <ul className="dorpdown-list-date-company">
                      {cities?.map((j, i) => (
                        <li
                          key={i}
                          className="icon-cursor"
                          onClick={() => {
                            onLocationClick(j);
                          }}
                        >
                          <img src="/image/location-j-place.png" alt="" />
                          {j?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="company-job-sec">
        <div className="container container-site">
          {jobs?.length > 0 &&
            jobs?.slice(0, count)?.map((prod, index) => (
              <Fragment key={prod?.id}>
                <div className="divider"></div>
                <div className="Job-Opportunities-row">
                  <JobRecordLeft JobData={prod} />
                  <JobRecordRight
                    date={prod?.date}
                    selectedJobId={prod?.id}
                    isApplied={prod?.is_applied}
                    wishList={prod?.whishlist}
                  />
                </div>
              </Fragment>
            ))}
          <div className="divider"></div>
        </div>
        {jobs?.length > 10 && (
          <div className="see-more-job" onClick={() => countHandler()}>
            <Link href="#">
              <button>See more</button>
            </Link>
          </div>
        )}
      </section>
    </>
  );
};

export default OpenPosstion;
