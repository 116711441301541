import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  incrementFavoriteJobsCount,
  setLoginModel,
} from "../../../redux/slices/AuthSlice";
import API from "../../../api";
import {USER_TYPES} from "../../../constants";

function AddToWishlist({ jobId, wishlist, withParentButton = false }) {
  const dispatch = useDispatch();
  const { user, isEmailVerify } = useSelector((state) => state.auth);
  const [jobAddedToWishlist, setJobAddedToWishlist] = useState(false);
  const [textValue, setTextValue] = useState(null);
  const [imagePath, setImagePath] = useState(null);

  useEffect(() => {
    if (wishlist || jobAddedToWishlist) {
      setTextValue("Job Added to Wishlist");
      setImagePath("/image/favorite-filled.png");
      return;
    }
    setTextValue("Add Job To Wishlist");
    setImagePath("/image/favorite.png");
  }, [wishlist, jobAddedToWishlist, jobId, textValue, imagePath]);

  const wishListHandler = (jobId) => {
    if (isEmailVerify === false) {
      dispatch(setLoginModel(true));
      return;
    }
    if (jobAddedToWishlist || wishlist) {
      return;
    }
    addJobToWishList(jobId);
  };

  const addJobToWishList = async (jobId) => {
    if (user?.user_type === USER_TYPES.EMPLOYER ) return;
    if (user?.id && jobId) {
      try {
        const formData = new FormData();
        formData.append("user_id", user?.id);
        formData.append("job_id", jobId);
        await API.post(`/update-whishlist`, formData)
          .then((response) => {
            if (
              response?.status === 200 ||
              response?.status === 201 ||
              response?.status === 304
            ) {
              let res = response?.data;

              if (res?.result === true) {
                dispatch(incrementFavoriteJobsCount());
                setJobAddedToWishlist(true);
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  if (withParentButton) {
    return (
      <button
        className="fav-j-btn icon-cursor"
        onClick={() => wishListHandler(jobId)}
      >
        <img src={imagePath} alt="favorite" />
        {textValue}
      </button>
    );
  }

  return (
    <div
      className="job-favorite icon-cursor"
      onClick={() => wishListHandler(jobId)}
    >
      <img src={imagePath} alt="favorite" />
      <p>{textValue}</p>
    </div>
  );
}

export default AddToWishlist;
