import AdminHeader from "./AdminHeader/AdminHeader";
import AdminSidebar from "./AdminSidebar/AdminSidebar";
import PermissionModel from "../Common/PermissionModel/PermissionModel";

const AdminLayout = ({ children }) => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          {/* <!-- Sidebar --> */}
          <AdminSidebar />
          {/* <!-- Main Content --> */}
          <div className="col-md-10 right-content-sidebar">
            {/* <!-- header start here --> */}
            <AdminHeader />
            {children}
          </div>
        </div>
      </div>
      {/* <PermissionModel/> */}
    </>
  );
};

export default AdminLayout;
