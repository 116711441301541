import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import OutsideClickHandler from "react-outside-click-handler";
import PhoneNumber from "../../../Common/PhoneNumber/PhoneNumber";
import Message from "../EmployerForm/Message";
import API from "../../../../api";

const CandidateForm = ({
  errorMsg,
  loader,
  errorMsgHandler,
  CandidateSignUpHandler,
}) => {
  const [selectedImageName, setSelectedImageName] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);

  const [allJobTitles, setAllTobTitles] = useState([]);
  const [selectJobTitles, setSelectJobTitles] = useState([]);
  const [allJobTitlesHide, setAllJobTitlesHide] = useState(true);

  const [allSkillTags, setAllSkillTags] = useState([]);
  const [selectSkillTags, setSelectSkillTags] = useState([]);
  const [allSkillTagsHide, setAllSkillTagsHide] = useState(false);

  const highlightMatchingText = (option, inputValue) => {
    const lowercaseInputValue = inputValue.toLowerCase();
    const regex = new RegExp(`(${lowercaseInputValue})`, "gi");
    const parts = option.split(regex);

    return (
      <span>
        {parts.map((part, index) =>
          regex.test(part) ? (
            <strong key={index} style={{ color: "#143975" }}>
              {part}
            </strong>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      country: "",
      countryId: null,
      phoneNo: "",
      files: null,
      jobTitle: "",
      skillTags: "",
      uploadR: "",
      linkedinURL: "",
      termServices: false,
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter first name"),
      lastName: Yup.string().required("Please enter last name"),
      email: Yup.string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Please enter valid email address"
        )
        .required("Please enter email"),
      password: Yup.string().test((value) => {
        const errors = [];
        if (!value || value.length < 8) {
          errors.push("Password must be at least 8 characters in length");
        }
        if (!/\d/.test(value)) {
          errors.push("Use a minimum of 1 numeric value");
        }
        if (!/[A-Z]/.test(value)) {
          errors.push("Use at least 1 Upper Case Letter (ABCD)");
        }
        if (!/[a-z]/.test(value)) {
          errors.push("Use at least 1 Lower Case Letter (abcd)");
        }
        if (!/[!#%$*]/.test(value)) {
          errors.push("Use at least one special character (!# % $ *)");
        }
        setPasswordErrors(errors);

        return errors.length === 0;
      }),
      confirmPassword: Yup.string()
        .required("Please enter confirm password")
        .oneOf([Yup.ref("password")], "Your passwords do not match"),
      country: Yup.string().required("Please select country"),
      phoneNo: Yup.string().required("Enter phone number"),
      files: Yup.mixed()
        .required("Upload resume")
        .test(
          "fileType",
          "File can be a word or PDF document",
          (value) =>
            !value ||
            [
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ].includes(value?.type)
        )
        .test(
          "fileSize",
          "The file size should not exceed 5 MB",
          (value) => !value || value?.size <= 5 * 1024 * 1024
        ),
      termServices: Yup.boolean()
        .oneOf([true], "Please select the terms and conditions")
        .required("Please select the terms and conditions"),
      // jobTitle: Yup.string().required("Enter job titles"),
      // skillTags: Yup.string().test(
      skillTags: Yup.mixed().test(
        "skillTags",
        "Please select a minimum of 5 Skill Tags",
        (value) =>
          !!(Array.isArray(selectSkillTags) && selectSkillTags?.length >= 5)
      ),
    }),
    onSubmit: async (data, { resetForm, setErrors }) => {
      const { email } = data;
      if (passwordErrors?.length > 0) return;
      if (selectSkillTags?.length < 5) return;
      try {
        await API.post("/auth/check-email-unique", {
          email: email,
        })
          .then((response) => {
            if (response?.status === 200 || response?.status === 201) {
              let res = response?.data;
              if (response?.data?.result === true) {
                const selectJobTitlesId = selectJobTitles?.map(
                  (item) => item.id
                );
                const selectSkillTagsId = selectSkillTags.map(
                  (item) => item.id
                );
                CandidateSignUpHandler(
                  data,
                  selectJobTitlesId,
                  selectSkillTagsId
                );
              } else {
                let errorMsg = res?.message?.email[0];
                setErrors({ email: errorMsg });
                errorMsgHandler("");
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const isSkillTagsFieldValid = () =>
    !!(
      formik.touched["skillTags"] &&
      formik.errors["skillTags"] &&
      selectSkillTags?.length < 5
    );

  const getSkillTagsErrorMessage = () => {
    return (
      isSkillTagsFieldValid() && (
        <p className="error-msg">{formik.errors["skillTags"]}</p>
      )
    );
  };

  const isPasswordFieldValid = (passwordErrors) =>
    !!(formik.touched["password"] && passwordErrors?.length > 0);
  const getPasswordErrorMessage = (passwordErrors) => {
    if (isPasswordFieldValid(passwordErrors)) {
      return (
        <ul className="error-msg">
          {passwordErrors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      );
    }
    return null;
  };

  const countryHandler = async (id, name) => {
    await formik.setFieldValue("country", name);
    await formik.setFieldValue("countryId", id);
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImageName(file.name);
        formik.setFieldValue("files", file);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedImageName("");
      formik.setFieldValue("files", null);
    }
  };

  const onJobTitileChange = async (value) => {
    await formik.setFieldValue("jobTitle", value);
    getSearchSuggestion(value, "jobTitle");
  };

  const onJobTitileClick = async (curentJobTitles) => {
    const isIdExists = selectJobTitles?.some(
      (item) => item.id === curentJobTitles?.id
    );
    if (isIdExists) return;
    setSelectJobTitles([...selectJobTitles, curentJobTitles]);
    setAllJobTitlesHide(true);
    await formik.setFieldValue("jobTitle", "");
  };

  const onJobTitileCancel = async (curentJobTitles) => {
    const updatedItems = selectJobTitles.filter(
      (item) => item.id !== curentJobTitles?.id
    );
    setSelectJobTitles(updatedItems);
  };

  const onSkillTagsChange = async (value) => {
    await formik.setFieldValue("skillTags", value);
    getSearchSuggestion(value, "jobTag");
  };

  const onSkillTagsClick = async (curentSkillTags) => {
    const isIdExists = selectSkillTags?.some(
      (item) => item.id === curentSkillTags?.id
    );
    if (isIdExists) return;
    setSelectSkillTags([...selectSkillTags, curentSkillTags]);
    setAllSkillTagsHide(true);
    await formik.setFieldValue("skillTags", "");
  };

  const onSkillTagsCancel = async (curentSkillTags) => {
    const updatedItems = selectSkillTags.filter(
      (item) => item.id !== curentSkillTags?.id
    );
    setSelectSkillTags(updatedItems);
  };

  const getSearchSuggestion = async (searchValue, searchType) => {
    try {
      let params = ``;
      if (searchValue !== "") {
        params = `?query_key=${searchValue}&type=${searchType}`;
      }
      await API.get(`/job-board/get-search-suggestions${params}`)
        .then((response) => {
          if (response?.status === 200) {
            let res = response?.data;
            if (searchType === "jobTitle") {
              if (res?.job_titles?.length > 0) {
                setAllTobTitles(res?.job_titles);
                setAllJobTitlesHide(false);
              }
            }

            if (searchType === "jobTag") {
              if (res?.job_tags?.length > 0) {
                setAllSkillTags(res?.job_tags);
                setAllSkillTagsHide(false);
              }
            }
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    async function getCountries() {
      try {
        await API.get(`/get-countries`)
          .then((response) => {
            if (response?.status === 200) {
              let res = response?.data;
              setCountryOptions(res);
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }

    getCountries();
  }, []);

  return (
    <div className="candidate-signup-form-feild">
      <form onSubmit={formik.handleSubmit}>
        <div className="candidate-username-signup-form f-feild ">
          <div
            className={`first-name ${
              isFormFieldValid("firstName") && "error-input"
            }`}
          >
            <label for="fname">First Name</label>
            <br />
            <input
              className="form-font-f"
              type="text"
              placeholder="First Name"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {getFormErrorMessage("firstName")}
          </div>

          <div
            className={`last-name ${
              isFormFieldValid("lastName") && "error-input"
            }`}
          >
            <label for="lname">Last Name</label>
            <br />
            <input
              className="form-font-f"
              type="text"
              placeholder="Last Name"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {getFormErrorMessage("lastName")}
          </div>
        </div>

        <div
          className={`signup-email f-feild ${
            isFormFieldValid("email") && "error-input"
          }`}
        >
          <label for="femail">E-Mail</label>
          <br />
          <input
            className="form-font-f"
            type="email"
            name="email"
            id=""
            placeholder="Enter valid email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {getFormErrorMessage("email")}
        </div>

        <div
          className={`signup-passowrd f-feild ${
            isPasswordFieldValid(passwordErrors) && "error-input"
          }`}
        >
          <label for="fpassword">Password</label>
          <br />
          <div class="password-icon bg-pass-f">
            <input
              className="form-font-f"
              id="password-field"
              type={passwordShown ? "text" : "password"}
              name="password"
              placeholder="Create Password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="icon-hide">
              {isPasswordFieldValid(passwordErrors) ? (
                <img src="/image/warning.png" alt="" />
              ) : (
                <>
                  <img
                    src="/image/hide-password.png"
                    alt=""
                    className={passwordShown && "password-icon-togle"}
                    onClick={() => setPasswordShown(true)}
                  />
                  <img
                    src="/image/password.png"
                    alt=""
                    className={!passwordShown && "password-icon-togle"}
                    onClick={() => setPasswordShown(false)}
                  />
                </>
              )}
            </div>
          </div>
          {getPasswordErrorMessage(passwordErrors)}
        </div>

        <div
          className={`signup-passowrd f-feild ${
            isFormFieldValid("confirmPassword") && "error-input"
          }`}
        >
          <label for="fpassword">Confirm Password</label>
          <br />
          <div class="password-icon bg-pass-f">
            <input
              className="form-font-f"
              id="password-field"
              type={confirmPasswordShown ? "text" : "password"}
              name="confirmPassword"
              placeholder="Re-type Password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="icon-hide">
              {isFormFieldValid("confirmPassword") ? (
                <img src="/image/warning.png" alt="" />
              ) : (
                <>
                  <img
                    src="/image/hide-password.png"
                    alt=""
                    className={confirmPasswordShown && "password-icon-togle"}
                    onClick={() => setConfirmPasswordShown(true)}
                  />
                  <img
                    src="/image/password.png"
                    alt=""
                    className={!confirmPasswordShown && "password-icon-togle"}
                    onClick={() => setConfirmPasswordShown(false)}
                  />
                </>
              )}
            </div>
          </div>
          {getFormErrorMessage("confirmPassword")}
        </div>
        <div
          className={`signup-country f-feild ${
            isFormFieldValid("country") && "error-input"
          }`}
        >
          <label for="fcountry">Country</label>
          <br />
          <div
            className={`industry-feild-frm-country bg-pass-f ${
              isFormFieldValid("country") && "error-input"
            }`}
          >
            <div
              id="dropdown-boxId-2"
              className="company-form-dropdownBox-f"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {formik?.values?.country === ""
                ? "Choose Country"
                : formik?.values?.country}
            </div>
            <div className="icon-hide">
              {isFormFieldValid("country") ? (
                <img src="/image/warning.png" alt="" />
              ) : (
                <img src="/image/form-d-f.png" alt="" />
              )}
            </div>
            {countryOptions?.length > 0 && (
              <div className="list-search-dropdown-company">
                <ul className="list-job-title-country">
                  {countryOptions.map((j, i) => (
                    <li
                      className="icon-cursor-f"
                      onClick={() => countryHandler(j?.id, j?.name)}
                    >
                      {/* <img src="/image/US.png" alt="" /> */}
                      {j?.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div
          className={`signup-phone f-feild ${
            isFormFieldValid("phoneNo") && "error-input"
          }`}
        >
          <label for="fphone">Phone</label>
          <br />
          <div
            className={`merge-feild  ${
              isFormFieldValid("phoneNo") && "error-input"
            }`}
          >
            <PhoneNumber
              id=""
              className="form-font-f"
              type="tel"
              name="phoneNo"
              value={formik.values.phoneNo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          {getFormErrorMessage("phoneNo")}
        </div>
        <OutsideClickHandler
          onOutsideClick={() => {
            setAllJobTitlesHide(true);
          }}
        >
          <div className="signup-job-titles f-feild tags-tittles">
            <label for="fjobtitles">Job Titles I’m interested in</label>
            <input
              className="form-font-f"
              type="text"
              name="jobTitle"
              value={formik.values.jobTitle}
              onChange={(e) => onJobTitileChange(e.currentTarget.value)}
              placeholder="Job Titles I’m interested in"
            />
            {allJobTitles?.length > 0 && (
              <div
                className={`job-tags-titles-dropdown ${
                  allJobTitlesHide && "job-dropdown-hide"
                }`}
              >
                <ul className="dorpdown-list-location">
                  {allJobTitles.map((j, i) => (
                    <li
                      key={i}
                      onClick={() => {
                        onJobTitileClick(j);
                      }}
                    >
                      {formik?.values?.jobTitle?.length > 0 ? (
                        <>
                          {highlightMatchingText(
                            j?.name,
                            formik?.values?.jobTitle
                          )}
                        </>
                      ) : (
                        j?.name
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {selectJobTitles?.length > 0 && (
              <ul class="tags-titles-list">
                {selectJobTitles.map((j, i) => (
                  <li key={i}>
                    {j?.name}
                    <span onClick={() => onJobTitileCancel(j)}>
                      <img src="../image/cross.png" alt="" />
                    </span>
                  </li>
                ))}
              </ul>
            )}
            {/* <p className="error-msg hide-error">Enter job titles </p> */}
          </div>
        </OutsideClickHandler>
        <OutsideClickHandler
          onOutsideClick={() => {
            setAllSkillTagsHide(true);
          }}
        >
          <div
            className={`signup-skill-tags f-feild tags-tittles ${
              isSkillTagsFieldValid() && "error-input"
            }`}
          >
            <label for="fjobtitles">Tech Tags</label>
            <input
              className="form-font-f"
              type="text"
              name="skillTags"
              value={formik.values.skillTags}
              onChange={(e) => {
                onSkillTagsChange(e.currentTarget.value);
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              placeholder="Tech Tags"
            />
            {getSkillTagsErrorMessage()}
            {allSkillTags?.length > 0 && (
              <div
                className={`job-tags-titles-dropdown ${
                  allSkillTagsHide && "job-dropdown-hide"
                }`}
              >
                <ul className="dorpdown-list-location">
                  {allSkillTags.map((j, i) => (
                    <li
                      key={i}
                      onClick={() => {
                        onSkillTagsClick(j);
                      }}
                    >
                      {" "}
                      {j?.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {selectSkillTags?.length > 0 && (
              <ul class="tags-titles-list">
                {selectSkillTags.map((j, i) => (
                  <li key={i}>
                    {j?.name}
                    <span onClick={() => onSkillTagsCancel(j)}>
                      <img
                        src="../image/cross.png"
                        alt=""
                        onClick={() => {
                          isSkillTagsFieldValid();
                        }}
                      />
                    </span>
                  </li>
                ))}
              </ul>
            )}

            {/* <p className="error-msg hide-error ">Enter skill tags</p> */}
          </div>
        </OutsideClickHandler>
        <div
          className={`signup-resume f-feild upload-icon ${
            isFormFieldValid("files") && "error-input"
          }`}
        >
          <label for="fresume">Upload Resume</label>
          <div className="resume-choose-label">
            {" "}
            {selectedImageName !== "" ? selectedImageName : "Choose File"}
          </div>
          <input
            type="file"
            name="files"
            className="bg-pass-f"
            placeholder="Choose file"
            onClick={handleImageChange}
            onChange={handleImageChange}
          />
          {getFormErrorMessage("files")}
        </div>

        <div className={`signup-linkedIn-link f-feild`}>
          <label for="flink">LinkedIn Link</label>

          <input
            className="form-font-f"
            type="text"
            name="linkedinURL"
            id=""
            placeholder="Enter Your LinkedIn Profile Here"
            value={formik.values.linkedinURL}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className="signup-check f-feild">
          {isFormFieldValid("termServices") && (
            <div className="attention-box">
              <div className="attendtion-heading">
                <div className="icon-atten">
                  <img src="/image/attention.png" alt="" />
                </div>
                <div className="attention-text">
                  <p className="text-atten-box">
                    {formik?.errors?.termServices}
                  </p>
                </div>
              </div>
            </div>
          )}
          <input
            type="checkbox"
            name="termServices"
            checked={formik?.values?.termServices}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />{" "}
          &nbsp;Yes, I understand and Agree to the Terms of Service
        </div>
        <div className="candidate-from-signup-create">
          <button type="submit" className="form-submit">
            {loader ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Create"
            )}
          </button>
          <br />
          <Message errorMsg={errorMsg} />
        </div>
      </form>
    </div>
  );
};

export default CandidateForm;
