import axios from "axios";
import { BASE_API } from "./config";
import { useSelector } from "react-redux";

const APIPvt = axios.create();

APIPvt.interceptors.request.use(
  async (config) => {
    try {
      // Get the accessToken from the Redux state using useSelector
      const accessToken  = localStorage.getItem("token");

      const response = await axios.get(
        "https://workbridge.unitedsoftlabs.com/base_url.php"
      );

      if (response?.status === 200) {
        const { base_url } = response.data;

        config.baseURL = base_url + "/public/api/v1/admin/v1";
      } else {
        config.baseURL = BASE_API;
      }

      // Set the Bearer token in the request headers
      config.headers.Authorization = `Bearer ${accessToken}`;
    } catch (error) {
      // Handle errors
      console.error("Error fetching base URL:", error);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default APIPvt;
