import { useNavigate, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveSideBar,
} from "../../../../redux/slices/AdminDashboardSlice";
import * as Yup from "yup";
import PermissionForm from "./PermissionForm";
import FormError from "../../../Common/Admin/FormError/FormError";
import APIPvt from "../../../../apiPvt";
import PhoneNumber from "../../../Common/PhoneNumber/PhoneNumber";

const AdminCreateUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedDep, selectedRoleR } = useSelector((state) => state.adminDashboard);

  const [loader, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState([]);

  /*Dropdown Option*/
  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [depOptions, setDepOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [userTypeOptions, setUserTypeOptions] = useState([]);
  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const [moduleOptions, setModuleOptions] = useState([]);
  const [statusDropDown, setStatusDropDown] = useState([]);

  /*Select Value*/
  const [selectModule, setSelectModule] = useState([]);
  const [moduleError, setModuleError] = useState(false);

  /*Permission*/
  const [selectPermission, setSelectPermission] = useState([]);
  const [selectedSubModules, setselectedSubModules] = useState([]);
  const [selectedSubModulesSlug, setselectedSubModulesSlug] = useState([]);

  const [activePerTab, setActivePerTab] = useState("");

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      country: "",
      countryId: null,
      address: "",
      city: "",
      cityId: null,
      state: "",
      postalCode: "",
      phoneNo: "",
      email: "",

      dep: "",
      depId: null,
      role: "",
      roleId: null,
      userType: "",
      userTypeId: null,
      jobType: "",
      jobTypeId: null,
      module: "",

      userName: "",
      status: "",
      statusId: null,
      password: "",
      confirmPassword: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter First Name"),
      lastName: Yup.string().required("Please Enter First Name"),
      country: Yup.string().required("Please Select Country"),
      city: Yup.string().required("Please Select City"),
      state: Yup.string().required("Please Enter State/Province"),
      postalCode: Yup.string().required("Please Enter Postal Code"),
      phoneNo: Yup.string().required("Please Enter Phone Number"),
      email: Yup.string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Please Enter Valid Email Id"
        )
        .required("Please Enter Email Id"),

      dep: Yup.string().required("Please Enter Department"),
      role: Yup.string().required("Please Enter Role"),
      userType: Yup.string().required("Please Enter User Type"),
      jobType: Yup.string().required("Please Enter Job Type"),
      // module: Yup.mixed().test(
      //   "module",
      //   "Please Enter Admin Module Access",
      //   (value) => !!(Array.isArray(selectModule) && selectModule?.length <= 0)
      // ),

      userName: Yup.string().required("Please Enter User Name"),
      status: Yup.string().required("Please Enter Status"),
      password: Yup.string().test((value) => {
        const errors = [];
        if (!value || value.length < 8) {
          errors.push("Password Must Be At Least 8 Vharacters In Length");
        }
        if (!/\d/.test(value)) {
          errors.push("Use A Minimum Of 1 Numeric Value");
        }
        if (!/[A-Z]/.test(value)) {
          errors.push("Use At Least 1 Upper Case Letter (ABCD)");
        }
        if (!/[a-z]/.test(value)) {
          errors.push("Use At Least 1 Lower Case Letter (abcd)");
        }
        if (!/[!#%$*]/.test(value)) {
          errors.push("Use At Least One Special Character (!# % $ *)");
        }
        setPasswordErrors(errors);

        return errors.length === 0;
      }),
      confirmPassword: Yup.string()
        .required("Please Enter Confirm Password")
        .oneOf([Yup.ref("password")], "Password Doesn’t Match"),
    }),
    onSubmit: async (data, { resetForm }) => {
      if (isModuleFieldValid()) {
        setModuleError(true);
        return;
      } else if (passwordErrors?.length > 0) return;
      onCreateUser(data);
    },
  });

  const onCountyClick = async (id, name, cities) => {
    await formik.setFieldValue("country", name);
    await formik.setFieldValue("countryId", id);
    setCityOptions(cities);
  };

  const onCityClick = async (id, name) => {
    await formik.setFieldValue("city", name);
    await formik.setFieldValue("cityId", id);
  };

  const onDepClick = async (id, name, roles) => {
    await formik.setFieldValue("dep", name);
    await formik.setFieldValue("depId", id);
    setRoleOptions(roles);
  };

  const onRoleClick = async (id, title) => {
    await formik.setFieldValue("role", title);
    await formik.setFieldValue("roleId", id);
  };

  const onUserTypeClick = async (id, name) => {
    await formik.setFieldValue("userType", name);
    await formik.setFieldValue("userTypeId", id);
  };

  const onJobTypeClick = async (id, name) => {
    await formik.setFieldValue("jobType", name);
    await formik.setFieldValue("jobTypeId", id);
  };

  const onStatusClick = async (label, value) => {
    await formik.setFieldValue("status", label);
    await formik.setFieldValue("statusId", value);
  };

  /* Access Module Event Handler*/

  const onModuleClick = async (curentModule) => {
    const isIdExists = selectModule?.some(
      (item) => item.id === curentModule?.id
    );
    const newModule = [...selectModule, curentModule];
    if (isIdExists) return;
    setSelectModule(newModule);
    setselectedSubModulesSlug([]);
    if (newModule?.length > 0) setModuleError(false);
    getModulePermission(newModule);
  };

  const onModuleCancel = async (curentModule) => {
    const updatedItems = selectModule.filter(
      (item) => item.id !== curentModule?.id
    );
    setSelectModule(updatedItems);
    setselectedSubModulesSlug([]);
    if (updatedItems?.length === 0) setModuleError(true);
    getModulePermission(updatedItems);
  };

  const onActivePerTabClick = (item) => {
    setActivePerTab(item?.name);
    setselectedSubModules(item?.sub_modules);
  };

  const onSlugClick = (slugName) => {
    if (selectedSubModulesSlug.includes(slugName)) {
      setselectedSubModulesSlug((prevState) =>
        prevState.filter((name) => name !== slugName)
      );
    } else {
      setselectedSubModulesSlug((prevState) => [...prevState, slugName]);
    }
  };

  const onAddAllSlugClick = () => {
    if (Array.isArray(selectPermission) && selectPermission?.length > 0) {
      const permissionNames = selectPermission?.reduce((acc, module) => {
        if (module?.sub_modules && Array.isArray(module?.sub_modules)) {
          const subModulePermissionNames = module?.sub_modules.reduce(
            (subAcc, subModule) => {
              if (
                subModule?.permissions &&
                Array.isArray(subModule?.permissions)
              ) {
                const subModuleNames = subModule?.permissions.map(
                  (permission) => permission?.name
                );
                return [...subAcc, ...subModuleNames];
              }
              return subAcc;
            },
            []
          );
          return [...acc, ...subModulePermissionNames];
        }
        return acc;
      }, []);

      setselectedSubModulesSlug(permissionNames);
    }
  };

  const onRemoveAllSlugClick = () => {
    setselectedSubModulesSlug([]);
  };

  const onCancelClick = () => {
    dispatch(
      setActiveSideBar({
        id: "2",
        name: "Roles",
        value: "Create Role",
      })
    );
  };

  const isModuleFieldValid = () => !!(selectModule?.length <= 0);

  const getModuleErrorMessage = () => {
    return (
      isModuleFieldValid() && (
        <p className="error-msg">{formik.errors["module"]}</p>
      )
    );
  };

  const isPasswordFieldValid = (passwordErrors) =>
    !!(formik.touched["password"] && passwordErrors?.length > 0);
  const getPasswordErrorMessage = (passwordErrors) => {
    if (isPasswordFieldValid(passwordErrors)) {
      return (
        <ul className="error-msg">
          {passwordErrors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      );
    }
    return null;
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const onCreateUser = async (data) => {
    try {
      const {
        firstName,
        lastName,
        email,
        address,
        cityId,
        postalCode,
        countryId,
        phoneNo,
        depId,
        roleId,
        userTypeId,
        jobTypeId,
        userName,
        statusId,
        password,
        confirmPassword,
      } = data;
      let selectModuleId = [];
      selectModuleId = selectModule?.map((item) => item?.id);

      const formData = new FormData();
      formData.append("first_name", firstName);
      formData.append("last_name", lastName);
      formData.append("email", email);
      formData.append("address", address);
      formData.append("city_id", cityId);
      formData.append("postal_code", postalCode);
      formData.append("country_id", countryId);
      formData.append("phone_number", phoneNo);
      formData.append("department_id", depId);
      formData.append("role_id", roleId);
      formData.append("user_type_id", userTypeId);
      formData.append("job_type_id", jobTypeId);
      formData.append("username", userName);
      formData.append("status", statusId);
      formData.append("password", password);
      formData.append("password_confirmation", confirmPassword);

      selectModuleId?.length > 0 &&
        Object.keys(selectModuleId).forEach((key) => {
          formData.append("module_ids[]", selectModuleId[key]);
        });

      selectedSubModulesSlug?.length > 0
        ? Object.keys(selectedSubModulesSlug).forEach((key) => {
            formData.append("permissions[]", selectedSubModulesSlug[key]);
          })
        : formData.append("permissions[]", []);

      console.log("selectedSubModulesSlug", selectedSubModulesSlug?.length);
      setLoader(true);
      await APIPvt.post(`/user`, formData)
        .then((response) => {
          if (response?.status === 200 || response?.status === 201) {
            const res = response?.data;

            if (res?.success === true) {
              dispatch(
                setActiveSideBar({
                  id: "2",
                  name: "Users",
                  value: "Users",
                })
              );
              navigate("/admin-user");
            } else {
              setErrorMsg(res?.message);
              setTimeout(() => {
                setErrorMsg("");
              }, 5000);
            }
            setLoader(false);
          }
        })
        .catch((error) => {
          if (error) {
            const { response } = error;
            const { data } = response;
            setErrorMsg(data?.message);
            setTimeout(() => {
              setErrorMsg("");
            }, 5000);
            console.log(error);
          }
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const getDataCreateUser = async () => {
    try {
      await APIPvt.get(`/get-data-create-user`)
        .then((response) => {
          if (response?.status === 200 || response?.success === true) {
            let res = response?.data;
            let option = response?.data?.data;
            if (res?.success === true) {
              setCountryOptions(option?.countries);
              setDepOptions(option?.departments);
              setUserTypeOptions(option?.user_types);
              setJobTypeOptions(option?.job_types);
              setModuleOptions(option?.modules);
              setStatusDropDown(option?.status);
            }
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getModulePermission = async (selectModule) => {
    try {
      const { roleId } = formik?.values;
      const selectModuleId = selectModule?.map((item) => item.id);

      const formData = new FormData();
      roleId !== null && formData.append("role_id", roleId);
      selectModuleId !== null &&
        Object.keys(selectModuleId).forEach((key) => {
          formData.append("module_ids[]", selectModuleId[key]);
        });

      await APIPvt.post(`/get-module-submodule-permissions`, formData)
        .then((response) => {
          if (response?.status === 200 || response?.success === true) {
            let res = response?.data;
            let option = response?.data?.data;
            if (res?.success === true && res?.data?.length > 0) {
              setSelectPermission(option);
              setselectedSubModules(option[0]?.sub_modules);
              setActivePerTab(option[0]?.name);
            }
          }
        })
        .catch((error) => {
          if (error) {
            setselectedSubModules([]);
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDataCreateUser();
    async function getSelectedDepRole() {
      await formik.setFieldValue("depId", selectedDep?.id);
      await formik.setFieldValue("dep", selectedDep?.name);
      await formik.setFieldValue("roleId", selectedRoleR?.id);
      await formik.setFieldValue("role", selectedRoleR?.name);
    }
    getSelectedDepRole();
  }, []);



  console.log("selectModule", selectModule);
  console.log("selectedSubModulesSlug", selectedSubModulesSlug);
  return (
    <>
      <div className="form-heading">
        <h2>Create New User</h2>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="department-form-box">
          <div className="first-part-f">
            <h3>Personal Information</h3>
            <p>Add User personal details here</p>
          </div>
          <div className="admin-from-step space-b-f">
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("firstName") && "error-input-admin"
              }`}
            >
              <label for="">First Name *</label>
              <br />
              <input
                type="text"
                id="dept-f"
                placeholder="First Name"
                className="form-font-admin"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("firstName")}
            </div>
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("lastName") && "error-input-admin"
              }`}
            >
              <label for="">Last Name *</label>
              <br />
              <input
                type="text"
                id="dept-f"
                placeholder="Last Name"
                className="form-font-admin"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("lastName")}
            </div>

            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("country") && "error-input-admin"
              }`}
            >
              <label for="">Country *</label>
              <div className="admin-status-filter-form">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${
                        formik?.values?.country !== "" && "text-filter-select"
                      }`}
                    >
                      {" "}
                      {formik?.values?.country === ""
                        ? "Country"
                        : formik?.values?.country}
                    </p>
                    {isFormFieldValid("country") ? (
                      <img src="/image/warning.png" alt="" />
                    ) : (
                      <img src="/image/admin-d.png" alt="" />
                    )}
                  </div>
                </div>

                {countryOptions?.length > 0 && (
                  <div className="admin-status-dropdown-frm">
                    <ul className="list-admin-country">
                      {countryOptions?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onCountyClick(item?.id, item?.name, item?.cities);
                          }}
                        >
                          {/* <img src="/image/CA.png" alt="" /> */}
                          {item?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {getFormErrorMessage("country")}
            </div>
          </div>
          <div className="admin-from-step space-b-f">
            <div className="f-admin feild-f-admin-addres">
              <label for="">Address</label>
              <br />
              <input
                type="text"
                id="dept-f"
                placeholder="Address"
                className="form-font-admin"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("city") && "error-input-admin"
              }`}
            >
              <label for="">City *</label>
              <div className="admin-status-filter-form">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${
                        formik?.values?.city !== "" && "text-filter-select"
                      }`}
                    >
                      {" "}
                      {formik?.values?.city === ""
                        ? "City"
                        : formik?.values?.city}
                    </p>
                    {isFormFieldValid("city") ? (
                      <img src="/image/warning.png" alt="" />
                    ) : (
                      <img src="/image/admin-d.png" alt="" />
                    )}
                  </div>
                </div>
                {cityOptions?.length > 0 && (
                  <div className="admin-status-dropdown-frm">
                    <ul className="list-admin-country">
                      {cityOptions?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onCityClick(item?.id, item?.name);
                          }}
                        >
                          {/* <img src="/image/CA.png" alt="" /> */}
                          {item?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {getFormErrorMessage("city")}
            </div>
          </div>
          <div className="admin-from-step space-b-f">
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("state") && "error-input-admin"
              }`}
            >
              <label for="">State/Province *</label>
              <br />
              <input
                type="text"
                id="dept-f"
                placeholder="State/Province"
                className="form-font-admin"
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("state")}
            </div>
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("postalCode") && "error-input-admin"
              }`}
            >
              <label for="">Postal Code *</label>
              <br />
              <input
                type="text"
                id="dept-f"
                placeholder="Postal Code"
                className="form-font-admin"
                name="postalCode"
                value={formik.values.postalCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("postalCode")}
            </div>
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("phoneNo") && "error-input-admin"
              }`}
            >
              <label for="">Phone Number *</label>
              <PhoneNumber
                id="dept-f"
                className="form-font-admin"
                type="tel"
                name="phoneNo"
                value={formik.values.phoneNo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("phoneNo")}
            </div>

            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("email") && "error-input-admin"
              }`}
            >
              <label for="">Email Address *</label>
              <br />
              <input
                type="email"
                id="dept-f"
                placeholder="Email id"
                className="form-font-admin"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("email")}
            </div>
          </div>
        </div>

        <div className="department-form-box second-box-admin">
          <div className="first-part-f">
            <h3>User Detail</h3>
            <p>Add User detail here</p>
          </div>

          <div className="admin-from-step space-b-f">
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("dep") && "error-input-admin"
              }`}
            >
              <label for="">Department *</label>
              <div className="admin-status-filter-form">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${
                        formik?.values?.dep !== "" && "text-filter-select"
                      }`}
                    >
                      {" "}
                      {formik?.values?.dep === ""
                        ? "Department"
                        : formik?.values?.dep}
                    </p>
                    {isFormFieldValid("dep") ? (
                      <img src="/image/warning.png" alt="" />
                    ) : (
                      <img src="/image/admin-d.png" alt="" />
                    )}
                  </div>
                </div>
                {depOptions?.length > 0 && (
                  <div className="admin-status-dropdown-frm">
                    <ul className="list-admin-portal">
                      {depOptions?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onDepClick(item?.id, item?.name, item?.roles);
                          }}
                        >
                          {item?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {getFormErrorMessage("dep")}
            </div>

            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("role") && "error-input-admin"
              }`}
            >
              <label for="">Role *</label>
              <div className="admin-status-filter-form">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${
                        formik?.values?.role !== "" && "text-filter-select"
                      }`}
                    >
                      {" "}
                      {formik?.values?.role === ""
                        ? "Role"
                        : formik?.values?.role}
                    </p>
                    {isFormFieldValid("role") ? (
                      <img src="/image/warning.png" alt="" />
                    ) : (
                      <img src="/image/admin-d.png" alt="" />
                    )}
                  </div>
                </div>
                {roleOptions?.length > 0 && (
                  <div className="admin-status-dropdown-frm">
                    <ul className="list-admin-portal">
                      {roleOptions?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onRoleClick(item?.id, item?.title);
                          }}
                        >
                          {item?.title}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {getFormErrorMessage("role")}
            </div>
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("userType") && "error-input-admin"
              }`}
            >
              <label for="">User Type *</label>
              <div className="admin-status-filter-form">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${
                        formik?.values?.userType !== "" && "text-filter-select"
                      }`}
                    >
                      {" "}
                      {formik?.values?.userType === ""
                        ? "User Type"
                        : formik?.values?.userType}
                    </p>
                    {isFormFieldValid("userType") ? (
                      <img src="/image/warning.png" alt="" />
                    ) : (
                      <img src="/image/admin-d.png" alt="" />
                    )}
                  </div>
                </div>
                {userTypeOptions?.length > 0 && (
                  <div className="admin-status-dropdown-frm">
                    <ul className="list-admin-portal">
                      {userTypeOptions?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onUserTypeClick(item?.id, item?.name);
                          }}
                        >
                          {item?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {getFormErrorMessage("userType")}
            </div>
          </div>
          <div className="admin-from-step space-b-f">
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("jobType") && "error-input-admin"
              }`}
            >
              <label for="">Job Type *</label>
              <div className="admin-status-filter-form">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${
                        formik?.values?.jobType !== "" && "text-filter-select"
                      }`}
                    >
                      {" "}
                      {formik?.values?.jobType === ""
                        ? "Job Type"
                        : formik?.values?.jobType}
                    </p>
                    {isFormFieldValid("jobType") ? (
                      <img src="/image/warning.png" alt="" />
                    ) : (
                      <img src="/image/admin-d.png" alt="" />
                    )}
                  </div>
                </div>
                {jobTypeOptions?.length > 0 && (
                  <div className="admin-status-dropdown-frm">
                    <ul className="list-admin-portal">
                      {jobTypeOptions?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onJobTypeClick(item?.id, item?.name);
                          }}
                        >
                          <img src="/image/li-search.png" alt="" />
                          {item?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {getFormErrorMessage("jobType")}
            </div>

            <div
              className={`f-admin feild-f-admin tags-admin ${
                moduleError && "error-input-admin"
              }`}
            >
              <label for="">Module Access *</label>
              <div className="admin-status-filter-form">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p>Admin Module Access</p>
                    {moduleError ? (
                      <img src="/image/warning.png" alt="" />
                    ) : (
                      <img src="/image/admin-d.png" alt="" />
                    )}
                  </div>
                </div>

                {moduleOptions?.length > 0 && (
                  <div className="admin-status-dropdown-frm">
                    <ul className="list-admin-portal">
                      {moduleOptions?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onModuleClick(item);
                          }}
                        >
                          <img src="/image/modules.svg" alt="" />
                          {item?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {moduleError && (
                <p class="error-msg">Please Enter Admin Module Access</p>
              )}
              {selectModule?.length > 0 && (
                <ul class="tags-titles-list">
                  {selectModule.map((j, i) => (
                    <li key={i}>
                      {j?.name}
                      <span onClick={() => onModuleCancel(j)}>
                        <img src="/image/cross.png" alt="" />
                      </span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="f-admin feild-f-admin"></div>
          </div>
        </div>

        <div className="department-form-box">
          <div className="first-part-f">
            <h3>User Credential</h3>
            <p>Add User Credentail detail here</p>
          </div>
          <div className="admin-from-step space-b-f">
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("userName") && "error-input-admin"
              }`}
            >
              <label for="">User Name *</label>
              <br />
              <input
                type="text"
                id="dept-f"
                placeholder="User Name"
                className="form-font-admin"
                name="userName"
                value={formik.values.userName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("userName")}
            </div>

            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("status") && "error-input-admin"
              }`}
            >
              <label for="">Status</label>
              <div className={`admin-status-filter-form`}>
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${
                        formik?.values?.status !== "" && "text-filter-select"
                      }`}
                    >
                      {" "}
                      {formik?.values?.status === ""
                        ? "Status"
                        : formik?.values?.status}
                    </p>
                    {isFormFieldValid("status") ? (
                      <img src="/image/warning.png" alt="" />
                    ) : (
                      <img src="/image/admin-d.png" alt="" />
                    )}
                  </div>
                </div>

                {statusDropDown?.length > 0 && (
                  <div className="admin-status-dropdown-frm">
                    <ul className="list-admin-portal">
                      {statusDropDown?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onStatusClick(item?.label, item?.value);
                          }}
                        >
                          {item?.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {getFormErrorMessage("status")}
            </div>

            <div className="f-admin feild-f-admin"></div>
          </div>
          <div className="admin-from-step space-b-f">
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("password") && "error-input-admin"
              }`}
            >
              <label for="">Password * </label>
              <div className="admin-status-filter-form">
                <div className="password-admin-f">
                  <input
                    type={passwordShown ? "text" : "password"}
                    id="admin-pass-field"
                    placeholder="Password"
                    className="form-font-admin"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {isPasswordFieldValid(passwordErrors) ? (
                    <img src="/image/warning.png" alt="" />
                  ) : (
                    <>
                      <img
                        src="/image/pass-admin.png"
                        alt=""
                        className={passwordShown && "password-icon-togle"}
                        onClick={() => setPasswordShown(true)}
                      />
                      <img
                        src="/image/hide-pass-admin.png"
                        alt=""
                        className={!passwordShown && "password-icon-togle"}
                        onClick={() => setPasswordShown(false)}
                      />
                    </>
                  )}
                </div>
              </div>
              {getPasswordErrorMessage(passwordErrors)}
            </div>
            <div
              className={`f-admin feild-f-admin ${
                isFormFieldValid("confirmPassword") && "error-input-admin"
              }`}
            >
              <label for="">Confirm Password *</label>
              <div className="admin-status-filter-form">
                <div className="password-admin-f">
                  <input
                    type={confirmPasswordShown ? "text" : "password"}
                    id="admin-pass-field"
                    placeholder="Confirm Password"
                    className="form-font-admin"
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  {isFormFieldValid("confirmPassword") ? (
                    <img src="/image/warning.png" alt="" />
                  ) : (
                    <>
                      <img
                        src="/image/pass-admin.png"
                        alt=""
                        className={
                          confirmPasswordShown && "password-icon-togle"
                        }
                        onClick={() => setConfirmPasswordShown(true)}
                      />
                      <img
                        src="/image/hide-pass-admin.png"
                        alt=""
                        className={
                          !confirmPasswordShown && "password-icon-togle"
                        }
                        onClick={() => setConfirmPasswordShown(false)}
                      />
                    </>
                  )}
                </div>
              </div>
              {getFormErrorMessage("confirmPassword")}
            </div>
            <div className="f-admin feild-f-admin"></div>
          </div>
        </div>

        {selectPermission?.length > 0 && (
          <PermissionForm
            activePerTab={activePerTab}
            selectPermission={selectPermission}
            selectedSubModules={selectedSubModules}
            selectedSubModulesSlug={selectedSubModulesSlug}
            onActivePerTabClick={onActivePerTabClick}
            onSlugClick={onSlugClick}
            onAddAllSlugClick={onAddAllSlugClick}
            onRemoveAllSlugClick={onRemoveAllSlugClick}
          />
        )}

        <FormError errorMsg={errorMsg} />
        <div className="admin-form-btn">
          {" "}
          <button className="cancel-admin" onClick={() => onCancelClick()}>
            Cancel
          </button>
          <button className="next-admin" type="submit">
            {" "}
            {loader ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default AdminCreateUser;
