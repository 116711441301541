import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import {
  setActiveSubTab,
  setActiveTab,
  setSignUpEmp,
  clearLoginUser,
  setPostJobMode,
  setPostJobId,
} from "../../../redux/slices/AuthSlice";
import { setClearFilter } from "../../../redux/slices/JobSearchSlice";
import { setActiveSideBar } from "../../../redux/slices/AdminDashboardSlice";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import "./TopNavBar.css";

const TopNavBar = ({
  findJobHide,
  loginHide,
  signUpHide,
  toggleAuthHeader,
  isLogged,
}) => {
  const { isEmailVerify, user, userName, favoriteJobCount } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();

  const onSignUpHandler = () => {
    dispatch(setActiveTab("candidate"));
    dispatch(setActiveSubTab("signUp"));
    dispatch(setSignUpEmp({}));
    dispatch(setPostJobId(null));
  };

  const onLogOutHandler = () => {
    dispatch(clearLoginUser());
    localStorage.removeItem("token");
  }

  const PublicHeader = () => {
    return (
      <div className="container container-site">
        <div className="row-log">
          <div className="logo-col icon-cursor">
            <Link to="/">
              <img src="/image/WORKBRIDGE.png" alt="logo" />
            </Link>
          </div>
          <div className="col-two-button">
            {!findJobHide && (
              <div className="find-job-btn">
                <Link
                  to="/job-search"
                  onClick={() => dispatch(setClearFilter())}
                >
                  <button className="find-j-nav ">Find a Job</button>
                </Link>
              </div>
            )}
            <div className="menu-items">
              {!loginHide && (
                <p>
                  <Link to="/login">
                    <button className="login-header">Login </button>
                  </Link>
                </p>
              )}

              {!signUpHide && (
                <Link to="/signup" onClick={() => onSignUpHandler()}>
                  <button className="signuo-nav">Sign Up</button>
                </Link>
              )}
            </div>
            <div className="toggleBtn">
              <img src="/image/toogle-btn.png" alt="toggleBtn" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const DashboardHeader = () => {
    const [hideMenu, setHideMenu] = useState(true);

    const postJobClickHanlder = () => {
      dispatch(setActiveTab("employer"));
      dispatch(setActiveSubTab("postJob"));
      dispatch(setPostJobMode("add"));
      dispatch(setPostJobId(null));
    };

    return (
      <div className="container container-site-profile">
        {isEmailVerify === true && user?.user_type === "employer" ? (
          <>
            <div className="row-log candidate-profile-header">
              <div className="logo-col-profile-left icon-cursor">
                <Link to="/">
                  <img src="/image/WORKBRIDGE.png" alt="logo" />
                </Link>
                <div
                  className="find-job-btn-candidate-dashbord"
                  onClick={() => postJobClickHanlder()}
                >
                  <Link to="/signup">
                    <button>Post a Job</button>
                  </Link>
                </div>
              </div>
              <div className="hide-wishlist-style">
                <div className="candidate-name-img icon-cursor">
                  <img src="/image/profile.png" alt="" />
                  <p className="candidate-name">
                    Hi,{" "}
                    {userName !== "" ? capitalizeFirstLetter(userName) : "John"}
                  </p>
                </div>
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setHideMenu(true);
                  }}
                >
                  <div
                    className="toggle-icon-profile icon-cursor"
                    onClick={() => setHideMenu(!hideMenu)}
                  >
                    <img src="/image/menu-profile.png" alt="" />
                  </div>
                </OutsideClickHandler>
              </div>
            </div>
            <div
              className={`dropdown-dashbord ${hideMenu && "hide-show-click"}`}
              onClick={() => setHideMenu(true)}
            >
              <ul className="dropdown-menu-dashbord">
                <Link to="/employer-dashboard">
                  <li className="menu-dropdown">
                    <img src="/image/dash-m.png" alt="" />
                    Dashboard
                  </li>
                </Link>
                <Link to="/employer-profile">
                  <li className="menu-dropdown">
                    <img src="/image/profl-m.png" alt="" />
                    My Profile
                  </li>
                </Link>
                <Link
                  to="/admin-dashboard"
                  onClick={() =>
                    dispatch(
                      setActiveSideBar({
                        id: "1",
                        name: "Modules",
                        value: "Modules",
                      })
                    )
                  }
                >
                  <li className="menu-dropdown">
                    <img src="/image/setting-m.png" alt="" />
                    System Settings
                  </li>
                </Link>
                <Link to="/" onClick={() => onLogOutHandler()}>
                  <li className="menu-dropdown">
                    <img src="/image/log-m.png" alt="" />
                    Logout
                  </li>
                </Link>
              </ul>
            </div>
          </>
        ) : (
          <>
            <div className="row-log candidate-profile-header">
              <div className="logo-col-profile-candidate icon-cursor">
                <Link to="/">
                  <img src="/image/WORKBRIDGE.png" alt="logo" />
                </Link>
                {!findJobHide && (
                  <div className="find-job-btn-candidate-dashbord">
                    <Link
                      to="/job-search"
                      onClick={() => dispatch(setClearFilter())}
                    >
                      <button className="find-j-nav ">Find a Job</button>
                    </Link>
                  </div>
                )}
              </div>
              <div className="profile-right-col icon-cursor">
                <div className="candidate-name-img">
                  <img src="/image/profile.png" alt="" />
                  <p className="candidate-name">
                    Hi,{" "}
                    {userName !== "" ? capitalizeFirstLetter(userName) : "John"}
                  </p>
                </div>
                <div className="candidate-wishlist-block icon-cursor">
                  <Link to="/candidate-wishlist">
                    <img src="/image/wishlist.png" alt="" />
                    <p className="candidate-wishlist">Wishlist</p>
                    <div className="whislist-notofication">
                      <p className="conter-text-wiishlist">
                        {favoriteJobCount}
                      </p>
                    </div>
                  </Link>
                </div>
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setHideMenu(true);
                  }}
                >
                  <div
                    className="toggle-icon-profile icon-cursor"
                    onClick={() => setHideMenu(!hideMenu)}
                  >
                    <img src="/image/menu-profile.png" alt="" />
                  </div>
                </OutsideClickHandler>
              </div>
            </div>
            <div
              className={`dropdown-dashbord ${hideMenu && "hide-show-click"}`}
              onClick={() => setHideMenu(true)}
            >
              <ul className="dropdown-menu-dashbord">
                <Link to="/candidate-dashboard">
                  <li className="menu-dropdown">
                    <img src="/image/dash-m.png" alt="" />
                    Dashboard
                  </li>
                </Link>
                <Link to="/candidate-profile">
                  <li className="menu-dropdown">
                    <img src="/image/profl-m.png" alt="" />
                    My Profile
                  </li>
                </Link>
                <Link to="/candidate-wishlist">
                  <li className="menu-dropdown">
                    <img src="/image/wishlist-m.png" alt="" />
                    Wishlist
                  </li>
                </Link>
                <Link to="/" onClick={() => onLogOutHandler()}>
                  <li className="menu-dropdown">
                    <img src="/image/log-m.png" alt="" />
                    Logout
                  </li>
                </Link>
              </ul>
            </div>
          </>
        )}
      </div>
    );
  };
  return (
    <header>
      {isEmailVerify === false ? <PublicHeader /> : <DashboardHeader />}
    </header>
  );
};

export default TopNavBar;
