import { Link } from "react-router-dom";
import TopMatchLeft from "./TopMatchLeft";
import TopMatchRight from "./TopMatchRight";
import Pagination from "../Pagination/Pagination";
import "./TopMatchContainer.css";

const TopMatchContainer = ({
  startPage,
  currentPage,
  endPage,
  featuredJobs,
  topMatchJobs,
  onCurrentPageHandler,
}) => {

  return (
    <>
      <section className="job-posstion-job-content">
        <div className="container container-site">
          <div className="job-heading">
            <h2>Top Matches</h2>
          </div>
          <div className="divider"></div>
          <div className="left-right-content">
            <div className="col-left-j-space">
              {featuredJobs?.length > 0 &&
                featuredJobs.map((prod) => (
                  <TopMatchLeft JobData={prod} selectedJobId={prod?.id} />
                ))}
            </div>

            <div className="divider-left"></div>
            <div className="col-right-j-space">
              {topMatchJobs?.length > 0 &&
                topMatchJobs.map((prod, index) => (
                  <TopMatchRight key={prod?.id} JobData={prod} selectedJobId={prod?.id} isApplied={prod?.is_applied}/>
                ))}
            </div>
          </div>
        </div>
      </section>
      <Pagination
         startPage={startPage}
         currentPage={currentPage}
         endPage={endPage}
        onCurrentPageHandler={onCurrentPageHandler}
      />
    </>
  );
};

export default TopMatchContainer;
