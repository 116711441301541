import "./LogoSection.css";

const LogoSection = () => {
  return (
    <>
      <section className="logo-dev">
        <div className="container container-site">
          <div className="logs-ros ">
            <img src="/image/Python.png " alt="Python " />
            <img src="/image/Azure.png " alt="Azure " />
            <img src="/image/aws.png " alt="aws " />
            <img src="/image/DevOps.png " alt="DevOps " />
            <img src="/image/Sql.png " alt="Sql " />
            <img src="/image/Microsoft.png " alt="Microsoft " />
          </div>
        </div>
      </section>
    </>
  );
};

export default LogoSection;
