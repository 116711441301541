import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import APIPvt from "../../apiPvt";

const initialState = {
  activeSideBar: { id:"1", name: "Modules", value: "Modules" },
  selectedDep: {id: null, name:""},
  selectedRoleR: {id: null, name: ""}

};
const AdminDashboardSlice = createSlice({
  name: "adminDashboard",
  initialState,
  reducers: {
    setActiveSideBar: (state, action) => {
      state.activeSideBar = action.payload;
    },
    setSelectedDep: (state, action) => {
      state.selectedDep = action.payload;
    },
    setSelectedRoleR: (state, action) => {
      state.selectedRoleR = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {setActiveSideBar, setSelectedDep, setSelectedRoleR} = AdminDashboardSlice.actions;

export default AdminDashboardSlice.reducer;


// asynchronous function with createAsyncThunk
export const depStatusDropdownAsync = createAsyncThunk(
  "admin/depStatusDropdown",
  async () => {

    try {
      const response = await APIPvt.get(`/departments-status-dropdown`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  },
);