import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import API from "../../../../api";
import "../ForgotForm/ForgotForm.css";
const PasswordForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const [loader, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [passwordErrors, setPasswordErrors] = useState([]);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      password: Yup.string().test((value) => {
        const errors = [];
        if (!value || value.length < 8) {
          errors.push("Password must be at least 8 characters in length");
        }
        if (!/\d/.test(value)) {
          errors.push("Use a minimum of 1 numeric value");
        }
        if (!/[A-Z]/.test(value)) {
          errors.push("Use at least 1 Upper Case Letter (ABCD)");
        }
        if (!/[a-z]/.test(value)) {
          errors.push("Use at least 1 Lower Case Letter (abcd)");
        }
        if (!/[!#%$*]/.test(value)) {
          errors.push("Use at least one special character (!# % $ *)");
        }
        setPasswordErrors(errors);

        return errors.length === 0;
      }),
      confirmPassword: Yup.string()
        .required("Please enter confirm password")
        .oneOf([Yup.ref("password")], "Your passwords do not match"),
    }),
    onSubmit: async (data, { resetForm }) => {
      const { password } = data;
      try {
        let params = `?`;
        if (token !== "") {
          params += `token=${token}&`;
        }
        if (password !== "") {
          params += `password=${password}`;
        }
        setLoader(true);
        await API.get(`/auth/Password-Changed${params}`)
          .then((response) => {
            if (response?.status === 200 || response?.status === 201) {
              const res = response?.data;
              if (res?.result === true) {
                navigate("/login");
              }
              setLoader(false);
            }
          })
          .catch((error) => {
            if (error) {
              const { response } = error;
              const { data } = response;
              setErrorMsg(data?.message);
              setTimeout(() => {
                setErrorMsg("");
              }, 5000);
              console.log(error);
            }
            setLoader(false);
          });
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const isPasswordFieldValid = (passwordErrors) =>
    !!(formik.touched["password"] && passwordErrors?.length > 0);
  const getPasswordErrorMessage = (passwordErrors) => {
    if (isPasswordFieldValid(passwordErrors)) {
      return (
        <ul className="error-msg">
          {passwordErrors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      );
    }
    return null;
  };

  return (
    <>
      <section className="candiate-login">
        <div className="container container-site">
          <div className="candidate-login-row-password">
            <div className="candidate-profile candidate-profile-login-f">
              <img src="/image/passwordforget.png" alt="password" />
            </div>
            <div className="candiate-login-form">
              <div className="candidate-signin-password-ft change-password-f">
                <h2>Change Password</h2>
                <form onSubmit={formik.handleSubmit}>
                  <div className="candidate-email">
                    <div
                      className={`signup-passowrd password-change-f ${
                        isFormFieldValid("password") && "error-input"
                      }`}
                    >
                      <div className="password-icon bg-pass-f">
                        <input
                          className="form-font-f"
                          id="password-field"
                          type={passwordShown ? "text" : "password"}
                          name="password"
                          placeholder="Enter valid password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />

                        <div className="icon-hide">
                          {isFormFieldValid("password") ? (
                            <img src="/image/warning.png" alt="" />
                          ) : (
                            <>
                              <img
                                src="/image/hide-password.png"
                                alt=""
                                className={
                                  passwordShown && "password-icon-togle"
                                }
                                onClick={() => setPasswordShown(true)}
                              />
                              <img
                                src="/image/password.png"
                                alt=""
                                className={
                                  !passwordShown && "password-icon-togle"
                                }
                                onClick={() => setPasswordShown(false)}
                              />
                            </>
                          )}
                        </div>
                      </div>
                      {getPasswordErrorMessage(passwordErrors)}
                      {/* <div className="progress-bar-password">
                        <div className="progress-password">
                          <div className="progress-part-password prograssbar-active"></div>
                          <div className="progress-part-password"></div>
                          <div className="progress-part-password"></div>
                          <div className="progress-part-password"></div>
                          <div className="progress-part-password"></div>
                        </div>
                      </div>
                      <ul className="error-msg">
                        <li className="colr-green">
                          Password must be at least 8 characters in length
                        </li>
                        <li>Use a minimum of 1 numeric value,</li>
                        <li>Use at least 1 Upper Case Letter (ABCD)</li>
                        <li> at least 1 Lower Case Letter (abcd)</li>
                        <li>
                          User at least one special character ( !# % $ * )
                        </li>
                      </ul> */}
                    </div>
                    <div
                      className={`signup-passowrd password-change-f ${
                        isFormFieldValid("confirmPassword") && "error-input"
                      }`}
                    >
                      <div className="password-icon bg-pass-f">
                        <input
                          className="form-font-f"
                          type={confirmPasswordShown ? "text" : "password"}
                          name="confirmPassword"
                          id="password-field"
                          placeholder="Confirm New Password"
                          value={formik.values.confirmPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />

                        <div className="icon-hide">
                          {isFormFieldValid("confirmPassword") ? (
                            <img src="/image/warning.png" alt="" />
                          ) : (
                            <>
                              <img
                                src="/image/hide-password.png"
                                alt=""
                                className={
                                  confirmPasswordShown && "password-icon-togle"
                                }
                                onClick={() => setConfirmPasswordShown(true)}
                              />
                              <img
                                src="/image/password.png"
                                alt=""
                                className={
                                  !confirmPasswordShown && "password-icon-togle"
                                }
                                onClick={() => setConfirmPasswordShown(false)}
                              />
                            </>
                          )}
                        </div>
                      </div>
                      {getFormErrorMessage("confirmPassword")}
                    </div>
                    <div className="heading-below-p">
                      <p className="heading-below-text">{errorMsg}</p>
                    </div>
                    <div className="candidate-from-submit">
                      <button type="submit" className="password-btn">
                        {loader ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Reset"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PasswordForm;
