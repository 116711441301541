const transformRes = async (data) => {
  if (!data) {
    return [];
  }
  try {
    const currentArray = JSON?.parse(data);
    const convertArray = currentArray?.map((item) => {
      return item;
    });
    return convertArray;
  } catch (error) {
    console.error("Error parsing responsibilities:", error);
    return [];
  }
};

export default transformRes;
