const BaseInfo = ({ appliedJobsCount, recJobsCount }) => {
  return (
    <section className="candidate-dashbord-sec">
      <div className="container container-site">
        <div class="space-inner-row">
          <div className="job-recommend-applied">
            <a href="#job_applied" className="card-link-box-1">
              <div className="job-applied-col job-candidate-cards">
                <img src="/image/job-applied.png" alt="" />
                <p className="job-title-candidate-dashboard">Jobs Applied</p>
                <p className="job-applied-candidate-dashboard">
                  {appliedJobsCount}
                </p>
              </div>
            </a>
            <a href="#rec_job" className="card-link-box-2">
              <div className="job-applied-col job-candidate-cards">
                <img src="/image/number-job.png" alt="" />
                <p className="job-title-candidate-dashboard">
                  Recommended Jobs
                </p>
                <p className="job-applied-candidate-dashboard">
                  {recJobsCount}
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BaseInfo;
