import "./WorldConnect.css";

const WorldConnect = () => {
  return (
    <>
      <section className="world-conect-sec">
        <div className="world-conect-row">
          <img src="/image/world.png" alt="world" />
          <p>Connecting the world to work</p>
        </div>
      </section>
    </>
  );
};

export default WorldConnect;
