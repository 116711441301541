import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setActiveSideBar,
  setSelectedDep,
  setSelectedRoleR,
} from "../../../redux/slices/AdminDashboardSlice";
import TablePagination from "../../../components/Common/Admin/TablePagination/TablePagination";
import TableError from "../../../components/Common/Admin/TabelError/TableError";
import APIPvt from "../../../apiPvt";
import "./index.css";

const AdminDepartment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [msg, setMsg] = useState("");
  const [listingData, setListingData] = useState([]);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState({
    label: "",
    value: null,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const navHandler = () => {
    dispatch(setSelectedDep({ id: null, name: "" }));

    dispatch(setSelectedRoleR({ id: null, name: "" }));
    dispatch(
      setActiveSideBar({
        id: "2",
        name: "Department",
        value: "Create Department",
      })
    );
    navigate("/admin-system-user");
  };

  const onSearchChange = (value) => {
    setSearch(value);
  };

  const onStatusClick = (label, value) => {
    setSelectedStatus({
      label: label,
      value: value,
    });
  };

  const onPageChange = (page) => {
    if (page >= 1 && page <= totalPages) setCurrentPage(page);
  };

  const onCreateRoleClick = (id, name) => {
    dispatch(
      setSelectedDep({
        id: id,
        name: name,
      })
    );
    dispatch(
      setActiveSideBar({
        id: "2",
        name: "Roles",
        value: "Create Role",
      })
    );
    navigate("/admin-system-user");
  };

  const getDepartmentListingData = async () => {
    try {
      let params = `?`;
      if (search !== "") {
        params += `search=${search}&`;
      }
      if (selectedStatus?.value !== null) {
        params += `status=${selectedStatus?.value}&`;
      }
      if (currentPage !== null) {
        params += `page=${currentPage}&`;
      }
      setMsg("");
      await APIPvt.get(`/departments${params}`)
        .then((response) => {
          if (response?.status === 200 || response?.success === true) {
            let res = response?.data;
            setListingData(res?.data);
            setStatus(res?.status);
            setTotalPages(res?.last_page);
            if (
              res?.data?.length === 0 &&
              (search !== "" || selectedStatus?.value !== null)
            )
              setMsg("Please make another selection. No record found.");
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDepartmentListingData();
  }, [selectedStatus, search, currentPage]);

  return (
    <div className="page-space bg-clr-admin">
      <div className="page-title-heading">
        <h2>Department</h2>
      </div>{" "}
      <button
        className="create-department"
        type="text"
        onClick={() => navHandler()}
      >
        Create Department +
      </button>
      <div className="filter-search-bar">
        <div className="col-left-adminfilter">
          <p className="filter-label-admin">Status</p>
          <div className="admin-status-filter">
            <div
              id="dropdown-boxId2"
              className="company-form-dropdownBox1"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div className="text-filter-admin">
                <p
                  className={` ${
                    selectedStatus?.label !== "" && "text-filter-select"
                  }`}
                >
                  {" "}
                  {selectedStatus?.label === ""
                    ? "Status"
                    : selectedStatus?.label}
                </p>
                <img src="/image/admin-d.png" alt="" />
              </div>
            </div>

            {status?.length > 0 && (
              <div className="admin-status-dropdown">
                <ul className="list-job-title-company">
                  {status?.map((item, index) => (
                    <li
                      onClick={() => {
                        onStatusClick(item?.label, item?.value);
                      }}
                    >
                      {item?.label}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="col-right-searcgadmin">
          <div className="search-admin">
            <img src="/image/job-search.png" alt="" />
            <input
              type="text"
              name="search"
              id="jon-search-box"
              placeholder="Search"
              className="form-font-f"
              onChange={(e) => {
                onSearchChange(e?.target?.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="department-table-imge">
        <div className="col-left-table-depart">
          <div className="table-responsive">
            <table className="depart-table-left">
              <thead className="tb-heading-deprt">
                <tr>
                  <th scope="col">
                    <div className="th-admin-heading">Department Name</div>
                  </th>
                  <th scope="col">
                    <div className="th-admin-heading">Status</div>
                  </th>
                  <th scope="col">
                    <div className="th-admin-heading">Actions</div>
                  </th>
                </tr>
              </thead>
              <tbody className="tb-admin">
                {listingData?.length > 0 &&
                  listingData?.map((item, index) => (
                    <tr>
                      <td className="deprt-left-td">
                        <div className="name-sr-circle">
                          <span className="later-circle">
                            {item?.name?.charAt(0)}
                          </span>
                          {item?.name}
                        </div>
                      </td>
                      <td>
                        <div
                          className={`active-tb-data ${
                            item?.status === 0 && "inactive-tb-data"
                          }`}
                        >
                          {" "}
                          {item?.status === 0 ? "Inactive" : "Active"}
                        </div>
                      </td>
                      <td>
                        <div className="four-role-img">
                          <img
                            src="/image/role-d.png"
                            alt=""
                            className="show-tooltip icon-cursor"
                          />
                          <img
                            src="/image/role-c-d.png"
                            alt=""
                            className="show-tooltip1 icon-cursor"
                            onClick={() =>
                              onCreateRoleClick(item?.id, item?.name)
                            }
                          />
                          <img
                            src="/image/role-edit.png"
                            alt=""
                            className="show-tooltip2 icon-cursor"
                          />
                          <img
                            src="/image/role-delet.png"
                            alt=""
                            className="show-tooltip3 icon-cursor"
                          />
                          <div className="tooltip-view-role">View Roles</div>
                          <div className="tooltip-crete-role">Create Role</div>
                          <div className="tooltip-edit-role">Edit</div>
                          <div className="tooltip-delete-role">Delete</div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {listingData?.length > 0 && (
            <TablePagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={onPageChange}
            />
          )}
          <TableError msg={msg} />
        </div>
        <div className="col-right-image-depart">
          <img src="/image/right-depart.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default AdminDepartment;
