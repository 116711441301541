import {useEffect} from "react";
import "./PostJobZoeModel.css";
import OutsideClickHandler from "react-outside-click-handler";
import {useDispatch, useSelector} from "react-redux";
import {closeZoeModal} from "../../../redux/slices/JobPostZoeModalSlice";
import {changeJobDecisionToZoe, clearJobDecision} from "../../../redux/slices/JobDecisionSlice";
import login from "../../../pages/Auth/Login";


const PostJobZoeModel = ({ handleNoButton }) => {
  const dispatch = useDispatch();

  const handleNoButtonClicked = async () => {
    await handleNoButton();
  }

  return (
    <section className="model-popup-post-job">
      <div className="popup-post-job">
        <div className="popup-content-zoe-chek">
          <div className="logo-site">
            <a href="#">
              <img src="/image/WORKBRIDGE.png" alt="" />
            </a>
          </div>
          <OutsideClickHandler
            onOutsideClick={() => {
              dispatch(closeZoeModal());
            }}
          >
            <div className="col-job-zoecard">
              <p className="zeo-chek-text">
                Do you want <span>Zoe</span> to check your job description for{" "}
                <strong>Diversity, Equity</strong> and{" "}
                <strong>Inclusion</strong> and recommend changes
              </p>
              <img src="/image/zeo-eye.png" alt="" />
            </div>
            <div className="zeo-btn">
              <button
                className="zeo-nobtn"
                type="submit"
                onClick={async () => {
                  dispatch(closeZoeModal());
                  await handleNoButtonClicked();
                }}
              >
                No
              </button>
              <button
                className="zeo-yesbtn"
                type="submit"
                onClick={() => {
                  dispatch(changeJobDecisionToZoe());
                  dispatch(closeZoeModal());
                }}
              >
                Yes
              </button>
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    </section>
  );
};

export default PostJobZoeModel;
