import React from "react";
import InputMask from "react-input-mask";

const PhoneNumber = ({
  className,
  id,
  name,
  type,
  value,
  onChange,
  onBlur,
}) => {
  return (
    <InputMask
      id={id}
      mask="(999) 99999999"
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    >
      {(inputProps) => <input className={className} id={id} type={type} placeholder="(___) ________" {...inputProps} />}
    </InputMask>
  );
};

export default PhoneNumber;
