import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setSelectJobId } from "../../../redux/slices/JobDetailSlice";
import { setLoginModel } from "../../../redux/slices/AuthSlice";
import {
  jobAppliedAsync,
  setJobAppliedId,
} from "../../../redux/slices/candidateDashboardSlice";
import getDays from "../../../utils/getDays";
import AddToWishlist from "./AddToWishlist";
import "./JobRecord.css";
import { USER_TYPES } from "../../../constants";

const JobRecordRight = ({ selectedJobId, date, isApplied, wishList }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toggleApplied, setToggleApplied] = useState(false);
  const { user, isEmailVerify } = useSelector((state) => state.auth);

  const onDetailJobHandler = () => {
    navigate(`/job-view-detail/${selectedJobId}`);
  };

  const applyNowHandler = () => {
    if (isEmailVerify === false) {
      dispatch(setLoginModel(true));
    } else {
      dispatch(jobAppliedAsync({ userId: user?.id, jobId: selectedJobId }))
        .then((response) => {
          const res = response?.payload;
          if (res?.result === true) {
            dispatch(setJobAppliedId(selectedJobId));
            setToggleApplied(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className="job-announcements">
      <div className="job-announcements-col btn-size">
        <a onClick={() => onDetailJobHandler()}>
          <button>View detailed Job Position</button>
        </a>
        <div className="job-time-wishlist">
          <div className="job-announcements-status">
            <p>{date ? getDays(date) + " Days ago" : "21 Days ago"}</p>
          </div>
          {isEmailVerify && user?.user_type === "employer" ? (
            ""
          ) : (
            <>
              <div
                className="job-apply icon-cursor"
                onClick={() => applyNowHandler()}
              >
                <img src="/image/apply.png" alt="apply" />
                <p>
                  {isApplied === 1
                    ? "Applied"
                    : toggleApplied
                    ? "Applied"
                    : "Apply Now"}
                </p>
              </div>
              <AddToWishlist jobId={selectedJobId} wishlist={wishList} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobRecordRight;
