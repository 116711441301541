import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import JobHead from "../../../components/Job/JobHead/JobHead";
import JobOtherQuesation from "../../../components/Job/JobQuesation/JobOtherQuestion";
import JobCoreQuestion from "../../../components/Job/JobQuesation/JobCoreQuestion";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";
import LoginModel from "../../../components/Common/LoginModel/LoginModel";
import API from "../../../api";
import "./index.css";

const JobQuesation = () => {
  const UrlParams = useParams();
  const { user } = useSelector((state) => state.auth);
  const [jobDetail, setJobDetail] = useState([]);

  const [skillBased, setSkillBased] = useState([]);
  const [cultureFit, setCultureFit] = useState([]);
  const [coreArea, setCoreArea] = useState([]);

  useEffect(() => {
    async function getJobQuesation() {
      if (UrlParams?.id !== null) {
        let params = `?`;
        if (user !== null) {
          params += `user_id=${user?.id}&`;
        }
        if (UrlParams?.id !== null) {
          params += `jobId=${UrlParams?.id}`;
        }
        try {
          await API.get(`/job-board/job-sample-questions${params}`)
            .then((response) => {
              if (response?.status === 200 || response?.status === 201) {
                let res = response?.data?.data;
                let interview = response?.data?.data?.interview_qa;
                setJobDetail(res?.jobDetail);

                setSkillBased(interview?.skill_based);
                setCultureFit(interview?.culture_fit);
                setCoreArea(interview?.core_area);
              }
            })
            .catch((error) => {
              if (error) {
                console.log(error);
              }
            });
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log("else");
      }
    }

    getJobQuesation();
  }, []);

  return (
    <>
      <JobHead hideQuesationBtn={true} jobDetail={jobDetail} />
      {skillBased?.length > 0 ||
      cultureFit?.length > 0 ||
      coreArea?.length > 0 ? (
        <section className="question-sec">
          <div className="container container-site">
            <JobOtherQuesation
              label="Technical Skills Questions"
              data={skillBased}
            />
            <JobOtherQuesation
              label="Cultural Fit Questions"
              data={cultureFit}
            />
            <JobCoreQuestion label={"Core Area Questions"} data={coreArea} />
          </div>
        </section>
      ) : (
        <></>
      )}

      <WorldConnect />
      <LoginModel />
    </>
  );
};

export default JobQuesation;
