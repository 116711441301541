import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { EmployerRoutes } from "./EmployerRoutes";
import { CandidateRoutes } from "./CandidateRoutes";
import { AdminRoutes } from "./AdminRoutes";
import { PublicRoutes } from "./PublicRoutes";
import ScrollToTop from "../utils/ScrollToTop";
import Home from "../pages/Home";
import JobSearch from "../pages/Job/JobSearch";
import ViewDetail from "../pages/Job/ViewDetail";
import CompanyDetail from "../pages/Job/CompanyDetail";
import JobQuesation from "../pages/Job/JobQuesation";
import Login from "../pages/Auth/Login";
import SignUp from "../pages/Auth/SignUp";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import ChangePassword from "../pages/Auth/ChangePassword";

/*candidate*/
import CanDashboard from "../pages/Candidate/Dashboard";
import CanProfile from "../pages/Candidate/Profile";
import CanWishlist from "../pages/Candidate/Wishlist";

/*Employer*/
import EmpDashboard from "../pages/Employer/Dashboard";
import EmpProfile from "../pages/Employer/Profile";
import EmpCandidateList from "../pages/Employer/List/EmpCandidateList";
import EmpFavouritesList from "../pages/Employer/List/EmpFavouritesList";

/*Admin*/
import AdminDashboard from "../pages/Admin/Dashboard";
import AdminDepartment from "../pages/Admin/Department";
import AdminRole from "../pages/Admin/Role";
import AdminUser from "../pages/Admin/User";
import AdminSystemUser from "../pages/Admin/SystemUser";


const CustomRouter = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route path="/" element={<Home />} />
          <Route path="/company-detail/:id" element={<CompanyDetail />} />
        </Route>

        <Route element={<PublicRoutes findJobHide={true} />}>
          <Route path="/job-search" element={<JobSearch />} />
          <Route path="/job-view-detail/:id" element={<ViewDetail />} />
          <Route path="/job-quesation/:id" element={<JobQuesation />} />
        </Route>

        <Route element={<PublicRoutes findJobHide={true} loginHide={true} />}>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/change-password" element={<ChangePassword />} />
        </Route>

        <Route
          element={
            <PublicRoutes
              findJobHide={true}
              loginHide={true}
              signUpHide={true}
            />
          }
        >
          <Route path="/signup" element={<SignUp />} />
        </Route>

        <Route element={<CandidateRoutes />}>
          <Route path="/candidate-dashboard" element={<CanDashboard />} />
          <Route path="/candidate-wishlist" element={<CanWishlist />} />
        </Route>

        <Route element={<CandidateRoutes findJobHide={true} />}>
          <Route path="/candidate-profile" element={<CanProfile />} />
        </Route>

        <Route element={<EmployerRoutes toggleAuthHeader={true} />}>
          <Route path="/employer-dashboard" element={<EmpDashboard />} />
          <Route path="/employer-profile" element={<EmpProfile />} />
          <Route
            path="/employer-Favourit-list"
            element={<EmpFavouritesList />}
          />
          <Route
            path="/employer-candidate-list"
            element={<EmpCandidateList />}
          />
        </Route>

        <Route element={<AdminRoutes />}>
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/admin-department" element={<AdminDepartment />} />
          <Route path="/admin-role" element={<AdminRole />} />
          <Route path="/admin-user" element={<AdminUser />} />
          <Route path="/admin-system-user" element={<AdminSystemUser />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default CustomRouter;
