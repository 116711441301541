import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  candidateListId: null,
  candidateStatuses: null,
};
const employerDashboardSlice = createSlice({
  name: "employer",
  initialState,
  reducers: {
    setCandidateListId: (state, action) => {
      state.candidateListId = action.payload;
    },
    setCandidateStatues: (state, action) => {
      state.candidateStatuses = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCandidateListId, setCandidateStatues } =
  employerDashboardSlice.actions;

export default employerDashboardSlice.reducer;

// asynchronous function with createAsyncThunk
export const getSystemFieldAsync = createAsyncThunk(
  "public/getSystemField",
  async ({ key }) => {
    try {
      const response = await API.get(`/get-system-field-values?key=${key}`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  },
);

export const favoriteCandidateAsync = createAsyncThunk(
  "employer/favorite",
  async ({ userId, accountId, jobId }) => {
    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("candidate_id", accountId);
    formData.append("job_id", jobId);

    try {
      const response = await API.post("/favorite-candidate", formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  },
);

export const changeJobStatusAsync = createAsyncThunk(
  "employer/changeJobStatus",
  async ({ jobId }) => {
    let params = `?`;
    if (jobId !== null) {
      params += `job_id=${jobId}`;
    }
    try {
      const response = await API.post(`/job-board/change-job-status${params}`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  },
);

export const getCandidateStatuses = createAsyncThunk(
  "employer/candidateStatuses",
  async () => {
    try {
      const response = await API.get("/get-candidate-statuses");

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      }
      throw new Error("Unexpected response status");
    } catch (error) {
      throw error;
    }
  },
);

export const candidateStatusChangeAsync = createAsyncThunk(
  "employer/changeCandidateStatus",
  async ({ candidateId, statusId, jobId }) => {
    try {
      const response = await API.get(
        `/change-candidate-status?candidate_id=${candidateId}&status_id=${statusId}&job_id=${jobId}`,
      );
      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  },
);

export const candidateFirstImpressionChangeAsync = createAsyncThunk(
  "employer/changeCandidateFirstImpression",
  async ({ candidateId, jobId, impressionId }) => {
    try {
      const response = await API.get(
        `/add-remove-impression?candidate_id=${candidateId}&job_id=${jobId}&impression_id=${impressionId}`,
      );
      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  },
);

export const changeCandidateStatusToResumeReviewedAsync = createAsyncThunk(
  "employer/changeCandidateStatusToResumeReviewed",
  async ({ candidateId, jobId }) => {
    const formData = new FormData();
    formData.append("candidate_id", candidateId);
    formData.append("job_id", jobId);
    try {
      const response = await API.post("/job-board/resume-reviewed", formData);
      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  },
);
