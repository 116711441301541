import { useDispatch, useSelector } from "react-redux";
import { setActiveSideBar } from "../../../redux/slices/AdminDashboardSlice";
import AdminCreateDepartment from "./CreateDepartment";
import AdminCreateRole from "./CreateRole";
import AdminCreateUser from "./CreateUser";

const AdminSystemUserMain = () => {
  const dispatch = useDispatch();
  const { activeSideBar } = useSelector((state) => state.adminDashboard);

  return (
    <>
      <div className="step-progressbar-admin">
        <ul className="progressbar-admin">
          <li className={`step-circle active active-circle `}>
            Create Department
          </li>
          <li
            className={`step-circle ${
              (activeSideBar?.value === "Create Role" ||
                activeSideBar?.value === "Create User") &&
              "active active-circle"
            }`}
          >
            Create Role
          </li>
          <li
            className={`step-circle ${
              activeSideBar?.value === "Create User" && "active active-circle"
            }`}
          >
            Create User
          </li>
        </ul>
      </div>
      {activeSideBar?.value === "Create Department" ? (
        <AdminCreateDepartment />
      ) : activeSideBar?.value === "Create Role" ? (
        <AdminCreateRole />
      ) : activeSideBar?.value === "Create User" ? (
        <AdminCreateUser />
      ) : (
        <></>
      )}
    </>
  );
};

export default AdminSystemUserMain;
