import TopNavBar from "./TopNavBar/TopNavBar";
import Footer from "./Footer/Footer";


const Layout = ({ findJobHide, loginHide, signUpHide, toggleAuthHeader, isLogged, children }) => {
  return (
    <div className="all-page-bg-clr">
      <TopNavBar
        findJobHide={findJobHide}
        loginHide={loginHide}
        signUpHide={signUpHide}
        toggleAuthHeader={toggleAuthHeader}
        isLogged={isLogged}
      />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
