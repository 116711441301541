import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FindJob from "../../../components/Common/FindJob/FindJob";
import MostSearchJob from "../../../components/Common/MostSearchJob/MostSearchJob";
import TopMatchContainer from "../../../components/Job/JobSearch/TopMatch/TopMatchContainer";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";
import LoginModel from "../../../components/Common/LoginModel/LoginModel";
import { setMostSearchJob, setSearchWord } from "../../../redux/slices/JobSearchSlice";
import API from "../../../api";

const JobSearch = () => {
  const { user } = useSelector((state) => state.auth);
  const {
    searchWord,
    jobTypeId,
    selectJobType,
    jobLocationId,
    dateId,
    selectJobLocation,
    mostSearchJob
  } = useSelector((state) => state.jobSearch);

  const dispatch = useDispatch();
  const [mostSearchedJobs, setMostSearchedJobs] = useState([]);
  const [featuredJobs, setFeaturedJobs] = useState([]);
  const [topMatchJobs, setTopMatchJobs] = useState([]);

  const [startPage, setStartPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(1);

  const findJobHandler = () => {
    getTopMatchJob();
  };

  const onCurrentPageHandler = (value) => {
    setCurrentPage(value);
    getTopMatchJob(value);
  };

  const onMostSearchjobClick = (value) => {
    dispatch(setSearchWord(value));
    dispatch(setMostSearchJob(!mostSearchJob));
    getTopMatchJob();
  };

  const getTopMatchJob = async (currentPage) => {
    try {
      let params = `?`;
      if (user !== null) {
        params += `user_id=${user?.id}&`;
      }
      if (searchWord !== "") {
        params += `searchWord=${searchWord}&`;
      }
      if (jobTypeId !== null) {
        params += `jobTypeId=${jobTypeId}&`;
      }
      if (jobLocationId !== null) {
        params += `jobLocationId=${jobLocationId}&`;
      }
      if (dateId !== null) {
        params += `datePosted=${dateId}&`;
      }
      if( user?.id !== null ){
        params += `user_id=${user?.id}&`;
      }
      params += `page=${currentPage}`;

      await API.get(`/job-board/top_matched_jobs${params}`)
        .then((response) => {
          if (response?.status === 200) {
            let res = response?.data;
            let total = res?.total;
            let end = 1;
            if (total >= perPage) {
              end = total / perPage;
              setEndPage(Math.ceil(end));
            } else {
              setEndPage(Math.ceil(end));
            }

            setTopMatchJobs(res?.data);
            setCurrentPage(res?.current_page);
            setTotal(res?.total);
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTopMatchJob(currentPage);
  }, [mostSearchJob]);

  useEffect(() => {
    getTopMatchJob(currentPage);
  }, []);

  useEffect(() => {
    async function getFindJob() {
      try {
        let params = `?`;
        params += user?.id !== null ? `user_id=${user?.id}&` : '';
        await API.get(`/job-board/find_job${params}`)
          .then((response) => {
            if (response?.status === 200) {
              let res = response?.data;
              setFeaturedJobs(res?.featuredJobs);
              setMostSearchedJobs(res?.mostSearchedJobs);
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
    getFindJob();
  }, []);

  return (
    <>
      <FindJob findJobHandler={findJobHandler} />
      <MostSearchJob
        mostSearchedJobs={mostSearchedJobs}
        onMostSearchjobClick={onMostSearchjobClick}
      />
      <TopMatchContainer
        startPage={startPage}
        currentPage={currentPage}
        endPage={endPage}
        featuredJobs={featuredJobs}
        topMatchJobs={topMatchJobs}
        onCurrentPageHandler={onCurrentPageHandler}
      />
      <WorldConnect />
      <LoginModel />
    </>
  );
};

export default JobSearch;
