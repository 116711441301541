import React, { useState } from "react";
import { useSelector } from "react-redux";

const JobOtherQuestion = ({ label, data }) => {
  const { isEmailVerify, user } = useSelector((state) => state.auth);

  const [mainCollapseOpen, setMainCollapseOpen] = useState(true);

  if (data?.length === 0) return null;

  return (
    <div className="question-tabs">
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className={`accordion-button q-tabs ${
                mainCollapseOpen ? "" : "collapsed"
              }`}
              type="button"
              onClick={() => setMainCollapseOpen(!mainCollapseOpen)}
            >
              {label}
            </button>
          </h2>
          <div
            id="collapseOne"
            className={`accordion-collapse collapse ${
              mainCollapseOpen ? "show" : ""
            }`}
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            {data?.length > 0 &&
              data?.map((item, index) => (
                <div className="accordion-body" key={index}>
                  <div className="accordion" id="sub-accordionExample">
                    <div className="accordion-item">
                      <h2
                        className="accordion-header sub-header"
                        id={`sub-headingOne-${index}`}
                      >
                        <button
                          className="accordion-button sub-heading"
                          type="button"
                          //  data-bs-toggle="collapse"
                          //  data-bs-target={`#sub-collapseanswer-${index}`}
                          //  aria-expanded="true"
                          //  aria-controls={`sub-collapseanswer-${index}`}
                        >
                          Question {index + 1}:
                          <span className="q-text-s">{item?.question}</span>
                        </button>
                      </h2>
                      {isEmailVerify && user?.user_type === "employer" && (
                        <div
                          id={`sub-collapseanswer-${index}`}
                          className="accordion-collapse collapse show"
                          aria-labelledby={`sub-headingOne-${index}`}
                          data-bs-parent="#sub-accordionExample"
                        >
                          <div className="accordion-body side-space">
                            <h2
                              className="accordion-header sub-header tab-space-tb"
                              id={`sub-headingOne-${index}`}
                            >
                              <button
                                className="accordion-button queation-answer-tab"
                                type="button"
                                //   data-bs-toggle="collapse"
                                //   data-bs-target={`#sub-headingOne-${index}`}
                                //   aria-expanded="true"
                                //   aria-controls={`sub-headingOne-${index}`}
                              >
                                Answer:
                                <span className="q-text-ans">
                                  {item?.answer}
                                </span>
                              </button>
                            </h2>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobOtherQuestion;
