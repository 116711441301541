import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ApplicantsTable from "../../../components/Common/ApplicantsTable/ApplicantsTable";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";
import ResumeModel from "../../../components/Common/ResumeModel/ResumeModel";
import CommentModel from "../../../components/Common/CommentModel/CommentModel";
import API from "../../../api";
import {
  candidateFirstImpressionChangeAsync,
  candidateStatusChangeAsync,
  changeCandidateStatusToResumeReviewedAsync,
  favoriteCandidateAsync,
} from "../../../redux/slices/employerDashboardSlice";
import { FvtData } from "../../../data/employer";
import "./index.css";
import { CANDIDATE_STATUSES } from "../../../constants";
import {
  addJobCommentAsync,
  emptyCommentsModalData,
  setCandidateId,
  setJobId,
} from "../../../redux/slices/commentModalSlice";

const EmpFavouritesList = () => {
  const dispatch = useDispatch();
  const { candidateStatuses } = useSelector((state) => state.employerDashboard);
  const { user } = useSelector((state) => state.auth);
  const [count, setCount] = useState(10);
  const [toggleModel, setToggleModel] = useState(false);
  const [toggleModelC, setToggleModelC] = useState(false);
  const [search, setSearch] = useState("");
  const [jobTitles, setJobTitles] = useState([]);
  const [selectedJobTitile, setSelectedJobTitile] = useState({
    jobId: null,
    jobTitle: "",
  });
  const [favoriteApplicants, setFavoriteApplicants] = useState([]);

  const commentRemoved = (commentId, commentToReplace) => {
    const updateFavoriteApplicants = favoriteApplicants.map((applicant) => {
      if (applicant?.comments?.id === commentId) {
        const newComment = {
          ...commentToReplace,
          comments_count: (applicant?.comments?.comments_count > 0 ) ? applicant?.comments?.comments_count  - 1 : 0,
        };

        if (commentToReplace === "") {
          return {
            ...applicant,
            comments: "",
          };
        }

        return {
          ...applicant,
          comments: {
            ...newComment,
          },
        };
      }
      return applicant;
    });
    setFavoriteApplicants(updateFavoriteApplicants);
  };

  const commentAdded = (candidateId, jobId, comment) => {
    const updateFavoriteApplicants = favoriteApplicants.map((applicant) => {
      if (
        applicant?.account_id === candidateId &&
        applicant?.job_id === jobId
      ) {
        const newComment = {
          ...comment,
          comments_count: applicant?.comments?.comments_count + 1,
        };

        return {
          ...applicant,
          comments: {
            ...newComment,
          },
        };
      }
      return applicant;
    });
    setFavoriteApplicants(updateFavoriteApplicants);
  };

  const handleNewCommentSubmit = async (text, jobId, candidateId) => {
    if (text === null || text === undefined || text === "") return;
    if (jobId === null || jobId === undefined || jobId === "") return;
    if (candidateId === null || candidateId === undefined || candidateId === "")
      return;

    dispatch(
      addJobCommentAsync({
        userId: user?.id,
        jobId: jobId,
        candidateId: candidateId,
        commentText: text,
      }),
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedFavoriteApplicants = favoriteApplicants.map(
            (applicant) => {
              if (
                applicant?.account_id === candidateId &&
                applicant?.job_id === jobId
              ) {
                return {
                  ...applicant,
                  comments: {
                    ...res?.data[0]?.comments[0],
                    comments_count: 0,
                  },
                };
              }
              return applicant;
            },
          );
          setFavoriteApplicants(updatedFavoriteApplicants);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const modelCHandler = (v, candidate_id, job_id) => {
    setToggleModelC(v);

    if (v === false) {
      dispatch(emptyCommentsModalData());
      return;
    }
    if (
      candidate_id === null ||
      candidate_id === undefined ||
      candidate_id === "" ||
      job_id === null ||
      job_id === undefined ||
      job_id === ""
    ) {
      setToggleModelC(false);
      return;
    }

    dispatch(setJobId(job_id));
    dispatch(setCandidateId(candidate_id));
  };
  const modelHandler = (v) => setToggleModel(v);

  const countHandler = () => {
    if (count < favoriteApplicants?.length)
      setCount((prevCount) => prevCount + 10);
  };

  const onSearchChange = (value) => {
    setSearch(value);
  };

  const onJobTitleClick = (job_id, job_title) => {
    setSelectedJobTitile({
      jobId: job_id,
      jobTitle: job_title,
    });
  };

  const onJobTitleCean = () => {
    setSelectedJobTitile({
      jobId: null,
      jobTitle: "",
    });
  };

  const onResumeClickHandler = async (candidateId, jobId, currentStatus) => {
    if (currentStatus !== CANDIDATE_STATUSES.NEW_APPLICANT) return;

    dispatch(
      changeCandidateStatusToResumeReviewedAsync({
        candidateId: candidateId,
        jobId: jobId,
      }),
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedApplicants = favoriteApplicants.map((applicant) => {
            if (
              applicant?.account_id === candidateId &&
              applicant?.job_id === jobId
            ) {
              return {
                ...applicant,
                status_id: res?.status_id,
                status: res?.status,
              };
            }
            return applicant;
          });
          setFavoriteApplicants(updatedApplicants);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onFirstImpressionClick = async (
    candidateId,
    jobId,
    impressionId,
    CurrentStatusId,
  ) => {
    if (CurrentStatusId === CANDIDATE_STATUSES.NEW_APPLICANT) return;
    if (candidateId === null || jobId === null || impressionId === null) return;

    dispatch(
      candidateFirstImpressionChangeAsync({
        candidateId: candidateId,
        jobId: jobId,
        impressionId: impressionId,
      }),
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedApplicants = favoriteApplicants.map((applicant) => {
            if (
              applicant?.account_id === candidateId &&
              applicant?.job_id === jobId
            ) {
              return {
                ...applicant,
                impression_id: res?.impression_removed === 1 ? 0 : impressionId,
              };
            }
            return applicant;
          });
          setFavoriteApplicants(updatedApplicants);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onCandidateStatusChange = async (
    candidateId,
    jobId,
    statusId,
    currentStatusId,
  ) => {
    if (candidateId === null || jobId === null || statusId === null) return;
    if (currentStatusId === statusId) return;
    dispatch(
      candidateStatusChangeAsync({
        candidateId: candidateId,
        statusId: statusId,
        jobId: jobId,
      }),
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedApplicants = favoriteApplicants.map((applicant) => {
            if (
              applicant?.account_id === candidateId &&
              applicant?.job_id === jobId
            ) {
              const { id, status } = candidateStatuses.find(
                (item) => item.id === statusId,
              );

              return {
                ...applicant,
                status_id: id,
                status: status,
              };
            }
            return applicant;
          });
          setFavoriteApplicants(updatedApplicants);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onFvtClick = async (account_id, job_id) => {
    dispatch(
      favoriteCandidateAsync({
        userId: user?.id,
        accountId: account_id,
        jobId: job_id,
      }),
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedFavoriteApplicants = favoriteApplicants?.filter(
            (favorite) =>
              favorite?.job_id !== job_id &&
              favorite?.account_id !== account_id,
          );
          setFavoriteApplicants(updatedFavoriteApplicants);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getEmpFvtApplicantsData = async () => {
    if (user?.id) {
      try {
        let params = `?`;
        if (user !== null) {
          params += `user_id=${user?.id}&`;
        }
        if (selectedJobTitile?.jobId !== null) {
          params += `job_id=${selectedJobTitile?.jobId}&`;
        }
        if (search !== "") {
          params += `search=${search}&`;
        }

        await API.get(`/get-favorite-candidates${params}`)
          .then((response) => {
            if (
              response?.status === 200 ||
              response?.status === 201 ||
              response?.status === 304 ||
              response?.status === 401
            ) {
              let res = response?.data;
              if (res?.result === true) {
                setFavoriteApplicants(res?.favorite_applicants);
                setJobTitles(res?.job_titles);
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getEmpFvtApplicantsData();
  }, [search, selectedJobTitile]);

  return (
    <>
      <section className="new-job">
        <div className="container container-site">
        <div className="space-inner-row">
          <div className="job-search-box-row">
            <div className="job-heading smilar-job-heading">
              <h2>Favourites</h2>
            </div>
            </div>
          </div>
          <div className="employee-searchbox-btn">
            <div className="search-list-box">
              <div className="filter-search-box">
                <img src="/image/job-search.png" alt="" />
                <input
                  type="text"
                  name="search"
                  id="jon-search-box"
                  placeholder="Search Applicants"
                  className="form-font-f"
                  onChange={(e) => {
                    onSearchChange(e?.target?.value);
                  }}
                />
              </div>
              <div className="data-not-found hide-notfound">
                <img src="/image/notfound.png" alt="" />
                <p className="search-text-notfound">No candidate found</p>
              </div>
              {/* <div className="list-search-dropdown">
                <ul className="list-job-title">
                  <li>React Developer</li>
                  <li>React Developer</li>
                  <li>React Developer</li>
                  <li>React Developer</li>
                </ul>
              </div> */}
            </div>
          </div>
          <div className="divider-opcity "></div>
          <div className="j-fillter-only">
            <div className="job-fillter-company">
              <div className="j-company-fillter">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-icon">
                    <p>
                      {" "}
                      {selectedJobTitile?.jobTitle === ""
                        ? "Job Title"
                        : selectedJobTitile?.jobTitle?.length >= 20
                        ? selectedJobTitile?.jobTitle?.substr(0, 20) + " ..."
                        : selectedJobTitile?.jobTitle}
                    </p>
                    {selectedJobTitile?.jobTitle === "" ? (
                      <img src="/image/company--detail-filter.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-b.png"
                          alt=""
                          onClick={() => onJobTitleCean()}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {jobTitles?.length > 0 && (
                  <div className="list-search-dropdown-company">
                    <ul className="list-job-title-company">
                      {jobTitles?.map((j, i) => (
                        <li
                          key={i}
                          className="icon-cursor"
                          onClick={() => {
                            onJobTitleClick(j?.job_id, j?.job_title);
                          }}
                        >
                          {j?.job_title}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="divider"></div>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th scope="col">Full name</th>
                <th scope="col">Job Title</th>
                <th scope="col">Date Applied</th>
                <th scope="col">First Impression</th>
                <th scope="col" className="technical-tags-row">
                  Technical Tags:
                </th>
                <th scope="col">Candidate Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {favoriteApplicants?.length > 0 &&
                favoriteApplicants
                  ?.slice(0, count)
                  ?.map((item, index) => (
                    <ApplicantsTable
                      key={index}
                      index={index}
                      item={item}
                      candidateStatusesList={candidateStatuses}
                      onFvtClick={onFvtClick}
                      modelHandler={modelHandler}
                      modelCHandler={modelCHandler}
                      onFirstImpressionClick={onFirstImpressionClick}
                      onCandidateStatusChangeHandler={onCandidateStatusChange}
                      onResumeClickHandler={onResumeClickHandler}
                      handleAddNewCommentHandler={handleNewCommentSubmit}
                      handleRemovedComment={commentRemoved}
                    />
                  ))}
            </tbody>
          </table>
        </div>
        {favoriteApplicants?.length > 10 && (
          <div className="see-more-job" onClick={() => countHandler()}>
            <Link href="#">
              <button>See more</button>
            </Link>
          </div>
        )}
      </section>
      <WorldConnect />
      {toggleModel && <ResumeModel modelHandler={modelHandler} />}
      {toggleModelC && (
        <CommentModel
          modelCHandler={modelCHandler}
          commentAdded={commentAdded}
          commentRemoved={commentRemoved}
        />
      )}
    </>
  );
};

export default EmpFavouritesList;
