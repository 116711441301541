import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EmpProfileForm from "../../../components/Employer/Profile/EmpProfileForm";
import EmpProfileView from "../../../components/Employer/Profile/EmpProfileView";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";
import API from "../../../api";
import "./index.css";
import { setLoginUserName } from "../../../redux/slices/AuthSlice";

const EmpProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [editable, setEditable] = useState(false);
  const [baseInfo, setBaseInfo] = useState({
    email: "",
    countryId: null,
    phone: "",
  });

  const [city, setCity] = useState({
    cityName: "",
    cityId: null,
  });

  const [companyInfo, setCompanyInfo] = useState({
    name: "",
    description: "",
    website: "",
    linkedin: "",
    logo: null,
  });

  const editHandler = (editValue) => {
    setEditable(editValue);
  };

  const changePasswordHandler = () => {
    navigate("/forgot-password");
  };

  const submitHandler = async (data) => {
    const {
      files,
      city,
      cityId,
      phoneNo,
      linkedinURL,
      companyName,
      companyInfo,
      websiteUrl,
      linkedin,
    } = data;

    try {
      const formData = new FormData();
      formData.append("id", user?.id);
      formData.append("city_id", cityId);
      formData.append("website_url", websiteUrl);
      formData.append("phone", phoneNo);
      formData.append("linkedin", linkedinURL);
      formData.append("company_detail", companyInfo);
      files !== null && formData.append("company_logo", files);

      setLoader(true);
      await API.post("/auth/update-employer-profile", formData)
        .then((response) => {
          if (response?.status === 200 || response?.status === 201) {
            const res = response?.data;

            if (res?.result === true) {
              getUserProfile();
              setEditable(false);
              setErrorMsg(res?.message?.success);
            }
            setLoader(false);
          }
        })
        .catch((error) => {
          if (error) {
            const { response } = error;
            const { data } = response;
            setErrorMsg(data?.message);
            setTimeout(() => {
              setErrorMsg("");
            }, 5000);
            console.log(error);
          }
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const getUserProfile = async () => {
    if (user?.id) {
      try {
        await API.get(`/auth/get-profile?id=${user?.id}`)
          .then((response) => {
            if (response?.status === 200 || response?.status === 201) {
              let res = response?.data;
              let company = response?.data?.company;
              let city = response?.data?.city;

              dispatch(setLoginUserName(res?.first_name));

              setBaseInfo({
                ...baseInfo,
                email: res?.email,
                phone: res?.phone,
                countryId: res?.country,
              });

              setCompanyInfo({
                name: company[0]?.name,
                description: company[0]?.description,
                website: company[0]?.website,
                linkedin: res?.linkedin,
                logo: company[0]?.logo,
              });

              if (city) {
                setCity({
                  cityName: city[0]?.name,
                  cityId: city[0]?.id,
                });
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <>
      <section className="profile-edit">
        <div className="container container-site">
          <div className="profile-edit-row">
            <div className="profile-heading">
            <h2>My Profile {editable && <span>(Edit)</span>}</h2>
            </div>
            {editable === false && (
              <div
                className="profile-eidt-icon icon-cursor"
                onClick={() => editHandler(true)}
              >
                <img src="/image/profile-edit.png" alt="" />
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="candidate-profile-sec">
        <div className="container container-site">
          <div className="my-profile-row">
            {editable ? (
              <EmpProfileForm
                loader={loader}
                editable={editable}
                baseInfo={baseInfo}
                city={city}
                companyInfo={companyInfo}
                editHandler={editHandler}
                submitHandler={submitHandler}
              />
            ) : (
              <EmpProfileView
                baseInfo={baseInfo}
                city={city}
                companyInfo={companyInfo}
              />
            )}
            <div
              className="candidate-profile-btn"
              onClick={() => changePasswordHandler()}
            >
              <div className="three-stack-btn">
                <button>Change Password</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WorldConnect />
    </>
  );
};

export default EmpProfile;
