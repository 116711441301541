import { useNavigate, Link } from "react-router-dom";

const BaseInfo = ({
  totalApplicants,
  jobListingsCount,
  draftJobsCount,
  favoriteCount,
}) => {
  const navigate = useNavigate();

  const onFavouritListClick = () => {
    if (favoriteCount > 0) navigate("/employer-Favourit-list");
  };

  return (
    <section class="candidate-dashbord-sec">
      <div class="container container-site">
        <div class="space-inner-row">
          <div class="employee-job-dasboard">
            <a href="#new_applicants" class="anchor-cards-job-1 card-1">
              <div class="job-applied-col job-employee-cards">
                <img src="/image/new-applicant.png" alt="" />
                <div class="card-employee-info">
                  <p class="job-title-candidate-dashboard">New Applicants</p>
                  <p class="job-applied-candidate-dashboard">
                    {" "}
                    {totalApplicants}
                  </p>
                </div>
              </div>
            </a>
            <a href="#job_listing" class="anchor-cards-job-2 card-2">
              <div class="job-applied-col job-employee-cards">
                <img src="/image/user-list.png" alt="" />
                <div class="card-employee-info">
                  <p class="job-title-candidate-dashboard">Job Listing</p>
                  <p class="job-applied-candidate-dashboard">
                    {" "}
                    {jobListingsCount}
                  </p>
                </div>
              </div>
            </a>
            <a href="#draft_jobs" class="anchor-cards-job-3 card-3">
              <div class="job-applied-col job-employee-cards">
                <img src="/image/draft-job.png" alt="" />
                <p class="job-title-candidate-dashboard">Draft Jobs</p>
                <p class="job-applied-candidate-dashboard"> {draftJobsCount}</p>
              </div>
            </a>
            <a            
              class="anchor-cards-job-4 card-4 icon-cursor"
              onClick={() => onFavouritListClick()}
            >
              <div class="job-applied-col job-employee-cards">
                <img src="/image/user-register.png" alt="" />
                <p class="job-title-candidate-dashboard">
                  {" "}
                  Favourite Candidates{" "}
                </p>
                <p class="job-applied-candidate-dashboard">{favoriteCount}</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BaseInfo;
