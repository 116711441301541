import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GoogleSession from "./GoogleSession/GoogleSession";
import CandidateForm from "./CandidateForm/CandidateForm";
import ProgressBar from "./EmployerForm/ProgressBar";
import SignUpForm from "./EmployerForm/SignUpForm";
import CreateProfileForm from "./EmployerForm/CreateProfileForm";
import PostJobForm from "./EmployerForm/PostJobForm";
import API from "../../../api";
import {
  setActiveSubTab,
  setActiveTab,
  setEmailVerify,
  setLoginUser,
  setLoginUserName,
  setSignUpEmp,
} from "../../../redux/slices/AuthSlice";
import WelcomeModel from "../../Common/WelcomeModel/WelcomeModel";
import UploadImageModel from "../../Common/UploadImageModel/UploadImageModel";
import PostJobDecisionModel from "../../Common/PostJobDecisionModel/PostJobDecisionModel";
import PostJobZoeModel from "../../Common/PostJobZoeModel/PostJobZoeModel";
import "./index.css";
import "./EmployerForm/EmployerForm.css";
import {closeZoeModal} from "../../../redux/slices/JobPostZoeModalSlice";


const SignUpMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { user, mode, postJobId, isEmailVerify } = useSelector(
    (state) => state.auth
  );

  const {isJobDecisionModalOpen} = useSelector(state => state.jobDecision);
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");

  const { activeTab, activeSubTab, signUpEmp } = useSelector(
    (state) => state.auth
  );

  const {isModalOpen} = useSelector(state => state.jobPostZoe);

  const [loader, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [toggleModel, setToggleModel] = useState(false);
  const [companyLogoModel, setCompanyLogoModel] = useState(false);
  const [employerForm, setEmployerForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    country: null,
    phoneNo: "",
    website_url: "",
    company_name: "",
    company_detail: "",
    company_logo: null,
    linkedin: "",
  });

  const [handleNoButtonPostNow, setHandleNoButtonPostNow] = useState();

  const handleNoButtonClick = async () => {
    await handleNoButtonPostNow();
  };

  const hideModel = () => {
    const timeId = setTimeout(() => {
      if (activeTab === "candidate") navigate("/candidate-dashboard");

      if (activeTab === "employer") {
      }
      dispatch(setActiveSubTab("postJob"));

      setErrorMsg("");
      setToggleModel(false);
      dispatch(setEmailVerify(true));
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  };

  const modelHandler = (value) => {
    setToggleModel(value);
    if (value === true) hideModel();
  };

  const errorMsgHandler = (msg) => {
    setErrorMsg(msg);
  };

  const companyLogoModelHandler = (value) => {
    setCompanyLogoModel(value);
  };

  const companyLogoHandler = (file) => {
    setEmployerForm({
      ...employerForm,
      company_logo: file,
    });
  };

  const CandidateSignUpHandler = async (
    data,
    selectJobTitlesId,
    selectSkillTagsId
  ) => {
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      countryId,
      phoneNo,
      linkedinURL,
      files,
    } = data;
    try {
      const formData = new FormData();
      formData.append("first_name", firstName);
      formData.append("last_name", lastName);
      formData.append("email", email);
      formData.append("phone", phoneNo);
      formData.append("password", password);
      formData.append("password_confirmation", confirmPassword);
      formData.append("country", countryId);
      formData.append("linkedin", linkedinURL);
      formData.append(" terms_of_services", 1);

      files !== null && formData.append("resume", files);

      selectJobTitlesId !== null &&
        Object.keys(selectJobTitlesId).forEach((key) => {
          formData.append("job_title[]", selectJobTitlesId[key]);
        });

      selectSkillTagsId !== null &&
        Object.keys(selectSkillTagsId).forEach((key) => {
          formData.append("tech_tags[]", selectSkillTagsId[key]);
        });

      setLoader(true);
      setErrorMsg("");
      await API.post("/auth/signup-candidate", formData)
        .then((response) => {
          if (response?.status === 200 || response?.status === 201) {
            const res = response?.data;
            const user = response?.data?.user;
            if (res?.result === true) {
              dispatch(setLoginUser(res));
              dispatch(setLoginUserName(user?.first_name));
              localStorage.setItem("token", res?.access_token);          
              setErrorMsg(res?.message?.success);
            }

            setLoader(false);
          }
        })
        .catch((error) => {
          if (error) {
            const { response } = error;
            const { data } = response;
            setErrorMsg(data?.message);
            setTimeout(() => {
              setErrorMsg("");
            }, 5000);
            console.log(error);
          }
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const signUpFormHandler = (data) => {
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      countryId,
      phoneNo,
    } = data;

    setEmployerForm({
      ...employerForm,
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
      password_confirmation: confirmPassword,
      country: countryId,
      phoneNo: phoneNo,
    });
    setErrorMsg("");
    dispatch(setSignUpEmp(data));
    dispatch(setActiveSubTab("createProfile"));
  };

  const createProfileHandler = async (data) => {
    const { websiteUrl, companyName, companyInfo, linkedinURL, files } = data;
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      countryId,
      phoneNo,
    } = signUpEmp;

    setEmployerForm({
      ...employerForm,
      website_url: websiteUrl,
      company_name: companyName,
      company_detail: companyInfo,
      company_logo: files,
      linkedin: linkedinURL,
    });

    try {
      const formData = new FormData();
      formData.append("first_name", firstName);
      formData.append("last_name", lastName);
      formData.append("email", email);
      formData.append("phone", phoneNo);
      formData.append("password", password);
      formData.append("password_confirmation", confirmPassword);
      formData.append("country", countryId);
      formData.append("linkedin", linkedinURL);
      formData.append(
        "website_url",
        websiteUrl.includes("http://") ? websiteUrl : "http://" + websiteUrl
      );
      formData.append("company_name", companyName);
      formData.append("company_detail", companyInfo);
      formData.append(" terms_of_services", 1);
      files !== null && formData.append("company_logo", files);

      setLoader(true);
      setErrorMsg("");
      await API.post("/auth/signup", formData)
        .then((response) => {
          if (response?.status === 200 || response?.status === 201) {
            const res = response?.data;
            const user = response?.data?.user;

            if (res?.result === true) {
              dispatch(setLoginUser(res));
              dispatch(setLoginUserName(user?.first_name));
              dispatch(setSignUpEmp({}));
              localStorage.setItem("token", res?.access_token);          
              setErrorMsg(res?.message?.success);
            }
            setLoader(false);
          }
        })
        .catch((error) => {
          if (error) {
            const { response } = error;
            const { data } = response;
            setErrorMsg(data?.message);
            setTimeout(() => {
              setErrorMsg("");
            }, 5000);
            console.log(error);
          }
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const postAJobHandler = async (data, selectSkillTags, selectCities) => {
    const {
      jobTitle,
      jobPositionId,
      currencyId,
      salaryStart,
      salaryEnd,
      salaryRange,
      jobTypeId,
      JobDes,
      rules,
      skill,
      mustSkill,
      niceToHave,
      benefits,
      workingHours,
      interviewPro,
      recNotes,
      functionalAreaId,
      reportToId,
      companySizeId,
      workMethodologyId,
      status,
    } = data;

    try {
      const formData = new FormData();
      formData.append("user_id", user?.id);
      formData.append("name", jobTitle);
      jobPositionId !== null &&
        formData.append("career_level_id", jobPositionId);
      currencyId !== null && formData.append("currency_id", currencyId);
      salaryStart !== "" && formData.append("salary_from", salaryStart);
      salaryEnd !== "" && formData.append("salary_to", salaryEnd);
      salaryRange !== "" && formData.append("salary_range", salaryRange);
      formData.append("job_types[]", jobTypeId);
      formData.append("description", JobDes);

      formData.append("responsibilities", JSON.stringify(rules));
      formData.append("skills", JSON.stringify(skill));
      formData.append("must_skills", JSON.stringify(mustSkill));
      formData.append("nice_to_have", JSON.stringify(niceToHave));
      formData.append("benefits", JSON.stringify(benefits));
      formData.append("working_hrs", JSON.stringify(workingHours));
      formData.append("interview_process", JSON.stringify(interviewPro));
      formData.append("recruiter_notes", JSON.stringify(recNotes));
      functionalAreaId !== null &&
        formData.append("functional_area_id", functionalAreaId);
      reportToId !== null && formData.append("report_to_id", reportToId);
      formData.append("company_size_id", companySizeId);
      workMethodologyId !== null &&
        formData.append("work_methodology_id", workMethodologyId);
      formData.append("status", status);

      selectSkillTags !== null &&
        Object.keys(selectSkillTags).forEach((key) => {
          formData.append("tags[]", selectSkillTags[key]);
        });
      selectCities !== null &&
        Object.keys(selectCities).forEach((key) => {
          formData.append("cities[]", selectCities[key]);
        });

      setLoader(true);
      const Url =
        mode === "add"
          ? `/job-board/post-a-job`
          : `/job-board/post-a-job/update/${postJobId}`;
      await API.post(Url, formData)
        .then((response) => {
          if (response?.status === 200 || response?.status === 201) {
            const res = response?.data;
            if (res?.success === true) {
              setErrorMsg(res?.message);
              navigate("/employer-dashboard");
            }

            setLoader(false);
          }
        })
        .catch((error) => {
          if (error) {
            const { response } = error;
            const { data } = response;
            setErrorMsg(data?.message);
            setTimeout(() => {
              setErrorMsg("");
            }, 5000);
            console.log(error);
          }
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (code) {
      async function checkEmailVerify() {
        try {
          await API.get(`/auth/check-email-verified/${code}`)
            .then((response) => {
              if (response?.status === 200) {
                let res = response?.data;
                if (res?.result === true) {
                  setErrorMsg(res?.message?.success);
                  modelHandler(true);
                }
              }
            })
            .catch((error) => {
              if (error) {
                console.log(error);
              }
            });
        } catch (error) {
          console.log(error);
        }
      }
      checkEmailVerify();
    }
  }, [code]);

  useEffect(() => {
    dispatch(closeZoeModal());
  }, []);

  return (
    <>
      <section className="candiate-signup">
        <div className="container container-site">
          <div className="candiate-signup-row">
            <div className="candidate-signup-form">
              <div className="candidate-signup">
                <h2>
                  {activeTab === "candidate" ? (
                    <>Create Account</>
                  ) : (
                    <>
                      {activeSubTab === "signUp" && (
                        <>Create Employer Account</>
                      )}
                      {activeSubTab === "createProfile" && (
                        <>Create Company Profile</>
                      )}
                      {activeSubTab === "postJob" && <>Post a Job</>}
                    </>
                  )}
                </h2>
                {isEmailVerify ? (
                  <></>
                ) : (
                  <div className="login-here">
                    <p>
                      Already have account?
                      <Link to="/login">
                        <span> Log in</span>
                      </Link>
                    </p>
                  </div>
                )}

                <div className="candidate-employe-btn">
                  <Link onClick={() => dispatch(setActiveTab("candidate"))}>
                    <button
                      className={
                        activeTab === "candidate"
                          ? "employee-btn toggle-btn-active"
                          : "employee-btn"
                      }
                    >
                      Candidate
                    </button>
                  </Link>
                  <Link
                    onClick={() => {
                      dispatch(setActiveTab("employer"));
                      dispatch(setActiveSubTab("signUp"));
                      dispatch(setSignUpEmp({}));
                    }}
                  >
                    <button
                      className={
                        activeTab === "employer"
                          ? "employee-btn toggle-btn-active"
                          : "employee-btn"
                      }
                    >
                      Employer
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            {activeTab === "candidate" ? (
              <>
                <div className="goole-login-border">
                  {/* <GoogleSession /> */}
                </div>
                <CandidateForm
                  errorMsg={errorMsg}
                  loader={loader}
                  errorMsgHandler={errorMsgHandler}
                  CandidateSignUpHandler={CandidateSignUpHandler}
                />
              </>
            ) : (
              <>
                <div className="goole-login-border">
                  <ProgressBar currentTab={activeSubTab} />
                </div>
                {activeSubTab === "signUp" && (
                  <SignUpForm signUpFormHandler={signUpFormHandler} />
                )}
                {activeSubTab === "createProfile" && (
                  <CreateProfileForm
                    errorMsg={errorMsg}
                    loader={loader}
                    companyLogo={employerForm?.company_logo}
                    companyLogoModelHandler={companyLogoModelHandler}
                    createProfileHandler={createProfileHandler}
                  />
                )}
                {activeSubTab === "postJob" && (
                  <PostJobForm
                    loader={loader}
                    postAJobHandler={postAJobHandler}
                    setHandleNoButtonPostNow={setHandleNoButtonPostNow}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </section>
      {toggleModel && <WelcomeModel modelHandler={modelHandler} />}
      {companyLogoModel && (
        <UploadImageModel
          companyLogoHandler={companyLogoHandler}
          companyLogoModelHandler={companyLogoModelHandler}
        />
      )}{" "}
      { isJobDecisionModalOpen && <PostJobDecisionModel /> }
      { isModalOpen && <PostJobZoeModel handleNoButton={handleNoButtonClick} />}
    </>
  );
};

export default SignUpMain;
