import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import NFormatter from "../../../utils/NFormatter";
import { setSelectJobId } from "../../../redux/slices/JobDetailSlice";
import { setSelectCompanyId } from "../../../redux/slices/companyDetailSlice";
import "./JobRecord.css";

const JobRecordLeft = ({
  activeSwitch,
  radioBtn,
  JobData,
  onChangeJobStatus,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [switchBtn, setSwitchBtn] = useState(false);

  const onSwitchChange = () => {
    setSwitchBtn(!switchBtn);
    onChangeJobStatus(JobData?.id);
  };

  const onDetailJobHandler = (id) => {
    navigate(`/job-view-detail/${id}`);
  };

  const onDetailCompanyHandler = (id) => {
    navigate(`/company-detail/${id}`);
  };

  useEffect(() => {
    setSwitchBtn(radioBtn);
  }, []);

  return (
    <>
      <div className="job-requriments">
        <div className="job-details">
          <div className={`job-title `}>
            <a
              className="title-job-link promoted-btn-not"
              onClick={() => onDetailJobHandler(JobData?.id)}
            >
              <h3>{JobData?.name}</h3>
            </a>
            {JobData?.is_promoted === 1 && (
              <Link href="#" className="title-job-link promoted-btn-not">
                <button>Promoted * </button>
              </Link>
            )}
            {/* <div className="job-services long-text-job-company">
                 <Link to="/company-detail">
                   <img src={JobData.companyLogo} alt="fitenss" />
                  <p>{JobData.companyName}</p>
                </Link>
               </div> */}
          </div>
          <div className="job-seclude">
            <div className="job-time">
              <p>
                {JobData?.salary_from &&
                  `Salary $${NFormatter(JobData?.salary_from)}`}{" "}
                {JobData?.salary_to && `- $${NFormatter(JobData?.salary_to)}`}
              </p>
            </div>
            <div className="job-time">
              <p>{JobData?.jobType?.length > 0 && JobData?.jobType[0]?.name}</p>
            </div>
          </div>
        </div>
        <div className="job-info">
          <div className="job-services-anchor">
            <a onClick={() => onDetailCompanyHandler(JobData?.company?.id)}>
              <img src={`/image/company-sm.png`} alt="fitenss" />
              <p>{JobData?.company?.name}</p>
            </a>
          </div>
          <p className="job-discription">
            {" "}
            {JobData?.description?.length >= 300 ? (
              <>{JobData?.description?.substr(0, 300) + "..."}</>
            ) : (
              <>{JobData?.description}</>
            )}{" "}
          </p>
        </div>
        <div className="job-skill-location">
          <div className="job-skills">
            {JobData?.tags?.length > 0 &&
              JobData?.tags?.map((t, i) => <button>{t?.name}</button>)}
          </div>
          {activeSwitch ? (
            JobData?.jobCities?.length !== 0 ? (
              <div class="job-location-switch">
                <div class="location-job-table">
                  <p>Location</p>
                  <img src="/image/Location.png" alt="Location" />
                  <p class="job-city">
                    {JobData?.jobCities?.length === 1
                      ? JobData?.jobCities[0]?.name
                      : "Multiple Cities"}
                  </p>
                </div>
                <div class="loction-switch-col">
                  <label class="switch">
                    <input
                      type="checkbox"
                      checked={switchBtn}
                      onChange={() => onSwitchChange()}
                    />
                    <span class="slider"></span>
                  </label>
                </div>
              </div>
            ) : (
              <div class="loction-switch-col">
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={switchBtn}
                    onChange={() => onSwitchChange()}
                  />
                  <span class="slider"></span>
                </label>
              </div>
            )
          ) : (
            JobData?.jobCities?.length !== 0 && (
              <div className="job-location">
                <p>Location</p>
                <img src="/image/Location.png" alt="Location" />
                <p className="job-city">
                  {JobData?.jobCities?.length === 1
                    ? JobData?.jobCities[0]?.name
                    : "Multiple Cities"}
                </p>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default JobRecordLeft;
