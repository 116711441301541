import { useEffect, useState } from "react";
import { useFormik, Field } from "formik";
import OutsideClickHandler from "react-outside-click-handler";
import * as Yup from "yup";
import API from "../../../../api";
import PhoneNumber from "../../../Common/PhoneNumber/PhoneNumber";

const CanProfileForm = ({
  loader,
  baseInfo,
  city,
  jobTitles,
  tags,
  editHandler,
  submitHandler,
}) => {
  const [allJobTitles, setAllTobTitles] = useState([]);
  const [selectJobTitles, setSelectJobTitles] = useState([]);
  const [allJobTitlesHide, setAllJobTitlesHide] = useState(true);

  const [allSkillTags, setAllSkillTags] = useState([]);
  const [selectSkillTags, setSelectSkillTags] = useState([]);
  const [allSkillTagsHide, setAllSkillTagsHide] = useState(true);

  const [selectedImageName, setSelectedImageName] = useState("");
  const [selectedVideoName, setSelectedVideoName] = useState("");

  const [cityOptions, setCityOptions] = useState([]);
  const [locationHide, setLocationHide] = useState(true);

  const [phoneNumberValue, setPhoneNumberValue] = useState(null);

  const onChange = (event) => {
    setPhoneNumberValue(event.target.value);
  };

  const highlightMatchingText = (option, inputValue) => {
    const lowercaseInputValue = inputValue.toLowerCase();
    const regex = new RegExp(`(${lowercaseInputValue})`, "gi");
    const parts = option.split(regex);

    return (
        <span>
        {parts.map((part, index) =>
            regex.test(part) ? (
                <strong key={index} style={{ color: "#143975" }}>
                  {part}
                </strong>
            ) : (
                part
            ),
        )}
      </span>
    );
  };

  const formik = useFormik({
    initialValues: {
      file: null,
      firstName: "",
      lastName: "",
      countryId: null,
      city: "",
      cityId: null,
      email: "",
      phoneNo: "",
      linkedinURL: "",
      aboutSelf: "",
      interested: "",
      introVideo: null,
      jobTitle: "",
      skillTags: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter first name"),
      lastName: Yup.string().required("Please enter last name"),
      city: Yup.string().required("Please select the city"),
      phoneNo: Yup.string().required("Enter phone number"),
      files: Yup.mixed()
        .test(
          "fileType",
          "File can be a JPEG or PNG document",
          (value) =>
            !value || ["image/png", "image/jpeg"].includes(value?.type),
        )
        .test(
          "fileSize",
          "The file size should not exceed 10 MB",
          (value) => !value || value?.size <= 10 * 1024 * 1024,
        ),

      introVideo: Yup.mixed()
        .test(
          "fileType",
          "Unsupported file format",
          (value) =>
            !value ||
            [
              "video/mp4",
              "video/webm",
              "video/x-matroska",
              "video/3gpp",
            ].includes(value?.type),
        )
        .test(
          "fileSize",
          "The file size should not exceed 25 MB",
          (value) => !value || value?.size <= 25 * 1024 * 1024,
        ),
      /*skillTags: Yup.mixed().test(
        "skillTags",
        "Please select a minimum of 5 Skill Tags",
        (value) =>
          !!(Array.isArray(selectSkillTags) && selectSkillTags?.length > 5),
      ),*/
    }),

    onSubmit: async (data, { resetForm, setErrors }) => {
      const selectJobTitlesId = selectJobTitles?.map((item) => item.id);
      const selectSkillTagsId = selectSkillTags.map((item) => item.id);

      submitHandler(data, selectJobTitlesId, selectSkillTagsId);
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const isFileFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFileErrorMessage = (name) => {
    return (
      isFileFieldValid(name) && (
        <p className="file-error-msg">{formik.errors[name]}</p>
      )
    );
  };

  /*const isSkillTagsFieldValid = () => {
    if ( selectSkillTags?.length < 5){
      formik.errors["skillTags"] = "Please select a minimum of 5 Skill Tags";
    }
    return !!((formik.touched["skillTags"] && formik.errors["skillTags"] && selectSkillTags?.length < 5) || (formik.errors["skillTags"] && selectSkillTags?.length < 5) || (selectSkillTags?.length < 5))
  }

  const getSkillTagsErrorMessage = () => {
    return (
        isSkillTagsFieldValid() && (
            <p className="error-msg">{formik.errors["skillTags"]}</p>
        )
    )
  }*/

  const cityHandler = async (id, name) => {
    await formik.setFieldValue("city", name);
    await formik.setFieldValue("cityId", id);
    setLocationHide(true);
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImageName(file.name);
        formik.setFieldValue("files", file);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedImageName("");
      formik.setFieldValue("files", null);
    }
  };

  const handleVideoChange = async (event) => {
    console.log("event", event);
    console.log("handleVideoChange");
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedVideoName(file.name);
        formik.setFieldValue("introVideo", file);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedVideoName("");
      formik.setFieldValue("introVideo", null);
    }
  };

  const onJobTitileChange = async (value) => {
    await formik.setFieldValue("jobTitle", value);
    getSearchSuggestion(value, "jobTitle");
  };

  const onJobTitileClick = async (curentJobTitles) => {
    const isIdExists = selectJobTitles?.some(
      (item) => item.id === curentJobTitles?.id,
    );
    if (isIdExists) return;
    setSelectJobTitles([...selectJobTitles, curentJobTitles]);
    setAllJobTitlesHide(true);
    await formik.setFieldValue("jobTitle", "");
  };

  const onJobTitileCancel = async (curentJobTitles) => {
    const updatedItems = selectJobTitles.filter(
      (item) => item.id !== curentJobTitles?.id,
    );
    setSelectJobTitles(updatedItems);
  };

  const onSkillTagsChange = async (value) => {
    await formik.setFieldValue("skillTags", value);
    getSearchSuggestion(value, "jobTag");
  };

  const onSkillTagsClick = async (curentSkillTags) => {
    const isIdExists = selectSkillTags?.some(
      (item) => item.id === curentSkillTags?.id,
    );
    if (isIdExists) return;
    setSelectSkillTags([...selectSkillTags, curentSkillTags]);
    setAllSkillTagsHide(true);
    await formik.setFieldValue("skillTags", "");
  };

  const onSkillTagsCancel = async (curentSkillTags) => {
    const updatedItems = selectSkillTags.filter(
      (item) => item.id !== curentSkillTags?.id,
    );
    setSelectSkillTags(updatedItems);
  };

  const getSearchSuggestion = async (searchValue, searchType) => {
    try {
      let params = ``;
      if (searchValue !== "") {
        params = `?query_key=${searchValue}&type=${searchType}`;
      }
      await API.get(`/job-board/get-search-suggestions${params}`)
        .then((response) => {
          if (response?.status === 200) {
            let res = response?.data;
            if (searchType === "jobTitle") {
              if (res?.job_titles?.length > 0) {
                setAllTobTitles(res?.job_titles);
                setAllJobTitlesHide(false);
              }
            }

            if (searchType === "jobTag") {
              if (res?.job_tags?.length > 0) {
                setAllSkillTags(res?.job_tags);
                setAllSkillTagsHide(false);
              }
            }
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    async function getCities() {
      if (baseInfo?.countryId) {
        try {
          await API.get(`/get-cities?country_id=${baseInfo?.countryId}`)
            .then((response) => {
              if (response?.status === 200) {
                let res = response?.data;
                setCityOptions(res);
              }
            })
            .catch((error) => {
              if (error) {
                console.log(error);
              }
            });
        } catch (error) {
          console.log(error);
        }
      }
    }

    getCities();
  }, [baseInfo]);

  useEffect(() => {
    formik.setValues({
      firstName: baseInfo?.first_name || "",
      lastName: baseInfo?.last_name || "",
      countryId: baseInfo?.countryId || "",
      city: city?.cityName || "",
      cityId: city?.cityId || "",
      email: baseInfo?.email || "",
      phoneNo: baseInfo?.phone || "",
      linkedinURL: baseInfo?.linkedin || "",
      aboutSelf: baseInfo?.bio || "",
      interested: baseInfo?.my_interests || "",
    });
    setSelectJobTitles(jobTitles);
    setSelectSkillTags(tags);
  }, []);

  console.log("formik errors =======>", formik.errors);

  return (
    <>
      <div className="candidtae-profile-image">
        <label id="candidate-img">
          <img src="/image/upload-1.png" alt="pic" />
          <input
            id="file-input"
            type="file"
            name="files"
            onClick={(e) => handleImageChange(e)}
            onChange={(e) => handleImageChange(e)}
          />
          <p className="candidate-logo-file">Upload here</p>
          <p className="file-size-candidate">Max: 10MB</p>
          <p className="p.file-error-msg"></p>
          {selectedImageName}
          {getFileErrorMessage("files")}
        </label>
      </div>
      <div className="divider-left-profile"></div>
      <div className="candidate-information">
        <div className="candidate-about-me">
          <h5>About Me</h5>
          <div className="candidate-profile-form">
            <form onSubmit={formik.handleSubmit}>
              <div className={`candidate-name-field-profile profile-field-f`}>
                <div
                  class=""
                  className={`first-name-profile ${
                    isFormFieldValid("firstName") && "error-input"
                  }`}
                >
                  <input
                    className="form-font-f"
                    type="text"
                    id="candidate-name-field"
                    placeholder="First Name"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {getFormErrorMessage("firstName")}
                </div>
                <div
                  className={`first-name-profile ${
                    isFormFieldValid("lastName") && "error-input"
                  }`}
                >
                  <input
                    className="form-font-f"
                    type="text"
                    id="candidate-name-field"
                    placeholder="Last Name"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {getFormErrorMessage("lastName")}
                </div>
              </div>

              <div
                className={`profile-field-f signup-passowrd-job location-profile-list ${
                  isFormFieldValid("city") && "error-input"
                }`}
              >
                <div className="industry-feild-frm bg-pass-f">
                  <div className="password-icon-logo">
                    <img src="/image/resume-location.png" alt="" />
                  </div>
                  <div
                    id="dropdown-boxId-2"
                    className="company-form-dropdownBox-profile"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={() => setLocationHide(!locationHide)}
                  >
                    {" "}
                    {formik?.values?.city === ""
                      ? "location"
                      : formik?.values?.city}
                  </div>
                  <div className="icon-hide">
                    {isFormFieldValid("city") ? (
                      <img src="/image/warning.png" alt="" />
                    ) : (
                      <img src="/image/form-d-f.png" alt="" />
                    )}
                  </div>
                  {cityOptions?.length > 0 && (
                    <div
                      id="company-dropList-t"
                      className={`dropdown-form-f company-drop-list-t ${
                        locationHide && "job-dropdown-hide"
                      }`}
                    >
                      <ul>
                        {cityOptions.map((j, i) => (
                          <li
                            className="icon-cursor-f"
                            key={i}
                            onClick={() => {
                              cityHandler(j?.id, j?.name);
                            }}
                          >
                            <img src="/image/resume-location.png" alt="" />{" "}
                            {j?.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                {getFormErrorMessage("city")}
              </div>

              <div className={`email-candidate-profile-f profile-field-f`}>
                <div className="location-f-icon bg-pass-f">
                  <div className="password-icon-logo">
                    <img src="/image/resume-mail.png" alt="" />
                  </div>
                  <input
                    className="form-font-f"
                    id="profile-email-candidate"
                    placeholder="E-Mail"
                    name="email"
                    value={formik.values.email}
                  />
                  <div className="icon-hide">
                    <img src="/image/lock-profile-employye.png" alt="" />
                    <span className="tooltiptext-profile">
                      Contact Admin Support
                    </span>
                  </div>
                </div>
              </div>

              <div
                className={`profile-field-f ${
                  isFormFieldValid("phoneNo") && "error-input"
                }`}
              >
                <div
                  className={`country-code-profile phone-f-profile  ${
                    isFormFieldValid("phoneNo") && "error-input"
                  }`}
                >
                  <div className="location-f-icon bg-pass-f">
                    <div className="password-icon-logo">
                      <img src="/image/resume-call.png" alt="" />
                    </div>
                    <PhoneNumber                      
                      className="form-font-f"
                      id="profile-call"
                      name="phoneNo"
                      value={formik.values.phoneNo}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <div className="icon-hide">
                      {isFormFieldValid("phoneNo") && (
                        <img src="/image/warning.png" alt="" />
                      )}
                    </div>
                  </div>
                </div>
                {getFormErrorMessage("phoneNo")}
              </div>
              <div className="candidate-link-profile profile-field-f">
                <div className="location-f-icon bg-pass-f">
                  <div className="password-icon-logo">
                    <img src="/image/linkdin.png" alt="" />
                  </div>
                  <input
                    className="form-font-f"
                    type="text"
                    id="social-link"
                    placeholder="Enter Your LinkedIn Profile Here"
                    name="linkedinURL"
                    value={formik.values.linkedinURL}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>

              <div
                className={`accordion-candidate-prfile`}
                id="myAccordionCandidateCandidate"
              >
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <button
                      type="button"
                      className="accordion-button collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                    >
                      Tell us about yourself
                    </button>
                  </div>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#myAccordionCandidate"
                  >
                    <div className="card-body">
                      <textarea
                        className="form-font-f"
                        id="candidate-profile-tabs"
                        cols="30"
                        rows="10"
                        name="aboutSelf"
                        value={formik.values.aboutSelf}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <p className="candidate-profile-tabs-msg">
                        100 words limit
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="headingTwo">
                    <button
                      type="button"
                      className="accordion-button collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                    >
                      Things I like to do.
                    </button>
                  </div>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#myAccordionCandidate"
                  >
                    <div className="card-body">
                      <textarea
                        className="form-font-f"
                        id="candidate-profile-tabs"
                        cols="30"
                        rows="10"
                        name="interested"
                        value={formik.values.interested}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <p className="candidate-profile-tabs-msg">
                        100 words limit
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="headingThree">
                    <button
                      type="button"
                      className="accordion-button collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                    >
                      Short Intro video
                    </button>
                  </div>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#myAccordionCandidate"
                  >
                    <div className="card-body">
                      <div className="upload-intro-vedio">
                        <label id="candidate-img">
                          <img src="/image/upload-1.png" alt="pic" />
                          <input
                            id="file-input-2"
                            type="file"
                            name="introVideo"
                            onClick={(e) => handleVideoChange(e)}
                            onChange={(e) => handleVideoChange(e)}
                          />
                          <p className="candidate-logo-file">Upload here</p>
                          <p className="file-size-candidate">Max: 25MB</p>
                          <p className="file-size-candidate">
                            Format: .mp4, .webm, .mkv, .3gp{" "}
                          </p>
                          {selectedVideoName}
                          {getFileErrorMessage("introVideo")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="preferences-row">
                <h5>Preferences</h5>
              </div>
              <div className="preferences-fields-profile">
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setAllJobTitlesHide(true);
                  }}
                >
                  <div className="signup-job-titles f-feild tags-tittles">
                    <label for="fjobtitles">Job Titles I’m interested in</label>
                    <input
                      className="form-font-f"
                      type="text"
                      name="jobTitle"
                      value={formik.values.jobTitle}
                      onChange={(e) => onJobTitileChange(e.currentTarget.value)}
                      placeholder="Job Titles I’m interested in"
                    />
                    {allJobTitles?.length > 0 && (
                      <div
                        className={`job-tags-titles-dropdown ${
                          allJobTitlesHide && "job-dropdown-hide"
                        }`}
                      >
                        <ul className="dorpdown-list-location">
                          {allJobTitles.map((j, i) => (
                            <li
                              key={i}
                              onClick={() => {
                                onJobTitileClick(j);
                              }}
                            >
                              {formik?.values?.jobTitle?.length > 0 ? (
                                  <>
                                    {highlightMatchingText(
                                        j?.name,
                                        formik?.values?.jobTitle,
                                    )}
                                  </>
                              ) : (
                                  j?.name
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}

                    {selectJobTitles?.length > 0 && (
                      <ul class="tags-titles-list">
                        {selectJobTitles.map((j, i) => (
                          <li key={i}>
                            {j?.name}
                            <span onClick={() => onJobTitileCancel(j)}>
                              <img src="../image/cross.png" alt="" />
                            </span>
                          </li>
                        ))}
                      </ul>
                    )}
                    {/* <p className="error-msg hide-error">Enter job titles </p> */}
                  </div>
                </OutsideClickHandler>
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setAllSkillTagsHide(true);
                  }}
                >
                  {/*<div className={`signup-skill-tags f-feild tags-tittles ${isSkillTagsFieldValid() && "error-input"}`}>*/}
                  <div className={`signup-skill-tags f-feild tags-tittles`}>
                    <label for="fjobtitles">Tech Tags</label>
                    <input
                      className="form-font-f"
                      type="text"
                      name="skillTags"
                      value={formik.values.skillTags}
                      onChange={(e) => {
                        onSkillTagsChange(e.currentTarget.value)
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      placeholder="Tech Tags"
                    />
                      {/*{getSkillTagsErrorMessage()}*/}

                    {allSkillTags?.length > 0 && (
                      <div
                        className={`job-tags-titles-dropdown ${
                          allSkillTagsHide && "job-dropdown-hide"
                        }`}
                      >
                        <ul className="dorpdown-list-location">
                          {allSkillTags.map((j, i) => (
                            <li
                              key={i}
                              onClick={() => {
                                onSkillTagsClick(j);
                              }}
                            >
                              {" "}
                              {j?.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}

                    {selectSkillTags?.length > 0 && (
                      <ul class="tags-titles-list">
                        {selectSkillTags.map((j, i) => (
                          <li key={i}>
                            {j?.name}
                            <span onClick={() => onSkillTagsCancel(j)}>
                              {/*<img src="../image/cross.png" alt="" onClick={() => isSkillTagsFieldValid()} />*/}
                              <img src="../image/cross.png" alt="" />
                            </span>
                          </li>
                        ))}
                      </ul>
                    )}

                    {/* <p className="error-msg hide-error ">Enter skill tags</p> */}
                  </div>
                </OutsideClickHandler>
              </div>

              <div className="back-save-btns">
                <button type="submit" className="save-btn-profilefill">
                  {loader ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Save"
                  )}
                </button>
                <button
                  className="cancel-btn-profile"
                  onClick={() => editHandler(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CanProfileForm;
