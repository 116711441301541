import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { setActiveSubTab } from "../../../../redux/slices/AuthSlice";
import API from "../../../../api";
import { PublicDomains } from "../../../../constants";
import PhoneNumber from "../../../Common/PhoneNumber/PhoneNumber";

const SignUpForm = ({ signUpFormHandler }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      country: "",
      countryId: null,
      phoneNo: "",
      termServices: false,
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter first name"),
      lastName: Yup.string().required("Please enter last name"),
      email: Yup.string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Please enter valid email address"
        )
        .required("Please enter email")
        .test(
          "is-not-public-email",
          "Public email account are not allowed",
          (value) => {
            const domain = value.split("@")[1];
            console.log("domain");
            return !PublicDomains.includes(domain);
          }
        ),

      password: Yup.string().test((value) => {
        const errors = [];
        if (!value || value.length < 8) {
          errors.push("Password must be at least 8 characters in length");
        }
        if (!/\d/.test(value)) {
          errors.push("Use a minimum of 1 numeric value");
        }
        if (!/[A-Z]/.test(value)) {
          errors.push("Use at least 1 Upper Case Letter (ABCD)");
        }
        if (!/[a-z]/.test(value)) {
          errors.push("Use at least 1 Lower Case Letter (abcd)");
        }
        if (!/[!#%$*]/.test(value)) {
          errors.push("Use at least one special character (!# % $ *)");
        }
        setPasswordErrors(errors);

        return errors.length === 0;
      }),
      confirmPassword: Yup.string()
        .required("Please enter confirm password")
        .oneOf([Yup.ref("password")], "Your passwords do not match"),
      country: Yup.string().required("Please select country"),
      phoneNo: Yup.string().required("Enter phone number"),
      termServices: Yup.boolean()
        .oneOf([true], "Please select the terms and conditions")
        .required("Please select the terms and conditions"),
    }),

    onSubmit: async (data, { resetForm, setErrors }) => {
      const { email } = data;
      if (passwordErrors?.length > 0) return;
      try {
        setLoader(true);
        await API.post("/auth/check-email-unique", {
          email: email,
        })
          .then(async (response) => {
            if (response?.status === 200 || response?.status === 201) {
              let res = response?.data;
              if (response?.data?.result === true) {
                await signUpFormHandler(data);
              } else {
                let errorMsg = res?.message?.email[0];
                setErrors({ email: errorMsg });
              }
              setLoader(false);
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
            setLoader(false);
          });
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const isPasswordFieldValid = (passwordErrors) =>
    !!(formik.touched["password"] && passwordErrors?.length > 0);
  const getPasswordErrorMessage = (passwordErrors) => {
    if (isPasswordFieldValid(passwordErrors)) {
      return (
        <ul className="error-msg">
          {passwordErrors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      );
    }
    return null;
  };

  const countryHandler = async (id, name) => {
    await formik.setFieldValue("country", name);
    await formik.setFieldValue("countryId", id);
  };

  useEffect(() => {
    async function getCountries() {
      try {
        await API.get(`/get-countries`)
          .then((response) => {
            if (response?.status === 200) {
              let res = response?.data;
              setCountryOptions(res);
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }

    getCountries();
  }, []);

  return (
    <>
      <div className="candidate-signup-form-feild ">
        <form onSubmit={formik.handleSubmit}>
          <div className="candidate-username-signup-form f-feild ">
            <div
              className={`first-name ${
                isFormFieldValid("firstName") && "error-input"
              }`}
            >
              <label for="fname">First Name</label>
              <br />
              <input
                className="form-font-f"
                type="text"
                placeholder="First Name"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("firstName")}
            </div>

            <div
              className={`last-name ${
                isFormFieldValid("lastName") && "error-input"
              }`}
            >
              <label for="lname">Last Name</label>
              <br />
              <input
                className="form-font-f"
                type="text"
                placeholder="Last Name"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("lastName")}
            </div>
          </div>

          <div
            className={`signup-email f-feild ${
              isFormFieldValid("email") && "error-input"
            }`}
          >
            <label for="femail">E-Mail</label>
            <br />
            <input
              className="form-font-f"
              type="email"
              name="email"
              id=""
              placeholder="Enter valid email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {getFormErrorMessage("email")}
          </div>

          <div
            className={`signup-passowrd f-feild ${
              isPasswordFieldValid(passwordErrors) && "error-input"
            }`}
          >
            <label for="fpassword">Password</label>
            <br />
            <div class="password-icon bg-pass-f">
              <input
                className="form-font-f"
                id="password-field"
                type={passwordShown ? "text" : "password"}
                name="password"
                placeholder="Create Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <div className="icon-hide">
                {isPasswordFieldValid(passwordErrors) ? (
                  <img src="/image/warning.png" alt="" />
                ) : (
                  <>
                    <img
                      src="/image/hide-password.png"
                      alt=""
                      className={passwordShown && "password-icon-togle"}
                      onClick={() => setPasswordShown(true)}
                    />
                    <img
                      src="/image/password.png"
                      alt=""
                      className={!passwordShown && "password-icon-togle"}
                      onClick={() => setPasswordShown(false)}
                    />
                  </>
                )}
              </div>
            </div>
            {getPasswordErrorMessage(passwordErrors)}
          </div>
          <div
            className={`signup-passowrd f-feild ${
              isFormFieldValid("confirmPassword") && "error-input"
            }`}
          >
            <label for="fpassword">Confirm Password</label>
            <br />
            <div class="password-icon bg-pass-f">
              <input
                className="form-font-f"
                id="password-field"
                type={confirmPasswordShown ? "text" : "password"}
                name="confirmPassword"
                placeholder="Re-type Password"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <div className="icon-hide">
                {isFormFieldValid("confirmPassword") ? (
                  <img src="/image/warning.png" alt="" />
                ) : (
                  <>
                    <img
                      src="/image/hide-password.png"
                      alt=""
                      className={confirmPasswordShown && "password-icon-togle"}
                      onClick={() => setConfirmPasswordShown(true)}
                    />
                    <img
                      src="/image/password.png"
                      alt=""
                      className={!confirmPasswordShown && "password-icon-togle"}
                      onClick={() => setConfirmPasswordShown(false)}
                    />
                  </>
                )}
              </div>
            </div>
            {getFormErrorMessage("confirmPassword")}
          </div>

          <div
            className={`signup-country f-feild ${
              isFormFieldValid("country") && "error-input"
            }`}
          >
            <label for="fcountry">Country</label>
            <br />
            <div
              className={`industry-feild-frm-country bg-pass-f ${
                isFormFieldValid("country") && "error-input"
              }`}
            >
              <div
                id="dropdown-boxId-2"
                className="company-form-dropdownBox-f"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {formik?.values?.country === ""
                  ? "Choose Country"
                  : formik?.values?.country}
              </div>
              <div className="icon-hide">
                {isFormFieldValid("country") ? (
                  <img src="/image/warning.png" alt="" />
                ) : (
                  <img src="/image/form-d-f.png" alt="" />
                )}
              </div>
              {countryOptions?.length > 0 && (
                <div className="list-search-dropdown-company">
                  <ul className="list-job-title-country">
                    {countryOptions.map((j, i) => (
                      <li
                        className="icon-cursor-f"
                        onClick={() => countryHandler(j?.id, j?.name)}
                      >
                        {/* <img src="/image/US.png" alt="" /> */}
                        {j?.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div
            className={`signup-phone f-feild ${
              isFormFieldValid("phoneNo") && "error-input"
            }`}
          >
            <label for="fphone">Phone</label>
            <br />
            <div
              className={`merge-feild  ${
                isFormFieldValid("phoneNo") && "error-input"
              }`}
            >
              <PhoneNumber
                id=""
                className="form-font-f"
                type="tel"
                name="phoneNo"
                value={formik.values.phoneNo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            {getFormErrorMessage("phoneNo")}
          </div>

          <div className="signup-check f-feild">
            {isFormFieldValid("termServices") && (
              <div className="attention-box">
                <div className="attendtion-heading">
                  <div className="icon-atten">
                    <img src="/image/attention.png" alt="" />
                  </div>
                  <div className="attention-text">
                    <p className="text-atten-box">
                      {formik?.errors?.termServices}
                    </p>
                  </div>
                </div>
              </div>
            )}
            <input
              type="checkbox"
              name="termServices"
              checked={formik?.values?.termServices}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />{" "}
            &nbsp;Yes, I understand and Agree to the Terms of Service
          </div>
          <div className="candidate-from-signup-create">
            <button type="submit" className="form-submit">
              {loader ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Next"
              )}
              {"  "}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SignUpForm;
