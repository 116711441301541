import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ApplicantsTable from "../../Common/ApplicantsTable/ApplicantsTable";
import API from "../../../api";
import { useDispatch } from "react-redux";
import {
  getCandidateStatuses,
  setCandidateStatues,
} from "../../../redux/slices/employerDashboardSlice";

const NewApplicants = ({
  totalApplicants,
  applicants,
  onFvtClick,
  modelHandler,
  modelCHandler,
  selectedFirstImpression,
  selectedCandidateStatus,
  onSearchChange,
  onFirstImpressionChange,
  onFirstImpressionClean,
  onCandidateStatusChange,
  onCandidateStatusClean,
  onFirstImpressionClick,
  onCandidateStatusChangeHandler,
  onResumeClickHandler,
  handleAddNewCommentHandler,
                         handleRemovedComment,
}) => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(10);
  const [firstImpression, setFirstImpression] = useState(null);
  const [candidateStatuses, setCandidateStatuses] = useState(null);

  const getImpressionsList = async () => {
    try {
      await API.get(`/get-impressions`)
        .then((response) => {
          if (
            response?.status === 200 ||
            response?.status === 201 ||
            response?.status === 304
          ) {
            let res = response?.data;
            if (res?.result === true) {
              setFirstImpression(res?.impressions);
            }
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCandidateStatuses = async () => {
    dispatch(getCandidateStatuses())
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          dispatch(setCandidateStatues(res?.status));
          setCandidateStatuses(res?.status);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getImpressionsList();
    fetchCandidateStatuses();
  }, []);

  const countHandler = () => {
    if (count < totalApplicants) setCount((prevCount) => prevCount + 10);
  };

  // if (applicants?.length === 0) return null;

  return (
    <section className="new-job" id="new_applicants">
      <div className="container container-site">
      <div className="space-inner-row">
        <div className="job-search-box-row">
          <div className="job-heading smilar-job-heading">
            <h2>New Applicants</h2>
          </div>
          <div className="search-box">
            <img src="/image/job-search.png" alt="" />
            <input
              type="text"
              name=""
              id="jon-search-box"
              placeholder="Search Jobs"
              className="form-font-f"
              onChange={(e) => {
                onSearchChange(e?.target?.value);
              }}
            />
          </div>
        </div>
        <div className="divider"></div>
        <div className="j-fillter-only-table">
          <div className="job-fillter-company-table">
            <div className="j-company-fillter-table">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1-table"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-filter-icon-table">
                  <p>
                    {" "}
                    {selectedFirstImpression?.text === null
                      ? "First Impression"
                      : selectedFirstImpression?.text?.length >= 20
                      ? selectedFirstImpression?.text?.substring(0, 20) + " ..."
                      : selectedFirstImpression?.text}
                  </p>
                  {selectedFirstImpression?.text === null ? (
                    <img src="/image/company--detail-filter.png" alt="" />
                  ) : (
                    <div
                      id="dropdown-boxId2"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src="/image/cross-b.png"
                        alt=""
                        onClick={() => onFirstImpressionClean()}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="list-search-dropdown-company">
                <ul className="list-job-title-company">
                  {firstImpression?.map((impression) => (
                    <li
                      key={impression?.id}
                      onClick={() =>
                        onFirstImpressionChange(
                          impression?.id,
                          impression?.impression,
                        )
                      }
                    >
                      {impression?.impression}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="job-fillter-company-table">
            <div className="j-company-fillter-table">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1-table"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-filter-icon-table">
                  <p>
                    {" "}
                    {selectedCandidateStatus?.text === null
                      ? "Candidate Status"
                      : selectedCandidateStatus?.text?.length >= 12
                      ? selectedCandidateStatus?.text?.substring(0, 12) + " ..."
                      : selectedCandidateStatus?.text}
                  </p>
                  {selectedCandidateStatus?.text === null ? (
                    <img src="/image/company--detail-filter.png" alt="" />
                  ) : (
                    <div
                      id="dropdown-boxId2"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src="/image/cross-b.png"
                        alt=""
                        onClick={() => onCandidateStatusClean()}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="list-search-dropdown-company">
                <ul className="list-job-title-company">
                  {candidateStatuses?.map((status) => (
                    <li
                      key={status?.id}
                      onClick={() =>
                        onCandidateStatusChange(status?.id, status?.status)
                      }
                    >
                      {status?.status}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="divider"></div>
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th scope="col">Full name</th>
              <th scope="col">Job Title</th>
              <th scope="col">Date Applied</th>
              <th scope="col">First Impression</th>
              <th scope="col" className="technical-tags-row">
                Technical Tags:
              </th>
              <th scope="col">Candidate Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {applicants?.length > 0 &&
              applicants
                ?.slice(0, count)
                ?.map((item, index) => (
                  <ApplicantsTable
                    key={index}
                    index={index}
                    item={item}
                    candidateStatusesList={candidateStatuses}
                    onFvtClick={onFvtClick}
                    modelHandler={modelHandler}
                    modelCHandler={modelCHandler}
                    onFirstImpressionClick={onFirstImpressionClick}
                    onCandidateStatusChangeHandler={
                      onCandidateStatusChangeHandler
                    }
                    onResumeClickHandler={onResumeClickHandler}
                    handleAddNewCommentHandler={handleAddNewCommentHandler}
                    handleRemovedComment={handleRemovedComment}
                  />
                ))}
          </tbody>
        </table>
        </div>
      </div>
      {applicants?.length > 10 && (
        <div class="see-more-job" onClick={() => countHandler()}>
          <Link href="#">
            <button>See more</button>
          </Link>
        </div>
   
      )}
    </section>
  );
};

export default NewApplicants;
