import { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Player, BigPlayButton } from "video-react";
import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";
import "./CanProfileView.css";
import "video-react/dist/video-react.css";

const CanProfileView = ({
  baseInfo,
  city,
  jobTitles,
  tags,
  onSubmitAboutSelf,
  onSubmitInterested,
  onSubmitIntroVideo,
}) => {
  const [aboutSelf, setAboutSelf] = useState("");
  const [interested, setInterested] = useState("");
  const [selectedVideoName, setSelectedVideoName] = useState("");
  const [introVideo, setIntroVideo] = useState(null);

  const formik = useFormik({
    initialValues: {
      introVideo: null,
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      introVideo: Yup.mixed()
        .test(
          "fileType",
          "Unsupported file format",
          (value) =>
            !value ||
            [
              "video/mp4",
              "video/webm",
              "video/x-matroska",
              "video/3gpp",
            ].includes(value?.type)
        )
        .test(
          "fileSize",
          "The file size should not exceed 25 MB",
          (value) => !value || value?.size <= 25 * 1024 * 1024
        ),
    }),

    onSubmit: async (data, { resetForm, setErrors }) => {
      const { introVideo } = data;
      if (introVideo !== null) onSubmitIntroVideo(introVideo);
    },
  });

  const isFileFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFileErrorMessage = (name) => {
    return (
      isFileFieldValid(name) && (
        <p className="file-error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const aboutSelfClickHandler = () => {
    onSubmitAboutSelf(aboutSelf);
  };

  const interestedClickHandler = () => {
    onSubmitInterested(interested);
  };

  const handleVideoChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedVideoName(file.name);
        formik.setFieldValue("introVideo", file);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedVideoName("");
      formik.setFieldValue("introVideo", null);
    }
  };

  return (
    <>
      {baseInfo?.avatar === null || baseInfo?.avatar === undefined ? (
        <div className="candidtae-profile-image-avatar">
          <img src="/image/avtar.jpg" alt="" />
        </div>
      ) : (
        <div className="candidtae-profile-image-avatar">
          <img src={baseInfo?.avatar} alt="" />
        </div>
      )}

      <div className="candidate-information">
        <div className="candidate-profile-date-after">
          <h5>{`${capitalizeFirstLetter(baseInfo?.first_name)}  ${
            baseInfo?.last_name
          }`}</h5>

          {city?.cityName && (
            <div className="candiate-info-heading">
              <Link href="#">
                <img src="/image/candidate-location.png" alt="" />
                <p className="candidate-info-text">{city?.cityName}</p>
              </Link>
            </div>
          )}

          {baseInfo?.email && (
            <div className="candiate-info-heading">
              <a href="mailto:teklavasadze@gmail.com">
                <img src="/image/mail-can.png" alt="" />
                <p className="candidate-info-text">{baseInfo?.email}</p>
              </a>
            </div>
          )}

          {baseInfo?.phone && (
            <div className="candiate-info-heading">
              <a href="tel:+995599132862">
                <img src="../image/call-can.png" alt="" />
                <p className="candidate-info-text">{baseInfo?.phone}</p>
              </a>
            </div>
          )}

          {baseInfo?.linkedin && (
            <div className="candiate-info-heading">
              <Link href="#">
                <img src="/image/linkdin.png" alt="" />
                <p className="candidate-info-text">{baseInfo?.linkedin}</p>
              </Link>
            </div>
          )}
        </div>
        <div
          className={`accordion-candidate-prfile ${
            (jobTitles?.length !== 0 || tags?.length !== 0) &&
            "divider-bottom-tabdata"
          }`}
          id="myAccordionCandidateCandidate"
        >
          {baseInfo?.bio !== null ? (
            <div className="accordion-item">
              <div className="candidate-profile-datefill">About Me</div>
              <div className="card-body">
                <p className="candidate-profile-data">{baseInfo?.bio} </p>
              </div>
            </div>
          ) : (
            <div className="accordion-item">
              <div className="accordion-header" id="headingOne">
                <button
                  type="button"
                  className="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                >
                  Tell us about yourself
                </button>
              </div>
              <div
                id="collapseOne"
                className="accordion-collapse collapse"
                data-bs-parent="#myAccordionCandidate"
              >
                <div className="card-body">
                  <textarea
                    className="form-font-f"
                    id="candidate-profile-tabs"
                    cols="30"
                    rows="10"
                    name="aboutSelf"
                    value={aboutSelf}
                    onChange={(e) => {
                      setAboutSelf(e.target.value);
                    }}
                  />
                  <p className="candidate-profile-tabs-msg">100 words limit</p>
                  <div className="save-cancel-btn">
                    <button
                      className="cancel-btn-profile"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                    >
                      Cancel
                    </button>
                    <button
                      className="save-btn-profile"
                      onClick={() => aboutSelfClickHandler()}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {baseInfo?.my_interests !== null ? (
            <div className="accordion-item">
              <div className="candidate-profile-datefill">
                Things I like to do.
              </div>
              <div className="card-body">
                {/* <ul className="candidate-profile-data-list">
                  {baseInfo?.my_interests}{" "}
                </ul> */}
                <p className="candidate-profile-data">
                  {baseInfo?.my_interests}{" "}
                </p>
              </div>
            </div>
          ) : (
            <div className="accordion-item">
              <div className="accordion-header" id="headingTwo">
                <button
                  type="button"
                  className="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                >
                  Things I like to do.
                </button>
              </div>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#myAccordionCandidate"
              >
                <div className="card-body">
                  <textarea
                    className="form-font-f"
                    id="candidate-profile-tabs"
                    cols="30"
                    rows="10"
                    name="interested"
                    value={interested}
                    onChange={(e) => {
                      setInterested(e.target.value);
                    }}
                  ></textarea>
                  <p className="candidate-profile-tabs-msg">100 words limit</p>
                  <div className="save-cancel-btn">
                    <button
                      className="cancel-btn-profile"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                    >
                      Cancel
                    </button>
                    <button
                      className="save-btn-profile"
                      onClick={() => interestedClickHandler()}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {baseInfo?.intro_video_link !== null ? (
            <div className="accordion-item">
              <div className="candidate-profile-datefill">
                Short Intro video
              </div>
              <div className="card-body">
                <div className="intro-profile-vedio-chek">
                  <div className="upload-candidate-intro-vedio">
                    <Player src={baseInfo?.intro_video_link}>
                      <BigPlayButton position="center" />
                    </Player>
                  </div>
                  <div className="check-box-vedio">
                    <div className="chek-box-one">
                      <input type="checkbox" name="" id="" />
                      &nbsp;Send video with every job application
                    </div>
                    <div className="check-box-two">
                      <input type="checkbox" name="" id="" />
                      &nbsp;Ask before applying for job
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <div className="accordion-item">
                <div className="accordion-header" id="headingThree">
                  <button
                    type="button"
                    className="accordion-button collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                  >
                    Short Intro video
                  </button>
                </div>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#myAccordionCandidate"
                >
                  <div className="card-body">
                    <div className="upload-intro-vedio">
                      <label for="file-input" id="candidate-img">
                        <img src="/image/upload-1.png" alt="pic" />
                        <input
                          id="file-input"
                          type="file"
                          name="introVideo"
                          onClick={handleVideoChange}
                          onChange={handleVideoChange}
                        />
                        <p className="candidate-logo-file">Upload here</p>
                        <p className="file-size-candidate">Max: 25MB</p>
                        <p className="file-size-candidate">
                          Format: .mp4, .webm, .mkv, .3gp{" "}
                        </p>
                        {selectedVideoName}
                        {getFileErrorMessage("introVideo")}
                      </label>
                    </div>
                    <div className="save-cancel-btn">
                      <button
                        className="cancel-btn-profile"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                      >
                        Cancel
                      </button>
                      <button className="save-btn-profile" type="submit">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>

        {(jobTitles?.length !== 0 || tags?.length !== 0) && (
          <div className="preferences-row">
            <h5>My Preferences</h5>
          </div>
        )}
        <div className="preferences-fields-profile">
          {jobTitles?.length > 0 && (
            <div className="signup-job-titles f-feild tags-tittles">
              <label for="fjobtitles">Job Titles I’m interested in</label>
              <br />

              <ul className="tags-titles-list">
                {jobTitles.map((j, i) => (
                  <li>{j?.name}</li>
                ))}
              </ul>
            </div>
          )}

          {tags?.length > 0 && (
            <div className="signup-job-titles f-feild tags-tittles">
              <label for="fjobtitles">My Top Tech Skill Tags</label>
              <br />
              <ul className="tags-titles-list">
                {tags.map((j, i) => (
                  <li>{j?.name}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CanProfileView;
