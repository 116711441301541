import { useState } from "react";
import { Link } from "react-router-dom";
import JobRecordLeft from "../../Common/JobRecord/JobRecordLeft";
import JobAppliedRight from "../../Common/JobRecord/JobAppliedRight";
import { JobAppliedData } from "../../../data/candidate";

const JobApplied = ({ appliedJobsCount, appliedJobs }) => {
  const [count, setCount] = useState(10);

  const countHandler = () => {
    if (count < appliedJobsCount) setCount((prevCount) => prevCount + 10);
  };

  if (appliedJobs?.length === 0) return null;

  return (
    <section className="job-applied" id="job_applied">
      <div className="container container-site">
        <div className="space-inner-row">
          <div className="job-search-box-row">
            <div className="job-heading smilar-job-heading">
              <h2>Jobs Applied</h2>
            </div>
            <div className="search-box">
              <img src="/image/job-search.png" alt="" />
              <input
                type="text"
                name=""
                id="jon-search-box"
                placeholder="Search"
                className="form-font-f"
              />
            </div>
          </div>
          {appliedJobs?.length > 0 &&
            appliedJobs?.slice(0, count)?.map((prod) => (
              <>
                <div className="divider"></div>
                <div className="Job-Opportunities-row">
                  <JobRecordLeft JobData={prod} />
                  <JobAppliedRight date={prod?.date} selectedJobId={prod?.id} />
                </div>
              </>
            ))}
          <div className="divider"></div>
        </div>
      </div>
      {appliedJobs?.length > 10 && (
        <div className="see-more-job" onClick={() => countHandler()}>
          <Link href="#">
            <button>See more</button>
          </Link>
        </div>
      )}
    </section>
  );
};

export default JobApplied;
