import { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import { useDispatch, useSelector } from "react-redux";
import { setResumeUrl } from "../../../redux/slices/candidateDashboardSlice";
import { CANDIDATE_STATUSES, IMPRESSION_TYPES } from "../../../constants";
import CapitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import formatDate from "../../../utils/formatDate";
import "./ApplicantsTable.css";
import {
  removeJobCommentAsync,
  setCandidateId,
  setJobId,
} from "../../../redux/slices/commentModalSlice";
import getDays, {calculateDaysAgoUTC} from "../../../utils/getDays";

const ApplicantsTable = ({
  index,
  item,
  candidateStatusesList,
  onFvtClick,
  modelHandler,
  modelCHandler,
  onFirstImpressionClick,
  onCandidateStatusChangeHandler,
  onResumeClickHandler,
  handleAddNewCommentHandler,
                           handleRemovedComment,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [toggleCommentBox, setToggleCommentBox] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showBadCandidate, setShowBadCandidate] = useState(false);
  const [showNeedToThinkCandidate, setShowNeedToThinkCandidate] =
    useState(false);
  const [showGoodCandidate, setShowGoodCandidate] = useState(false);
  const [commentText, setCommentText] = useState("");

  const handleCommentTextChange = (e) => {
    const value = e.target.value;
    setCommentText(value);
  };

  const handleMouseOver = (impressionId) => {
    if (item?.status_id === CANDIDATE_STATUSES.NEW_APPLICANT) {
      setShowWarning(true);
      return;
    }
    switch (impressionId) {
      case IMPRESSION_TYPES.NOT_A_GOOD_FIT:
        setShowBadCandidate(true);
        break;
      case IMPRESSION_TYPES.NEED_TO_THINK:
        setShowNeedToThinkCandidate(true);
        break;
      case IMPRESSION_TYPES.GOOD_CANDIDATE:
        setShowGoodCandidate(true);
        break;
      default:
        break;
    }
  };

  const handleMouseOut = () => {
    setShowWarning(false);
    setShowBadCandidate(false);
    setShowGoodCandidate(false);
    setShowNeedToThinkCandidate(false);
  };

  const toggleCommentBoxHandler = () => setToggleCommentBox(!toggleCommentBox);

  const removeCommentClickHandler = (commentId, candidateId, jobId) => {
    dispatch(removeJobCommentAsync({
      commentId: commentId,
      candidateId: candidateId,
      jobId: jobId
    }))
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          handleRemovedComment(commentId, res?.lastComment[res?.lastComment?.length - 1] ?? "");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Fragment key={item?.id}>
      <tr>
        <td data-label="full name" className="name-box-hvr">
          {`${capitalizeFirstLetter(item?.first_name)}  ${item?.last_name}`}

          <div className="name-comment-box">
            <div className="name-add-box">
              <div className="comment-owner-info">
                <img src="/image/comment-owner.png" alt="" />
                <p className="name-comment-owner">{`${capitalizeFirstLetter(
                  item?.first_name,
                )}  ${item?.last_name}`}</p>
              </div>
              <div className="name-info-box">
                <p>
                  Experienced Python developer with 7 years of experience, adept
                  at utilizing libraries and frameworks to build efficient and
                  scalable applications. Strong problem-solving skills and
                  attention to detail, delivering high-quality software
                  solutions on time.
                </p>
              </div>
            </div>
          </div>
        </td>
        <td data-label="job Title">{item?.job_title}</td>
        <td data-label="Date Applied">{formatDate(item?.created_at)}</td>

        <td data-label="CV Review">
          <div className="cv-cions-review">
            <img
              className={showWarning === true ? null : "icon-cursor"}
              src={
                item?.impression_id === IMPRESSION_TYPES.NOT_A_GOOD_FIT
                  ? "/image/bad-cv.png"
                  : "/image/bad-cv-icon.png"
              }
              alt=""
              onClick={() =>
                onFirstImpressionClick(
                  item?.account_id,
                  item?.job_id,
                  IMPRESSION_TYPES.NOT_A_GOOD_FIT,
                  item?.status_id,
                )
              }
              onMouseOver={() =>
                handleMouseOver(IMPRESSION_TYPES.NOT_A_GOOD_FIT)
              }
              onMouseOut={() => handleMouseOut()}
            />

            <img
              className={showWarning === true ? null : "icon-cursor"}
              src={
                item?.impression_id === IMPRESSION_TYPES.NEED_TO_THINK
                  ? "/image/think-need.png"
                  : "/image/normal.cv.png"
              }
              alt=""
              onClick={() =>
                onFirstImpressionClick(
                  item?.account_id,
                  item?.job_id,
                  IMPRESSION_TYPES.NEED_TO_THINK,
                  item?.status_id,
                )
              }
              onMouseOver={() =>
                handleMouseOver(IMPRESSION_TYPES.NEED_TO_THINK)
              }
              onMouseOut={() => handleMouseOut()}
            />
            <img
              className={showWarning === true ? null : "icon-cursor"}
              src={
                item?.impression_id === IMPRESSION_TYPES.GOOD_CANDIDATE
                  ? "/image/good-cv.png"
                  : "/image/good-cv-icon.png"
              }
              alt=""
              onClick={() =>
                onFirstImpressionClick(
                  item?.account_id,
                  item?.job_id,
                  IMPRESSION_TYPES.GOOD_CANDIDATE,
                  item?.status_id,
                )
              }
              onMouseOver={() =>
                handleMouseOver(IMPRESSION_TYPES.GOOD_CANDIDATE)
              }
              onMouseOut={() => handleMouseOut()}
            />

            <img
              className="icon-cursor"
              src={
                item?.is_favorite === 0
                  ? "/image/cv-favt.png"
                  : "/image/favourite-fillicon.png"
              }
              alt=""
              onClick={() => onFvtClick(item?.account_id, item?.job_id)}
            />
            {showGoodCandidate === true ? (
              <div className="good-cv-box">
                <p>Good Candidate</p>
                <img src="/image/review-good-cv.png" alt="" />
              </div>
            ) : null}

            {showNeedToThinkCandidate === true ? (
              <div className="thinkneed-cv-box">
                <p>Need to Think</p>
                <img src="/image/think-cv-need.png" alt="" />
              </div>
            ) : null}

            {showWarning === true ? (
              <div className="attention-box-table">
                <div className="attendtion-heading">
                  <div className="icon-atten">
                    <img src="/image/attention.png" alt="" />
                  </div>
                  <div className="attention-text-table">              
                    <p className="text-atten-box-table">
                      Please Update Status First
                    </p>
                  </div>
                </div>
              </div>
            ) : null}

            {showBadCandidate ? (
              <div className="badreview-cv-box">
                <p>Not a Good Fit</p>
                <img src="/image/bad-cv-review.png" alt="" />
              </div>
            ) : null}
          </div>
        </td>

        <td data-label="Technical Tags:">
          <div className="job-table-employee">
            {item?.tags?.length > 0 &&
              item?.tags
                ?.slice(0, 5)
                ?.map(
                  (t, i) =>
                    t?.name !== null && <button key={i}>{t?.name}</button>,
                )}
          </div>
        </td>

        <td data-label="Candidate Status">
          <div className="job-fillter-table-data">
            <div className="j-company-fillter-table-data">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1-tabeldate"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {item?.status_id === null
                  ? "Candidate Status"
                  : item?.status?.length >= 20
                  ? item?.status?.substring(0, 20) + " ..."
                  : CapitalizeFirstLetter(item?.status)}
              </div>
              <div className="list-search-dropdown-company">
                <ul className="list-table-title-cand">
                  {candidateStatusesList?.slice(1)?.map((candidateStatus) => (
                    <li
                      key={candidateStatus?.id}
                      onClick={() =>
                        onCandidateStatusChangeHandler(
                          item?.account_id,
                          item?.job_id,
                          candidateStatus?.id,
                          item?.status_id,
                        )
                      }
                    >
                      {candidateStatus?.status}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </td>
        <td data-label="Action">
          <div className="cv-cions">
            <div className="cv-resume">
              <img
                src="/image/cv-not.png"
                alt=""
                className="icon-cursor"
                onClick={() => {
                  modelHandler(true);
                  dispatch(setResumeUrl(item?.resume));
                  onResumeClickHandler(
                    item?.account_id,
                    item?.job_id,
                    item?.status_id,
                  );
                }}
              />
              
              <span className="tooltiptext">Resume</span>
            </div>
            <div className="cv-comment">
              <img
                src="/image/cv-comment.png"
                className="icon-cursor"
                alt=""
                onClick={() => toggleCommentBoxHandler()}
              />
              <span className="tooltiptext">Comment</span>
              <OutsideClickHandler
                onOutsideClick={() => {
                  setToggleCommentBox(false);
                }}
              >
                {toggleCommentBox ? (
                  <>
                    {item?.comments === "" ? (
                      <div className="text-comment-box">
                        <p className="comment-heading-text">Comment:</p>
                        <textarea
                          name=""
                          id="comment-area-job"
                          cols="30"
                          rows="3"
                          placeholder="Type here..."
                          onChange={handleCommentTextChange}
                        ></textarea>
                        <br />
                        <button
                          type="submit"
                          className="comment-submit"
                          onClick={() =>
                            handleAddNewCommentHandler(
                              commentText,
                              item?.job_id,
                              item?.account_id,
                            )
                          }
                        >
                          save
                        </button>
                      </div>
                    ) : (
                      <div className="text-comment-box">
                        <div className="comment-add-box">
                          <div className="comment-owner-info">
                            <img
                              src={
                                item?.comments?.avatar_link ??
                                "/image/comment-owner.png"
                              }
                              alt=""
                            />
                            <p className="name-comment-owner">
                              {item?.comments?.first_name +
                                " " +
                                item?.comments?.last_name}
                            </p>
                            <p className="comment-add-date">
                              {/*{item?.comments.created_at
                                ? calculateDaysAgoUTC(item?.comments.created_at) +
                                  " Days ago"
                                : "21 Days ago"}*/}
                              {calculateDaysAgoUTC(item?.comments?.created_at)}
                            </p>
                            {item?.comments?.user_id === user?.id ? (
                              <p
                                className="comment-remove"
                                onClick={() =>
                                  removeCommentClickHandler(
                                    item?.comments?.id,
                                    item?.comments?.candidate_id,
                                    item?.comments?.object_id,
                                  )
                                }
                              >
                                Remove
                              </p>
                            ) : null}
                          </div>
                          {item?.comments?.user_id === user?.id ? (
                            <div className="comment-adit-icon">
                              <Link href="#">
                                <img src="/image/comment-edit.png" alt="" />
                              </Link>
                            </div>
                          ) : null}
                        </div>
                        <div className="comment-info-box">
                          <p>{item?.comments?.comments}</p>
                        </div>
                        <div className="comment-reply">
                          <p>Reply</p>
                          <img src="/image/comment-reply.png" alt="" />
                        </div>
                        <div
                          className="more-comment"
                          onClick={() => {
                            modelCHandler(true, item?.account_id, item?.job_id);
                          }}
                        >
                          <Link href="#">
                            {/*{item?.comments?.comments_count > 0 ? (*/}
                            <p>
                              {item?.comments?.comments_count +
                                " more comments"}
                            </p>
                            {/*) : <p> Add more comments </p>}*/}
                          </Link>
                        </div>
                      </div>
                    )}
                  </>
                ) : null}
              </OutsideClickHandler>
            </div>
            <img src="/image/table-icon-opt.png" alt=""/>
          </div>
        </td>
      </tr>
    </Fragment>
  );
};

export default ApplicantsTable;
