const ProgressBar = ({ currentTab }) => {
  return (
    <div className="step-progressbar-container">
      <ul className="progressbar-steps">
        <li className="step-circle active">Sign up</li>
        <li
          className={`step-circle ${
            (currentTab === "createProfile" || currentTab === "postJob") &&
            "active active-circle"
          }`}
        >
          Create Profile
        </li>
        <li
          className={`step-circle ${
            currentTab === "postJob" && "active active-circle"
          }`}
        >
          Post A Job
        </li>
      </ul>
    </div>
  );
};

export default ProgressBar;
