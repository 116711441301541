import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setLoginModel } from "../../../../redux/slices/AuthSlice";
import { setSelectJobId } from "../../../../redux/slices/JobDetailSlice";
import { setSelectCompanyId } from "../../../../redux/slices/companyDetailSlice";
import {
  jobAppliedAsync,
  setJobAppliedId,
} from "../../../../redux/slices/candidateDashboardSlice";
import getDays from "../../../../utils/getDays";
import NFormatter from "../../../../utils/NFormatter";
import AddToWishlist from "../../../Common/JobRecord/AddToWishlist";
import { USER_TYPES } from "../../../../constants";

const TopMatchRight = ({ selectedJobId, JobData, isApplied }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toggleApplied, setToggleApplied] = useState(false);
  const { user, isEmailVerify } = useSelector((state) => state.auth);

  const onDetailJobHandler = () => {
    navigate(`/job-view-detail/${selectedJobId}`);
  };

  const onDetailCompanyHandler = (id) => {
    navigate(`/company-detail/${id}`);
  };

  const applyNowHandler = () => {
    if (isEmailVerify === false) {
      dispatch(setLoginModel(true));
    } else {
      dispatch(jobAppliedAsync({ userId: user?.id, jobId: selectedJobId }))
        .then((response) => {
          const res = response?.payload;
          if (res?.result === true) {
            dispatch(setJobAppliedId(selectedJobId));
            setToggleApplied(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <div className="Job-Opportunities-row-j-search job-col-right">
        <div className="job-requriments">
          <div className="job-details">
            <div className="job-title top-text-btn-align">
              <a
                className="title-job-link"
                onClick={() => onDetailJobHandler()}
              >
                <h3>{JobData?.name}</h3>
              </a>
              {JobData?.is_promoted === 1 && (
                <Link href="#" className="title-job-link promoted-btn-not">
                  <button>Promoted *</button>
                </Link>
              )}
            </div>
            <div className="job-seclude">
              <div className="job-time">
                <p>
                  {" "}
                  {JobData?.salary_from &&
                    `Salary $${NFormatter(JobData?.salary_from)}`}{" "}
                  {JobData?.salary_to && `- $${NFormatter(JobData?.salary_to)}`}
                </p>
              </div>
              <div className="job-time">
                <p>
                  {JobData?.jobType?.length > 0 && JobData?.jobType[0]?.name}
                </p>
              </div>
            </div>
          </div>
          <div className="job-info">
            <div className="job-services-anchor">
              <a
                onClick={() => onDetailCompanyHandler(JobData?.company?.id)}
              >
                <img src={`/image//company-sm.png`} alt="fitenss" />
                <p>{JobData?.company?.name}</p>
              </a>
            </div>
            <p className="job-discription">
              {JobData?.description?.length >= 250 ? (
                <>{JobData?.description?.substr(0, 250) + "..."}</>
              ) : (
                <>{JobData?.description}</>
              )}{" "}
            </p>
          </div>
          <div className="job-skill-location">
            <div className="job-skills">
              {JobData?.tags?.length > 0 &&
                JobData?.tags?.map((t, i) => <button>{t?.name}</button>)}
            </div>
            {JobData?.jobCities?.length !== 0 && (
              <div className="job-location">
                <p>Location</p>
                <img src="/image/Location.png" alt="Location" />
                <p className="job-city">
                  {JobData?.jobCities?.length === 1
                    ? JobData?.jobCities[0]?.name
                    : "Multiple Cities"}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="job-announcements">
          <div className="job-announcements-col btn-size">
            <a onClick={() => onDetailJobHandler()}>
              <button>View detailed Job Position</button>
            </a>
            <div className="job-time-wishlist">
              <div className="job-announcements-status">
                <p>
                  {JobData?.date
                    ? getDays(JobData?.date) + " Days ago"
                    : "21 Days ago"}
                </p>
              </div>

              {isEmailVerify && user?.user_type === "employer" ? (
                ""
              ) : (
                <>
                  <div
                    className="job-apply icon-cursor"
                    onClick={() => applyNowHandler()}
                  >
                    <img src="/image/apply.png" alt="apply" />
                    <p>
                      {" "}
                      {isApplied === 1
                        ? "Applied"
                        : toggleApplied
                        ? "Applied"
                        : "Apply Now"}
                    </p>
                  </div>
                  <AddToWishlist
                    jobId={JobData?.id}
                    wishlist={JobData?.whishlist}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="divider-two"></div>
    </>
  );
};

export default TopMatchRight;
