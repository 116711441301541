import { Link } from "react-router-dom";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";

const EmpProfileView = ({ baseInfo, city, companyInfo }) => {
  console.log("companyInfo", companyInfo)
  return (
    <>
      {companyInfo?.logo === null || companyInfo?.logo === undefined ? (
        <div className="candidtae-profile-image-avatar">
          <img src="/image/company-lx.png" alt="" />
        </div>
      ) : (
        <div className="candidtae-profile-image-avatar">
          <img src={companyInfo?.logo} alt="" />
        </div>
      )}

      <div className="candidate-information">
        <div className="candidate-profile-date-after">
          <h5>{capitalizeFirstLetter(companyInfo?.name)}</h5>
          {city?.cityName && (
            <div className="candiate-info-heading">
              <Link href="#">
                <img src="/image/candidate-location.png" alt="" />
                <p className="candidate-info-text">{city?.cityName}</p>
              </Link>
            </div>
          )}

          {companyInfo?.website && (
            <div className="candiate-info-heading">
              <Link href="#">
                <img src="/image/website-profile-link.png" alt="" />
                <p className="candidate-info-text">{companyInfo?.website}</p>
              </Link>
            </div>
          )}
          {baseInfo?.email && (
            <div className="candiate-info-heading">
              <a href="mailto:teklavasadze@gmail.com">
                <img src="/image/mail-can.png" alt="" />
                <p className="candidate-info-text">{baseInfo?.email}</p>
              </a>
            </div>
          )}
          {baseInfo?.phone && (
            <div className="candiate-info-heading">
              <a href="tel:+995599132862">
                <img src="../image/call-can.png" alt="" />
                <p className="candidate-info-text">{baseInfo?.phone}</p>
              </a>
            </div>
          )}
          {companyInfo?.linkedin && (
            <div className="candiate-info-heading">
              <Link href="#">
                <img src="/image/linkdin.png" alt="" />
                <p className="candidate-info-text">{companyInfo?.linkedin}</p>
              </Link>
            </div>
          )}
        </div>
        {companyInfo?.description && (
          <div className="employee-profile-compnay-data">
            <p className="company-name-profile-date">Company Information</p>
            <p className="employee-profile-company-content-date">
              {companyInfo?.description}
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default EmpProfileView;
