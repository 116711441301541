import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import JobRecordLeft from "../../Common/JobRecord/JobRecordLeft";
import JobRecordRight from "../../Common/JobRecord/JobRecordRight";
import API from "../../../api";
import "./JobSection.css";

const JobSection = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [count, setCount] = useState(10);
  const [data, setData] = useState([]);
  const countHandler = () => {
    if (count < 30) setCount((prevCount) => prevCount + 10);
    else navigate("/job-search");
  };
  useEffect(() => {
    async function getLatestJob() {
      try {
        let params = `?`;
        if (user !== null) {
          params += `user_id=${user?.id}`;
        }
        await API.get(`/job-board/new_jobs${params}`)
          .then((response) => {
            if (response?.status === 200) {
              let res = response?.data;
              setData(res);
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
    getLatestJob();
  }, []);
  if (data?.length === 0) return null;
  return (
    <>
      <section className="new-job">
        <div className="container container-site">
          <div className="job-sec-wrape">
            <div className="job-heading">
              <h2>New Job Opportunities</h2>
            </div>
            {data?.length > 0 &&
              data?.slice(0, count)?.map((prod) => (
                <>
                  <div className="divider"></div>
                  <div className="Job-Opportunities-row">
                    <JobRecordLeft JobData={prod} />
                    <JobRecordRight
                      date={prod?.date}
                      selectedJobId={prod?.id}
                      isApplied={prod?.is_applied}
                      wishList={prod?.whishlist}
                    />
                  </div>
                </>
              ))}
          </div>
          <div className="divider"></div>
        </div>
        {data?.length > 10 && (
          <div className="see-more-job" onClick={() => countHandler()}>
            <Link href="#">
              <button>See more</button>
            </Link>
          </div>
        )}
      </section>
    </>
  );
};
export default JobSection;
