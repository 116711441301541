import { Link } from "react-router-dom";
import "./CountrySection.css";

const CountrySection = () => {
  return (
    <>
      <section className="countries-work">
        <div className="container container-site">
          <div className="countries-work-heading ">
            <h2>Countries, where we work</h2>
          </div>
          <div className="country-flages-img ">
            <div className="countries-work-location ">
              <figure>
                <img src="/image/canada.png " alt="canada " />
                <figcaption>Canada</figcaption>
              </figure>
              <figure>
                <img src="/image/USA.png " alt="USA " />
                <figcaption>USA</figcaption>
              </figure>
              <figure>
                <img src="/image/Pakistan.png " alt="Pakistan " />
                <figcaption>Pakistan</figcaption>
              </figure>
              <figure>
                <img src="/image/North-Macedonia.png " alt="North-Macedonia " />
                <figcaption>North Macedonia</figcaption>
              </figure>
              <figure>
                <img src="/image/Albania.png " alt="Albania " />
                <figcaption>Albania</figcaption>
              </figure>
              <figure>
                <img src="/image/Bosnia.png " alt="Bosnia " />
                <figcaption>
                  Bosnia and
                  <br />
                  Herzegovina
                </figcaption>
              </figure>
              <figure>
                <img src="/image/Brazil.png " alt="Brazil " />
                <figcaption>Brazil</figcaption>
              </figure>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-sec ">
        <div className="container container-site">
          <div className="ready-start-row ">
            <div className="ready-start-image ">
              <img src="/image/last-above-footer.png " alt=" " />
            </div>
            <div className="ready-start-info ">
              <h4 className="team-remote ">
                WorkBridge Makes growing remote teams effortless
              </h4>
              <h4 className="contect-readt-start ">Ready to get Started?</h4>
              <div className="contact-btn ">
                {" "}
                <a href="# ">
                  <button>Contact US</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CountrySection;
