import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { FvtData } from "../../../data/employer";
import ApplicantsTable from "../../../components/Common/ApplicantsTable/ApplicantsTable";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";
import ResumeModel from "../../../components/Common/ResumeModel/ResumeModel";
import CommentModel from "../../../components/Common/CommentModel/CommentModel";
import API from "../../../api";
import {
  candidateFirstImpressionChangeAsync,
  candidateStatusChangeAsync,
  changeCandidateStatusToResumeReviewedAsync,
  favoriteCandidateAsync,
} from "../../../redux/slices/employerDashboardSlice";
import "./index.css";
import { CANDIDATE_STATUSES } from "../../../constants";
import {
  addJobCommentAsync,
  emptyCommentsModalData,
  setCandidateId,
  setJobId,
} from "../../../redux/slices/commentModalSlice";

const EmpCandidateList = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { candidateListId } = useSelector((state) => state.employerDashboard);
  const { candidateStatuses } = useSelector((state) => state.employerDashboard);
  const [count, setCount] = useState(10);
  const [toggleModel, setToggleModel] = useState(false);
  const [toggleModelC, setToggleModelC] = useState(false);
  const [candidatesList, setCandidatesList] = useState([]);
  const [jobDetail, setJobDetail] = useState({});

  const commentRemoved = (commentId, commentToReplace) => {
    const updatedCandidates = candidatesList.map((candidate) => {
      if (candidate?.comments?.id === commentId) {
        const newComment = {
          ...commentToReplace,
          comments_count: candidate?.comments?.comments_count > 0 ? candidate?.comments?.comments_count - 1 : 0,
        };

        if (commentToReplace === "") {
          return {
            ...candidate,
            comments: "",
          };
        }

        return {
          ...candidate,
          comments: {
            ...newComment,
          },
        };
      }
      return candidate;
    });
    setCandidatesList(updatedCandidates);
  };

  const commentAdded = (candidateId, jobId, comment) => {
    const updatedCandidatesList = candidatesList.map((candidate) => {
      if (
        candidate?.account_id === candidateId &&
        candidate?.job_id === jobId
      ) {
        const newComment = {
          ...comment,
          comments_count: candidate?.comments?.comments_count + 1,
        };

        return {
          ...candidate,
          comments: {
            ...newComment,
          },
        };
      }
      return candidate;
    });
    setCandidatesList(updatedCandidatesList);
  };
  const handleNewCommentSubmit = async (text, jobId, candidateId) => {
    if (text === null || text === undefined || text === "") return;
    if (jobId === null || jobId === undefined || jobId === "") return;
    if (candidateId === null || candidateId === undefined || candidateId === "")
      return;

    dispatch(
      addJobCommentAsync({
        userId: user?.id,
        jobId: jobId,
        candidateId: candidateId,
        commentText: text,
      }),
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedCandidates = candidatesList.map((candidate) => {
            if (
              candidate?.account_id === candidateId &&
              candidate?.job_id === jobId
            ) {
              return {
                ...candidate,
                comments: {
                  ...res?.data[0]?.comments[0],
                  comments_count: 0,
                },
              };
            }
            return candidate;
          });
          setCandidatesList(updatedCandidates);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const modelCHandler = (v, candidate_id, job_id) => {
    setToggleModelC(v);

    if (v === false) {
      dispatch(emptyCommentsModalData());
      return;
    }
    if (
      candidate_id === null ||
      candidate_id === undefined ||
      candidate_id === "" ||
      job_id === null ||
      job_id === undefined ||
      job_id === ""
    ) {
      setToggleModelC(false);
      return;
    }

    dispatch(setJobId(job_id));
    dispatch(setCandidateId(candidate_id));
  };
  const modelHandler = (v) => setToggleModel(v);

  const countHandler = () => {
    if (count < candidatesList?.length) setCount((prevCount) => prevCount + 10);
  };
  const onFirstImpressionClick = async (
    candidateId,
    jobId,
    impressionId,
    currentStatusId,
  ) => {
    if (currentStatusId === CANDIDATE_STATUSES.NEW_APPLICANT) return;
    if (candidateId === null || jobId === null || impressionId === null) return;

    dispatch(
      candidateFirstImpressionChangeAsync({
        candidateId: candidateId,
        jobId: jobId,
        impressionId: impressionId,
      }),
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedApplicants = candidatesList.map((applicant) => {
            if (
              applicant?.account_id === candidateId &&
              applicant?.job_id === jobId
            ) {
              return {
                ...applicant,
                impression_id: res?.impression_removed === 1 ? 0 : impressionId,
              };
            }
            return applicant;
          });
          setCandidatesList(updatedApplicants);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onResumeClickHandler = async (candidateId, jobId, currentStatus) => {
    if (currentStatus !== CANDIDATE_STATUSES.NEW_APPLICANT) return;

    dispatch(
      changeCandidateStatusToResumeReviewedAsync({
        candidateId: candidateId,
        jobId: jobId,
      }),
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedApplicants = candidatesList.map((applicant) => {
            if (
              applicant?.account_id === candidateId &&
              applicant?.job_id === jobId
            ) {
              return {
                ...applicant,
                status_id: res?.status_id,
                status: res?.status,
              };
            }
            return applicant;
          });
          setCandidatesList(updatedApplicants);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onCandidateStatusChangeHandler = async (
    candidateId,
    jobId,
    statusId,
    currentStatusId,
  ) => {
    if (candidateId === null || jobId === null || statusId === null) return;
    if (currentStatusId === statusId) return;
    dispatch(
      candidateStatusChangeAsync({
        candidateId: candidateId,
        statusId: statusId,
        jobId: jobId,
      }),
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedApplicants = candidatesList.map((applicant) => {
            if (
              applicant?.account_id === candidateId &&
              applicant?.job_id === jobId
            ) {
              const { id, status } = candidateStatuses.find(
                (item) => item.id === statusId,
              );

              return {
                ...applicant,
                status_id: id,
                status: status,
              };
            }
            return applicant;
          });
          setCandidatesList(updatedApplicants);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onFvtClick = async (account_id, job_id) => {
    dispatch(
      favoriteCandidateAsync({
        userId: user?.id,
        accountId: account_id,
        jobId: job_id,
      }),
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedCandidates = candidatesList?.map((candidate) => {
            if (
              candidate?.account_id === account_id &&
              candidate?.job_id === job_id
            ) {
              return {
                ...candidate,
                is_favorite: candidate?.is_favorite === 0 ? 1 : 0,
              };
            }
            return candidate;
          });
          setCandidatesList(updatedCandidates);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getEmpCandidatesListData = async () => {
    if (candidateListId !== null) {
      const formData = new FormData();
      formData.append("user_id", user?.id);
      formData.append("job_id", candidateListId);

      try {
        await API.post(`/get-candidates`, formData)
          .then((response) => {
            if (response?.status === 200 || response?.status === 201) {
              let res = response?.data;
              setCandidatesList(res?.candidates);
              setJobDetail(res?.jobDetail);
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("else");
    }
  };

  useEffect(() => {
    getEmpCandidatesListData();
  }, [candidateListId]);

  console.log("name", jobDetail);
  return (
    <>
      <section className="new-job">
        <div className="container container-site">
          <div className="job-search-box-row">
            <div className="job-heading smilar-job-heading">
              <h2>{jobDetail?.name ? jobDetail?.name : ""}</h2>
            </div>
          </div>
          <div className="employee-searchbox-btn">
            <div className="employee-list-btn">
              <Link to="/employer-Favourit-list">
                <button>View Favorites</button>
              </Link>
            </div>
            <div className="search-list-box">
            <div className="filter-search-box">
                <img src="/image/job-search.png" alt="" />
                <input
                  type="text"
                  name="search"
                  id="jon-search-box"
                  placeholder="Search Applicants"
                  className="form-font-f"                 
                />
              </div>

              {/* <div className="filter-search-box">
                <img src="/image/job-search.png" alt="" />
                <input
                  type="text"
                  name=""
                  id="candidate-list-search"
                  placeholder="Search Jobs"
                  className="form-font-f"
                />
                <img src="/image/Filter.png" alt="" />
              </div> */}
              <div className="data-not-found hide-notfound">
                <img src="/image/notfound.png" alt="" />
                <p className="search-text-notfound">No candidate found</p>
              </div>
              {/* <div className="list-search-dropdown">
                <ul className="list-job-title">
                  <li>React Developer</li>
                  <li>React Developer</li>
                  <li>React Developer</li>
                  <li>React Developer</li>
                </ul>
              </div> */}
            </div>
          </div>
          <div className="divider"></div>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th scope="col">Full name</th>
                <th scope="col">Job Title</th>
                <th scope="col">Date Applied</th>
                <th scope="col">First Impression</th>
                <th scope="col" className="technical-tags-row">
                  Technical Tags:
                </th>
                <th scope="col">Candidate Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {candidatesList?.length > 0 &&
                candidatesList
                  ?.slice(0, count)
                  ?.map((item, index) => (
                    <ApplicantsTable
                      index={index}
                      item={item}
                      candidateStatusesList={candidateStatuses}
                      onFvtClick={onFvtClick}
                      modelHandler={modelHandler}
                      modelCHandler={modelCHandler}
                      onCandidateStatusChangeHandler={
                        onCandidateStatusChangeHandler
                      }
                      onFirstImpressionClick={onFirstImpressionClick}
                      onResumeClickHandler={onResumeClickHandler}
                      handleAddNewCommentHandler={handleNewCommentSubmit}
                      handleRemovedComment={commentRemoved}
                    />
                  ))}
            </tbody>
          </table>
        </div>
        {candidatesList?.length > 10 && (
          <div class="see-more-job" onClick={() => countHandler()}>
            <Link href="#">
              <button>See more</button>
            </Link>
          </div>
        )}
      </section>
      <WorldConnect />
      {toggleModel && <ResumeModel modelHandler={modelHandler} />}
      {toggleModelC && (
        <CommentModel
          modelCHandler={modelCHandler}
          commentAdded={commentAdded}
          commentRemoved={commentRemoved}
        />
      )}
    </>
  );
};

export default EmpCandidateList;
