import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import "./CookiesSection.css";
import { useState } from "react";

const CookiesSection = () => {
  const cookies = new Cookies();
  const [isCookies, setIsCookies] = useState(
    false || cookies.get("acceptCookie")
  );

  const cookiesHandler = () => {
    cookies.set("acceptCookie", true, { path: "/" });
    setIsCookies(true);
  };

  const declineCookiesHandler = () => {
    setIsCookies(true);
  };

  return (
    <>
      {!isCookies && (
        <section className="cookies-sec">
          <div className="cookies-row">
            <div className="cookies-heading">
              <img src="/image/cookies.png" alt="cookies" />
              <p>This website uses cookies</p>
            </div>
            <div className="cookies-text-btn">
              <p className="cookies-paragrapgh">
                We use cookies to make the website function properly, improve
                performance and serve targeted advertisements. Note that if you
                do not accept optional cookies, your experience may be
                disrupted. By continuing to use this site, you consent to the
                use of cookies as specified in your{" "}
                <a href="#"> cookie preferences</a>. You can update your cookie
                preferences at any time. For more information please see our{" "}
                <a href="#">Privacy Policy.</a>
              </p>
              <div className="cookies-btns">
                <Link onClick={() => declineCookiesHandler()}>
                  <button className="decline-btn">Decline</button>
                </Link>
                <Link onClick={() => cookiesHandler()}>
                  <button className="allow-btn">Allow</button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default CookiesSection;
