import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import getDays from "../../../utils/getDays";
import {
  setActiveSubTab,
  setActiveTab,
  setPostJobId,
  setPostJobMode,
} from "../../../redux/slices/AuthSlice";
import "./JobRecord.css";

const JobDraftRight = ({ selectedJobId, date, onChangeJobStatus }) => {
  const dispatch = useDispatch();

  const postJobClickHandler = () => {
    onChangeJobStatus(selectedJobId);
  };

  const editJobClickHanlder = (id) => {
    dispatch(setActiveTab("employer"));
    dispatch(setActiveSubTab("postJob"));
    dispatch(setPostJobMode("edit"));
    dispatch(setPostJobId(id));
  };

  return (
    <div className="job-announcements">
      <div className="job-announcements-col btn-size">
        <div className="post-job-table">
          <Link href="#" onClick={() => postJobClickHandler()}>
            <button>Post This Job</button>
          </Link>
        </div>
        <div
          className="edit-post-job-table"
          onClick={() => editJobClickHanlder(selectedJobId)}
        >
          <Link to="/signup">
            <button>Edit</button>
          </Link>
        </div>
        <div class="job-time-wishlist">
          <div className="job-eployee-table-col">
            <p className="day">
              {date ? getDays(date) + " Days ago" : "21 Days ago"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDraftRight;
