export const PublicDomains = [
  "gmail.com",
  "yahoo.com",
  "outlook.com",
  "icloud.com",
  "aol.com",
  "protonmail.com",
  "mail.com",
  "zoho.com",
  "yandex.com",
  "mail.ru",
  "gmx.com",
  "inbox.com",
  "qq.com",
  "163.com",
  "126.com",
  "gmx.net",
  "rediffmail.com",
  "web.de",
  "naver.com",
  "daum.net",
  // Add more public domains here
];

export const USER_TYPES = Object.freeze({
  CANDIDATE: "candidate",
  EMPLOYER: "employer",
  // add more userTypes here
});

export const IMPRESSION_TYPES = Object.freeze({
  NOT_A_GOOD_FIT: 1,
  NEED_TO_THINK: 2,
  GOOD_CANDIDATE: 3,
});

export const CANDIDATE_STATUSES = Object.freeze({
  NEW_APPLICANT: 1,
  RESUME_REVIEWED: 2,
  DO_NOT_CONTACT: 3,
  OVERQUALIFIED_FOR_THIS_POSITION: 4,
  EXPERIENCE_NOT_RELEVANT: 5,
  NEED_TO_THINK_ABOUT_HIM: 6,
  NO_EXPERIENCE_AT_ALL: 7,
});

export const JOB_POST_DECISION = Object.freeze({
  COPY_PASTE: 'COPY_PASTE',
  ZOE: 'ZOE',
});

export const JOB_POST_FIELDS_AI = Object.freeze({
  JOB_SUMMARY: 'job_summary',
  ROLES_AND_RESPONSIBILITIES: 'roles_n_responsibilities',
  SKILLS: 'skills',
  MUST_HAVE: 'must_have_skills',
  SKILL_TAGS: 'key_skill_tags',
  NICE_TO_HAVE: 'nice_to_have_skills',
  BENEFITS: 'benefits',
  WORKING_HOURS: 'working_hours',
  INTERVIEW_PROCESS: 'interview_process',
  RECRUITER_NOTES: 'recruiter_notes',
});


export const ADMIN_SIDE_BAR  = [
  {
    id: "1",
    name: "Modules",
    value: "Modules",
    path: "/admin-dashboard",
    subMenuOpen: false,
    icon: "/image/Modules.png",
    subMenuOption: [],
  },
  {
    id: "2",
    name: "System Setup",
    value: "Department",
    path: "/admin-department",
    subMenuOpen: false,
    icon: "/image/system-nav.png",
    subMenuOption: [
      // {
      //   id: "21",
      //   name: "Countries",
      //   value: "Countries",
      //   path: "#",
      //   subMenuOpen: true,
      //   icon: "/image/country-nav.png",
      //   subMenuOption: [
      //     {
      //       id: "211",
      //       name: "Countries",
      //       value: "Countries",
      //       path: "#",
      //       subMenuOpen: false,
      //       icon: "/image/country-nav.png",
      //     },
      //     {
      //       id: "212",
      //       name: "Country Vacations",
      //       value: "Country Vacations",
      //       path: "#",
      //       subMenuOpen: false,
      //       icon: "/image/country-vac.png",
      //     },
      //   ],
      // },
      // {
      //   id: "22",
      //   name: "Statuses",
      //   value: "Statuses",
      //   path: "#",
      //   subMenuOpen: false,
      //   icon: "/image/Statuses-nav.png",
      // },
      {
        id: "23",
        name: "Department",
        value: "Department",
        path: "/admin-department",

        subMenuOpen: false,
        icon: "/image/deprt-nav.png",
      },
      {
        id: "24",
        name: "Roles",
        value: "Roles",
        path: "/admin-role",
        subMenuOpen: false,
        icon: "/image/role-nav.png",
      },
      {
        id: "25",
        name: "Users",
        value: "Users",
        path: "/admin-user",
        subMenuOpen: false,
        icon: "/image/user-nav.png",
      },
      // {
      //   id: "26",
      //   name: "Preferences",
      //   value: "Preferences",
      //   path: "#",
      //   subMenuOpen: false,
      //   icon: "/image/Preferences-nav.png",
      // },
    ],
  },
  // {
  //   id: "3",
  //   name: "Job Portal",
  //   value: "Job Portal",
  //   path: "#",
  //   subMenuOpen: false,
  //   icon: "/image/job-nav.png",
  //   subMenuOption: [
  //     {
  //       id: "31",
  //       name: "Job Titles",
  //       value: "Job Titles",
  //       path: "#",
  //       subMenuOpen: false,
  //       icon: "/image/job-nav.png",
  //     },
  //     {
  //       id: "32",
  //       name: "Job Description",
  //       value: "Job Description",
  //       path: "#",
  //       subMenuOpen: false,
  //       icon: "/image/job-nav.png",
  //     },
  //     {
  //       id: "33",
  //       name: "Department",
  //       value: "Department",
  //       path: "#",
  //       subMenuOpen: false,
  //       icon: "/image/deprt-nav.png",
  //     },
  //     {
  //       id: "34",
  //       name: "Technical Tags",
  //       value: "Technical Tags",
  //       path: "#",
  //       subMenuOpen: false,
  //       icon: "/image/tag-nav.png",
  //     },
  //     {
  //       id: "35",
  //       name: "Job Posts",
  //       value: "Job Posts",
  //       path: "#",
  //       subMenuOpen: false,
  //       icon: "/image/job-nav.png",
  //     },
  //     {
  //       id: "36",
  //       name: "Companies",
  //       value: "Companies",
  //       path: "#",
  //       subMenuOpen: false,
  //       icon: "/image/comapny-nav.png",
  //     },
  //   ],
  // },
  // {
  //   id: "4",
  //   name: "Reporting",
  //   value: "Reporting",
  //   path: "#",
  //   subMenuOpen: false,
  //   icon: "/image/Reporting-nav.png",
  //   subMenuOption: [],
  // },
]