const CustomSelect = ({
  label,
  label2,
  logo,
  borderRed,
  errorMsg,
  value,
  data,
  onClickHandler,
}) => {
  return (
    <>
      <div
        className={`signup-jobs-option f-feild ${borderRed && "error-input"}`}
      >
        <label for="fjobtitles">{label}</label>
        <br />
        <div className="industry-feild-frm bg-pass-f">
          <div
            id="dropdown-boxId-2"
            className="company-form-dropdownBox-f"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {value === "" ? label2 : value}
          </div>
          <div className="icon-hide">
            {borderRed ? (
              <img src="/image/warning.png" alt="" />
            ) : (
              <img src="/image/form-d-f.png" alt="" />
            )}
          </div>
          <div
            id="company-dropList-t"
            class="dropdown-form-f company-drop-list-t"
          >
            <ul>
              {data?.length > 0 &&
                data.map((j, i) => (
                  <li
                    class="icon-cursor-f"
                    onClick={() => onClickHandler(j?.id, j?.name)}
                  >
                    <img src={logo} alt="" />
                    {j?.name}{" "}
                  </li>
                ))}
            </ul>
          </div>
        </div>
        {errorMsg && <p className="error-msg">{errorMsg}</p>}
      </div>
    </>
  );
};

export default CustomSelect;
