import { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { setActiveSubTab } from "../../../../redux/slices/AuthSlice";
import Message from "../EmployerForm/Message";

const CreateProfileForm = ({
  errorMsg,
  loader,
  companyLogo,
  companyLogoModelHandler,
  createProfileHandler,
}) => {
  const dispatch = useDispatch();
  const { signUpEmpCompanyLogoFile } = useSelector((state) => state.auth);

  const [selectedImageName, setSelectedImageName] = useState("");

  const formik = useFormik({
    initialValues: {
      websiteUrl: "",
      companyName: "",
      companyInfo: "",
      linkedinURL: "",
      files: null,
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      websiteUrl: Yup.string().required("Please enter valid website URL"),
      companyName: Yup.string().required("Please enter company Name"),
      companyInfo: Yup.string().required("Please enter Company Information"),
      files: Yup.mixed()
        .required("Please upload a company logo")
        .test(
          "fileType",
          "File can be a JPEG or PNG document",
          (value) => !value || ["image/png", "image/jpeg"].includes(value?.type)
        )
        .test(
          "fileSize",
          "The file size should not exceed 10 MB",
          (value) => !value || value?.size <= 10 * 1024 * 1024
        ),
    }),
    onSubmit: async (data, { resetForm }) => {
      createProfileHandler(data);
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const handleImageChange = async (event) => {
    companyLogoModelHandler(true);
    // const file = event.target.files[0];

    // if (file) {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     setSelectedImageName(file.name);
    //     formik.setFieldValue("files", file);
    //   };
    //   reader.readAsDataURL(file);
    // } else {
    //   setSelectedImageName("");
    //   formik.setFieldValue("files", null);
    // }
  };

  useEffect(() => {
    if (companyLogo !== null) {
      formik.setFieldValue("files", companyLogo);
      setSelectedImageName(companyLogo?.name);
    }
  }, [companyLogo]);
  
  return (
    <>
      <div className="candidate-signup-form-feild">
        <form onSubmit={formik.handleSubmit}>
          <div
            className={`signup-website-url f-feild ${
              isFormFieldValid("websiteUrl") && "error-input"
            }`}
          >
            <label for="flink">Website URL</label>
            <input
              className="form-font-f"
              type="text"
              id=""
              placeholder="Website URL"
              name="websiteUrl"
              value={formik.values.websiteUrl}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {getFormErrorMessage("websiteUrl")}
          </div>

          <div
            className={`signup-company-name f-feild ${
              isFormFieldValid("companyName") && "error-input"
            }`}
          >
            <label for="flink">Company Name</label>
            <input
              className="form-font-f"
              type="text"
              id=""
              placeholder="Company Name"
              name="companyName"
              value={formik.values.companyName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {getFormErrorMessage("companyName")}
          </div>

          <div
            className={`signup-company-information f-feild ${
              isFormFieldValid("companyInfo") && "error-input"
            }`}
          >
            <label for="flink">Company Information</label>
            <br />
            <textarea
              className="form-font-f text-area-size"
              rows="10"
              cols="100"
              name="companyInfo"
              value={formik.values.companyInfo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <p className="msg-show-blowe-textarea">Max Limit (100) words</p>
            {getFormErrorMessage("companyInfo")}
          </div>
          <div className={`signup-linkedIn-link f-feild`}>
            <label for="flink">LinkedIn Link</label>
            <input
              className="form-font-f"
              type="text"
              id=""
              placeholder="Enter Your LinkedIn Profile Here"
              name="linkedinURL"
              value={formik.values.linkedinURL}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="signup-file-company-logo f-feild">
            <label for="file-input">
              <strong>
                <img src="/image/custom-icon.png" alt="pic" />
              </strong>
              <input
                // type="file"
                id="file-input"
                name="files"
                placeholder="Choose file"
                onClick={handleImageChange}
                //  onChange={handleImageChange}
              />

              <p className="company-logo-file">
                {selectedImageName !== ""
                  ? selectedImageName
                  : "Upload Company Logo"}
              </p>
              <span>(Upload file in .Jpeg .Png)</span>
              <p className="file-size">Max size 10MB</p>
            </label>
            {getFormErrorMessage("files")}
          </div>     
          <div className="candidate-from-signup-create btn-next-pre">
            <button
              className="btn-next-previous btn-next-pre-toggle"
              onClick={() => {
                dispatch(setActiveSubTab("signUp"));
              }}
            >
              Previous
            </button>
            <button
              className="btn-next-previous btn-next-pre-toggle"
              type="submit"
            >
              {loader ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Next"
              )}
            </button>   
          </div>
          <br/>  
          <Message errorMsg={errorMsg} />
        </form>
      </div>
    </>
  );
};

export default CreateProfileForm;
