import { Link } from "react-router-dom";
import "./Banner.css";

const Banner = () => {
  return (
    <>
      <section className="banner-top container-site">
        <div className="container">
          <div className="banner-row ">
            <div className="col-1-banner ">
              <img src="/image/banner-1.png " alt="boylaptop " />
            </div>
            <div className="col-2-banner ">
              <h1>
                Reach your <span>potential,</span>
                <br /> globally.
              </h1>
              <p className="banner-text ">
                Unlock your career potential with WorkBridge where tech
                professionals find dream jobs.
              </p>
              <div className="button-stack ">
                <Link to="/job-search">
                  <button>Your Future Starts Here!</button>
                </Link>
              </div>
              <div className="arrow-picture ">
                <img className="vector " src="/image/Vector 1.png " alt=" " />
              </div>
            </div>
            <div className="col-3-banner ">
              <img src="/image/banner-2.png " alt="girl with laptop " />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
