import { useState } from "react";
import { useFormik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import OutsideClickHandler from "react-outside-click-handler";
import API from "../../../api";
import {
  setActiveSubTab,
  setActiveTab,
  setEmailVerify,
  setLoginModel,
  setLoginUser,
  setLoginUserName,
  setSignUpEmp,
} from "../../../redux/slices/AuthSlice";
import "./LoginModel.css";

const LoginModel = ({ modelCHandler }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openLoginModel } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Please enter valid email address"
        )
        .required("Please enter email"),
      password: Yup.string().required("Please enter password"),
    }),
    onSubmit: async (data, { resetForm }) => {
      const { email, password } = data;
      try {
        setLoader(true);
        await API.post(`/auth/login`, {
          email,
          password,
        })
          .then((response) => {
            if (response?.status === 200 || response?.status === 201) {
              const res = response?.data;
              const user = response?.data?.user;

              if (res?.result === true) {
                dispatch(setLoginUser(res));
                dispatch(setLoginUserName(user?.first_name));
                dispatch(setEmailVerify(true));
                dispatch(setLoginModel(false));
                localStorage.setItem("token", res?.access_token);
                if (res?.user?.user_type === "candidate")
                  navigate("/candidate-dashboard");
                if (res?.user?.user_type === "employer")
                  navigate("/employer-dashboard");
              }
              setLoader(false);
            }
          })
          .catch((error) => {
            if (error) {
              const { response } = error;
              const { data } = response;
              setErrorMsg(data?.message);
              setTimeout(() => {
                setErrorMsg("");
              }, 5000);
              console.log(error);
            }
            setLoader(false);
          });
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const onSignUpHandler = () => {
    dispatch(setActiveTab("candidate"));
    dispatch(setActiveSubTab("signUp"));
    dispatch(setSignUpEmp({}));
    dispatch(setLoginModel(false));
  };

  return (
    <section
      className={`model-comment-popup ${
        openLoginModel === false && "hide-model-popup"
      }`}
    >
      <div className="popup-bg-job">
        <OutsideClickHandler
          onOutsideClick={() => {
            dispatch(setLoginModel(false));
          }}
        >
          <div className="popup-job-sec">
            <div className="model-left-right-job">
              <div className="col-left-model"></div>
              <div className="col-right-model">
                <div
                  className="close-img-resume"
                  onClick={() => dispatch(setLoginModel(false))}
                >
                  <img src="/image/profile-cross.jpg" alt="" />
                </div>
                <div className="model-logo-job-sec">
                  <img src="/image/WORKBRIDGE.png" alt="" />
                </div>
                <div className="content-f">
                  <h5>Welcome</h5>
                  <p className="login-f-model">
                    Please enter your login Details
                  </p>
                  <p className="error-msg error-email">{errorMsg}</p>
                </div>
                <div className="model-signin">
                  <form onSubmit={formik.handleSubmit}>
                    <div
                      className={`candidate-username ${
                        isFormFieldValid("email") && "error-input"
                      }`}
                    >
                      <div className="mail-log-waring-icon">
                        <div className="email-icon-logo">
                          <img src="/image/j-m-User.svg" alt="" />
                        </div>
                        <input
                          type="text"
                          id="email-j-model"
                          placeholder="Email"
                          name="email"
                          className="form-font-job-m"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {getFormErrorMessage("email")}
                    </div>
                    <div className="candidate-password">
                      <div
                        className={`signup-passowrd-job ${
                          isFormFieldValid("password") && "error-input"
                        }`}
                      >
                        <div className="password-icon-job">
                          <div className="password-icon-logo">
                            <img src="/image/j-m-Password.svg" alt="" />
                          </div>
                          <input
                            id="password-field-job"
                            type={passwordShown ? "text" : "password"}
                            placeholder="Password"
                            name="password"
                            className="form-font-job-m"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <div className="icon-hide">
                            {isFormFieldValid("password") ? (
                              <img src="/image/warning.png" alt="" />
                            ) : (
                              <>
                                <img
                                  src="/image/hide-password.png"
                                  alt=""
                                  className={
                                    passwordShown && "password-icon-togle"
                                  }
                                  onClick={() => setPasswordShown(true)}
                                />
                                <img
                                  src="/image/password.png"
                                  alt=""
                                  className={
                                    !passwordShown && "password-icon-togle"
                                  }
                                  onClick={() => setPasswordShown(false)}
                                />
                              </>
                            )}
                          </div>
                        </div>
                        {getFormErrorMessage("password")}
                      </div>
                      <div className="remember-forget-password">
                        <input
                          id="rememberme"
                          name="rememberme"
                          value="remember"
                          type="checkbox"
                        />{" "}
                        &nbsp;Remember me
                        <Link
                          to="/forgot-password"
                          onClick={() => dispatch(setLoginModel(false))}
                        >
                          {" "}
                          <p className="forget-password">Forgot password?</p>
                        </Link>
                      </div>
                      <div className="candidate-from-submit">
                        <button className="form-submit-job-model">
                          {loader ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            "Sign in"
                          )}
                        </button>
                      </div>
                    </div>
                    {/* <div className="or-divider-job ">
                      <div className="divider-one-job"></div>
                      <div className="text-or-job">Or</div>
                      <div className="divider-one-job"></div>
                    </div>
                    <div className="model-login-google">
                      <Link href="#">
                        <img src="/image/goole.png" alt="goole" />
                        <p>Continue with Google</p>
                      </Link>
                    </div> */}
                    <div className="signup-here-job">
                      <p>
                        Don’t have an account?{" "}
                        <Link to="/signup" onClick={() => onSignUpHandler()}>
                          <span>Sign up</span>
                        </Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    </section>
  );
};

export default LoginModel;
