import { useState } from "react";
import { Link } from "react-router-dom";
import JobRecordLeft from "../../Common/JobRecord/JobRecordLeft";
import JobRecordRight from "../../Common/JobRecord/JobRecordRight";
import { JobDetailData } from "../../../data/job";

const SimilarJobs = ({ similarJobs }) => {
  const [count, setCount] = useState(10);

  const countHandler = () => {
    if (count < similarJobs?.length) setCount((prevCount) => prevCount + 10);
  };

  if (similarJobs?.length === 0) return null;

  return (
    <>
      {" "}
      <section className="new-job">
        <div className="container container-site">
          <div className="job-search-box-row">
            <div className="job-heading smilar-job-heading">
              <h2>Similar Jobs</h2>
            </div>
            <div className="search-box">
              <img src="/image/job-search.png" alt="" />
              <input
                type="text"
                name=""
                id="jon-search-box"
                placeholder="Search Jobs"
                className="form-font-f"
              />
            </div>
          </div>
          {similarJobs?.length > 0 &&
            similarJobs?.slice(0, count)?.map((prod) => (
              <>
                <div className="divider"></div>
                <div className="Job-Opportunities-row">
                  <JobRecordLeft JobData={prod} />
                  <JobRecordRight
                    date={prod?.date}
                    selectedJobId={prod?.id}
                    wishList={prod?.whishlist}
                    isApplied={prod?.is_applied}
                  />
                </div>
              </>
            ))}
          <div className="divider"></div>
        </div>
        {similarJobs?.length > 10 && (
        <div className="see-more-job" onClick={() => countHandler()}>
          <Link href="#">
            <button>See more</button>
          </Link>
        </div>
        )}
      </section>
    </>
  );
};

export default SimilarJobs;
