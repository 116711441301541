const getDays = (endDate) => {
  const diffInMs = new Date() - new Date(endDate);
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
  return Math.floor(diffInDays);
};

export const calculateDaysAgoUTC = (postDate) => {

  // Create a Date object from the input timestamp
  const date = new Date(postDate);

  // Convert the UTC time to the local time zone
  const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

  const currentDate = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = currentDate - localDate;

  // Calculate the number of days
  const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return daysAgo === 0 || daysAgo === 1 ? `${daysAgo} day ago` : `${daysAgo} days ago`;
}

export default getDays;
