import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import getDays from "../../../utils/getDays";
import { setLoginModel } from "../../../redux/slices/AuthSlice";
import {
  jobAppliedAsync,
  setJobAppliedId,
} from "../../../redux/slices/candidateDashboardSlice";
import AddToWishlist from "../../Common/JobRecord/AddToWishlist";
import "./JobHead.css";

const JobHead = ({ hideQuesationBtn, jobDetail }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isEmailVerify, user } = useSelector((state) => state.auth);
  let jobDesDetail = Object.assign({}, jobDetail[0]);

  const [toggleApplied, setToggleApplied] = useState(false);

  const applyNowHandler = () => {
    if (isEmailVerify === false) {
      dispatch(setLoginModel(true));
    } else {
      dispatch(jobAppliedAsync({ userId: user?.id, jobId: jobDesDetail?.id }))
        .then((response) => {
          const res = response?.payload;
          if (res?.result === true) {
            dispatch(setJobAppliedId(jobDesDetail?.id));
            setToggleApplied(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const wishListHandler = () => {
    if (isEmailVerify === false) dispatch(setLoginModel(true));
  };

  const tranferData = (data) => {
    if (!data) {
      return [];
    }
    try {
      const currentArray = JSON?.parse(data);
      const convertArray = currentArray?.map((item) => {
        return item;
      });
      return convertArray;
    } catch (error) {
      console.error("Error parsing responsibilities:", error);
      return [];
    }
  };

  const QuesationHandler = () => {
    if (jobDesDetail?.id) navigate(`/job-quesation/${jobDesDetail?.id}`);
  };

  return (
    <>
      {" "}
      <section className="job-details-sec">
        <div className="container container-site">
          <div className="space-inner-row">
            <div className="j-compny-btn">
              <div className="j-company-logo">
                <img src="/image/amazaon.png" alt="" />
              </div>
              <div className="j-company-dev">
                <p className="company-job-title">
                  {" "}
                  {jobDesDetail?.name && jobDesDetail?.name}
                </p>
                <p className="j-sub-title">
                  {" "}
                  {jobDesDetail?.company && jobDesDetail?.company?.name}
                </p>

                {jobDesDetail?.salary_from && (
                  <div className="icon-text-job-requre">
                    <img src="/image/j-salary.png" alt="" />
                    <p className="j-c-salary">
                      {" "}
                      {`Salary $${jobDesDetail?.salary_from}`}
                      {jobDesDetail?.salary_to &&
                        `- $${jobDesDetail?.salary_to}`}
                      <span>/year</span>
                    </p>
                  </div>
                )}

                {jobDesDetail?.companySize && (
                  <div className="icon-text-job-requre">
                    <img src="/image/company-eployee.png" alt="" />
                    <p className="j-c-salary">
                      {" "}
                      {`Employees ${jobDesDetail?.companySize[0]?.title}`}
                    </p>
                  </div>
                )}

                {jobDesDetail?.jobType?.length > 0 && (
                  <div className="icon-text-job-requre">
                    <img src="/image/j-timing.png" alt="" />
                    <div className="job-find-list">
                      <p className="j-c-salary">
                        {" "}
                        {jobDesDetail?.jobType &&
                          jobDesDetail?.jobType[0]?.name}{" "}
                      </p>
                      <ul>
                        <li>
                          {" "}
                          {jobDesDetail?.date
                            ? getDays(jobDesDetail?.date) + " Days ago"
                            : "21 Days ago"}
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
              <div className="j-company-btn-apply">
                <div className="tow-btn-j-com">
                  {isEmailVerify && user?.user_type === "employer" ? (
                    <button class="edit-j-btn">
                      <img src="/image/edit-q.png" alt="" />
                      Edit
                    </button>
                  ) : (
                    <button
                      className="apply-j-btn"
                      onClick={() => applyNowHandler()}
                    >
                      <img src="/image/apply-j.png" alt="" />{" "}
                      {jobDesDetail?.is_applied === 1
                        ? "Applied"
                        : toggleApplied
                        ? "Applied"
                        : "Apply Now"}
                    </button>
                  )}
                  {hideQuesationBtn ? (
                    <></>
                  ) : (
                    <button
                      className="apply-j-btn"
                      onClick={() => QuesationHandler()}
                    >
                      <img src="/image/interview-q.png" alt="" />
                      Sample Interview Questions
                    </button>
                  )}

                  {isEmailVerify && user?.user_type === "employer" ? (
                    <button class="ques-list-j-btn">
                      <img src="/image/questi-list.png" alt="" />
                      Candidates List
                    </button>
                  ) : (
                    <AddToWishlist
                      jobId={jobDesDetail?.id}
                      wishlist={jobDesDetail?.whishlist}
                      withParentButton={true}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JobHead;
