import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import API from "../../../api";
import PhoneNumber from "../../Common/PhoneNumber/PhoneNumber";

const EmpProfileForm = ({
  loader,
  baseInfo,
  city,
  companyInfo,
  editHandler,
  submitHandler,
}) => {
  const [selectedImageName, setSelectedImageName] = useState("");
  const [cityOptions, setCityOptions] = useState([]);
  const [locationHide, setLocationHide] = useState(true);

  const formik = useFormik({
    initialValues: {
      files: null,
      companyName: "",
      countryId: null,
      city: "",
      cityId: null,
      websiteUrl: "",
      email: "",
      phoneNo: "",
      linkedinURL: "",
      companyInfo: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      city: Yup.string().required("Please select the city"),
      websiteUrl: Yup.string().required("Please enter valid website URL"),
      phoneNo: Yup.string().required("Enter phone number"),
      companyInfo: Yup.string().required("Please enter Company Information"),
      files: Yup.mixed()
        .test(
          "fileType",
          "File can be a JPEG or PNG document",
          (value) => !value || ["image/png", "image/jpeg"].includes(value?.type)
        )
        .test(
          "fileSize",
          "The file size should not exceed 10 MB",
          (value) => !value || value?.size <= 10 * 1024 * 1024
        ),
    }),

    onSubmit: async (data, { resetForm, setErrors }) => {
      submitHandler(data);
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const isFileFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFileErrorMessage = (name) => {
    return (
      isFileFieldValid(name) && (
        <p className="file-error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImageName(file.name);
        formik.setFieldValue("files", file);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedImageName("");
      formik.setFieldValue("files", null);
    }
  };

  const cityHandler = async (id, name) => {
    await formik.setFieldValue("city", name);
    await formik.setFieldValue("cityId", id);
    setLocationHide(true);
  };

  useEffect(() => {
    async function getCities() {
      if (baseInfo?.countryId) {
        try {
          await API.get(`/get-cities?country_id=${baseInfo?.countryId}`)
            .then((response) => {
              if (response?.status === 200) {
                let res = response?.data;
                setCityOptions(res);
              }
            })
            .catch((error) => {
              if (error) {
                console.log(error);
              }
            });
        } catch (error) {
          console.log(error);
        }
      }
    }
    getCities();
  }, [baseInfo]);

  useEffect(() => {
    formik.setValues({
      companyName: companyInfo?.name || "",
      countryId: baseInfo?.countryId || "",
      city: city?.cityName || "",
      cityId: city?.cityId || "",
      websiteUrl: companyInfo?.website || "",
      email: baseInfo?.email || "",
      phoneNo: baseInfo?.phone || "",
      linkedinURL: companyInfo?.linkedin || "",
      companyInfo: companyInfo?.description || "",
    });
  }, []);

  return (
    <>
      <div className="candidtae-profile-image">
        <label for="file-input" id="candidate-img">
          <img src="/image/upload-1.png" alt="pic" />
          <input
            id="file-input"
            type="file"
            name="files"
            onClick={handleImageChange}
            onChange={handleImageChange}
          />
          <p className="candidate-logo-file">Upload here</p>
          <p className="file-size-candidate">Max: 10MB</p>
          <p className="p.file-error-msg"> </p>
          {selectedImageName}
          {getFileErrorMessage("files")}
        </label>
      </div>
      <div className="divider-left-profile"> </div>
      <div className="candidate-information">
        <div className="candidate-about-me">
          <div className="candidate-profile-form">
            <form onSubmit={formik.handleSubmit}>
              <div className="candidate-name-field-profile-f profile-field-f">
                <div className="employr-f-profile password-icon bg-pass-f">
                  <input
                    className="form-font-f"
                    type="text"
                    name=""
                    id="Company-name-employe-profile"
                    placeholder="Company Name"
                    value={formik.values.companyName}
                  />
                  <div className="icon-hide">
                    <img src="/image/lock-profile-employye.png" alt="" />
                    <span className="tooltiptext-profile">
                      Contact Admin Support
                    </span>
                  </div>
                </div>
              </div>

              <div
                className={`profile-field-f signup-passowrd-job location-profile-list ${
                  isFormFieldValid("city") && "error-input"
                }`}
              >
                <div className="industry-feild-frm bg-pass-f">
                  <div className="password-icon-logo">
                    <img src="/image/resume-location.png" alt="" />
                  </div>
                  <div
                    id="dropdown-boxId-2"
                    className="company-form-dropdownBox-profile"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={() => setLocationHide(!locationHide)}
                  >
                    {" "}
                    {formik?.values?.city === ""
                      ? "location"
                      : formik?.values?.city}
                  </div>
                  <div className="icon-hide">
                    {isFormFieldValid("city") ? (
                      <img src="/image/warning.png" alt="" />
                    ) : (
                      <img src="/image/form-d-f.png" alt="" />
                    )}
                  </div>
                  {cityOptions?.length > 0 && (
                    <div
                      id="company-dropList-t"
                      className={`dropdown-form-f company-drop-list-t ${
                        locationHide && "job-dropdown-hide"
                      }`}
                    >
                      <ul>
                        {cityOptions.map((j, i) => (
                          <li
                            className="icon-cursor-f"
                            key={i}
                            onClick={() => {
                              cityHandler(j?.id, j?.name);
                            }}
                          >
                            <img src="/image/resume-location.png" alt="" />{" "}
                            {j?.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                {getFormErrorMessage("city")}
              </div>
              <div
                className={`url-employee-profile-f profile-field-f ${
                  isFormFieldValid("websiteUrl") && "error-input"
                }`}
              >
                <div className="location-f-icon bg-pass-f">
                  <div className="password-icon-logo">
                    <img src="/image/website-profile-link.png" alt="" />
                  </div>
                  <input
                    className="form-font-f"
                    id="profile-url-employee"
                    placeholder="Company Website Link"
                    name="websiteUrl"
                    value={formik.values.websiteUrl}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {isFormFieldValid("websiteUrl") && (
                    <img src="/image/warning.png" alt="" />
                  )}
                </div>

                {getFormErrorMessage("websiteUrl")}
              </div>
              <div className={`email-candidate-profile-f profile-field-f`}>
                <div className="location-f-icon bg-pass-f">
                  <div className="password-icon-logo">
                    <img src="/image/resume-mail.png" alt="" />
                  </div>
                  <input
                    className="form-font-f"
                    id="profile-email-candidate"
                    placeholder="E-Mail"
                    name="email"
                    value={formik.values.email}
                  />
                  <div className="icon-hide">
                    <img src="/image/lock-profile-employye.png" alt="" />
                    <span className="tooltiptext-profile">
                      Contact Admin Support
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={`profile-field-f ${
                  isFormFieldValid("phoneNo") && "error-input"
                }`}
              >
                <div
                  className={`country-code-profile phone-f-profile  ${
                    isFormFieldValid("phoneNo") && "error-input"
                  }`}
                >
                  <div className="location-f-icon bg-pass-f">
                    <div className="password-icon-logo">
                      <img src="/image/resume-call.png" alt="" />
                    </div>
                    <PhoneNumber
                    className="form-font-f"
                    id="profile-call"
                    type="tel"
                    name="phoneNo"
                    value={formik.values.phoneNo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    />
                    <div className="icon-hide">
                      {isFormFieldValid("phoneNo") && (
                        <img src="/image/warning.png" alt="" />
                      )}                
                    </div>
                  </div>
                </div>
                {getFormErrorMessage("phoneNo")}
              </div>
              <div className="candidate-link-profile profile-field-f">
                <div className="location-f-icon bg-pass-f">
                  <div className="password-icon-logo">
                    <img src="/image/linkdin.png" alt="" />
                  </div>
                  <input
                    className="form-font-f"
                    type="text"
                    id="social-link"
                    placeholder="Enter Your LinkedIn Profile Here"
                    name="linkedinURL"
                    value={formik.values.linkedinURL}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>

              <div
                className={`employee-profile-compnay ${
                  isFormFieldValid("companyInfo") && "error-input"
                }`}
              >
                <p className="company-name-profile">Company Information</p>
                <textarea
                  className="form-font-f"
                  rows="15"
                  cols="100"
                  name="companyInfo"
                  value={formik.values.companyInfo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <p className="msg-show-blowe-textarea">Max Limit (100) words</p>
                {getFormErrorMessage("companyInfo")}
              </div>
              <div className="back-save-btns">
                <button className="save-btn-profilefill" type="submit">
                  {loader ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Save"
                  )}
                </button>
                <button
                  className="cancel-btn-profile"
                  onClick={() => editHandler(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmpProfileForm;
