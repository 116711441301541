import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectCompanyId } from "../../../redux/slices/companyDetailSlice";
import API from "../../../api";
import "./CompanySection.css";

const CompanySection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const onDetailCompanyHandler = (id) => {
    navigate(`/company-detail/${id}`);
  };

  useEffect(() => {
    async function getFeaturedCompanies() {
      try {
        await API.get(`/job-board/featured_companies`)
          .then((response) => {
            if (response?.status === 200) {
              let res = response?.data;
              setData(res);
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
    getFeaturedCompanies();
  }, []);

  if (data?.length === 0) return null;

  return (
    <>
      <section className="one-heading-sec">
        <div className="container container-site">
          <div className="ensure-heading">
            <h2>We're here to ensure you achieve breakthrough success</h2>
          </div>
        </div>
      </section>

      {data?.length > 0 &&
        data.slice(0, 5).map((prod, index) => (
          <section
            className={
              index % 2 === 0
                ? "sucess-featured-sec-alt"
                : "sucess-featured-sec"
            }
          >
            <div className="container container-site">
              <div
                className={
                  index % 2 === 0 ? "sucess-featured alt" : "sucess-featured"
                }
              >
                <div className="sucess-featured-img ">
                  <img src="/image/meeting.png" alt="meeting " />
                </div>
                <div className="sucess-featured-info ">
                  <div className="image-heading-col">
                    <img src={"/image/company-lg.png"} alt="logo " />
                    <h3>{prod?.name}</h3>
                  </div>
                  <div className="first-col-content ">
                    <p>{prod?.description}</p>{" "}
                  </div>

                  <a             
                    onClick={() => onDetailCompanyHandler(prod?.id)}
                  >
                    <button className="detail-btn">View Details</button>
                  </a>
                </div>
              </div>
            </div>
          </section>
        ))}
    </>
  );
};

export default CompanySection;
